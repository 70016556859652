import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Stack,
    TextField,
    Typography,
    Select,
    MenuItem,
    Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCSVReader, formatFileSize, lightenDarkenColor, readString } from "react-papaparse";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getGoogleSheetData } from "../api";

export default function UploadCSVDialogLtdDomains(props) {
    const GREY = "#CCC";
    const GREY_DIM = "#686868";

    const parseCSV = (csvData) => {
        return new Promise((resolve, reject) => {
            const results = readString(csvData, {
                header: true,
                skipEmptyLines: true,
                transformHeader: header => header.toLowerCase().replace(/\s+/g, ''),
                complete: (results) => {
                    resolve(results.data);
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    };

    const styles = {
        zone: {
            alignItems: "center",
            border: `2px dashed ${GREY}`,
            borderRadius: '8px',
            display: "flex",
            flexDirection: "column",
            height: '152px',
            width: "100%",
            justifyContent: "center",
            padding: '16px',
            fontSize: '0.875rem',
            backgroundColor: '#fff',
            transition: 'all 0.2s ease-in-out',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f8f9fa',
                borderColor: '#1976d2',
                borderStyle: 'dashed'
            }
        },
        zoneHover: {
            borderColor: GREY_DIM,
        },
        file: {
            background: "linear-gradient(145deg, #ffffff, #f5f5f5)",
            borderRadius: 12,
            boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
            display: "flex",
            height: 80,
            width: "100%",
            position: "relative",
            zIndex: 10,
            flexDirection: "column",
            justifyContent: "center",
            transition: 'transform 0.2s ease',
            '&:hover': {
                transform: 'translateY(-2px)'
            }
        },
        info: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            padding: '4px 8px',
        },
        size: {
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            borderRadius: 3,
            marginBottom: '0.25em',
            justifyContent: "center",
            display: "flex",
            fontSize: '0.75rem',
        },
        name: {
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            borderRadius: 3,
            fontSize: '0.75rem',
            marginBottom: '0.25em',
        },
        progressBar: {
            bottom: 8,
            position: "absolute",
            width: "100%",
            paddingLeft: 10,
            paddingRight: 10,
        },
        remove: {
            height: 23,
            position: "absolute",
            right: 6,
            top: 6,
            width: 23,
        },
    };

    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [val, setVal] = useState([]);
    const [domains, setDomains] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [platform, setPlatform] = useState("");
    const [isCSVUploaded, setIsCSVUploaded] = useState(false);
    const [excelUrl, setExcelUrl] = useState("");
    const [urlError, setUrlError] = useState("");
    const [uploadError, setUploadError] = useState("");
    const [disabled, setDisabled] = useState(true);

    const formatDate = (date) => {
        if (!date) return null;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const validateGoogleSheetsUrl = (url) => {
        const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/e\/[^\/]+\/pub\?output=csv$/;
        return regex.test(url);
    };

    const validateHeaders = (dataArray, requiredHeaders) => {
        if (dataArray.length === 0) {
            return false;
        }
        const firstRow = dataArray[0];
        return requiredHeaders.every(header => Object.hasOwnProperty.call(firstRow, header));
    };

    const handleSync = async () => {
        if (!excelUrl || !validateGoogleSheetsUrl(excelUrl)) return;

        try {
            const response = await getGoogleSheetData(excelUrl);
            const csvData = response.data;

            const parsedData = await parseCSV(csvData);
            const requiredHeaders = ['domain'];
            const isValid = validateHeaders(parsedData, requiredHeaders);

            if (!isValid) {
                setUploadError('The CSV file is in the wrong format. It must contain "Domain" header.');
                setDisabled(true);
                return;
            }
            setUploadError('');
            setVal(parsedData);
            setDisabled(false);
        } catch (error) {
            console.error('Error fetching or parsing CSV data:', error);
        }
    };

    const downloadTemplate = () => {
        const csvContent = `platform,domain,orderdate,edate,est,gdv,currentPrice,clientOffer,type,lander,host,wby,aby,reg,regx,tld_count,tld_count_dev,sg,cpc,bids,esw,gdw,spr500,sprGDV,reco,apr,tags
dc,example1.com,2024-10-22,2024-10-27,1900,200,25,1000,Generic,lander1,host1,2024-10-27,2024-10-27,45,20,5,3,61000,1.5,10,1800,1600,0.85,0.75,950,900,premium
gd,example2.cc,2024-10-22,2024-10-27,1700,1600,25,2000,Brandable,lander2,host2,2024-10-27,2024-10-27,100,30,4,2,110000,2.0,15,1500,1400,0.80,0.70,1800,1700,startup
sn,example3.net,2024-10-22,2024-10-27,700,1300,25,500,Generic,lander3,host3,2024-10-27,,4,5,3,1,300,1.0,5,600,500,0.70,0.65,400,350,business`;

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'template.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (!isCSVUploaded && domains && platform && selectedDate) {
            const domainList = domains.split(/\s*,\s*|\s*[\r\n]+\s*/).map((domainName) => {
                const trimmedDomain = domainName.trim();
                if (trimmedDomain) {
                    return {
                        domain: trimmedDomain,
                        platform: platform,
                        portfolio: (platform === 'ald' || platform === 'pd') ? platform : 'ltd',
                        [(platform === 'ald' || platform === 'pd') ? "orderDate" : "edate"]: formatDate(selectedDate),
                    };
                }
                return null;
            }).filter(Boolean);
            setVal(domainList);
        }
    }, [domains, platform, selectedDate, isCSVUploaded]);

    const isUploadEnabled = isCSVUploaded || (val.length > 0);

    const resetState = () => {
        setZoneHover(false);
        setVal([]);
        setDomains("");
        setSelectedDate(null);
        setPlatform("");
        setIsCSVUploaded(false);
        setExcelUrl("");
        setUrlError("");
        setUploadError("");
        setDisabled(true);
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => {
                resetState();
                props.handleClose();
            }}
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                    overflow: 'hidden'
                }
            }}
        >
            <DialogTitle
                sx={{
                    py: 2,
                    px: 3,
                    typography: 'h6',
                    borderBottom: '1px solid #e0e0e0',
                    color: '#1976d2',
                    backgroundColor: '#fff'
                }}
            >
                Add Domain
            </DialogTitle>
            <DialogContent sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack
                        direction="row"
                        spacing={3}
                        divider={
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ mx: 1 }}
                            />
                        }
                        sx={{
                            p: 3,
                            mt: 2,
                            minHeight: '200px',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
                        }}
                    >
                        <Box width="50%" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <FormControl fullWidth>
                                <Select
                                    value={platform}
                                    onChange={(event) => setPlatform(event.target.value)}
                                    disabled={isCSVUploaded}
                                    displayEmpty
                                    size="small"
                                    sx={{
                                        height: '42px',
                                        backgroundColor: '#fff',
                                        fontSize: '0.875rem',
                                        '& .MuiSelect-select': {
                                            fontSize: '0.875rem'
                                        },
                                        '&:hover': {
                                            backgroundColor: '#f8f9fa'
                                        }
                                    }}
                                >
                                    <MenuItem disabled value="" sx={{ fontSize: '0.875rem' }}>Select Platform</MenuItem>
                                    {['ald', 'ba', 'dc', 'dd', 'gd', 'gdc', 'nc', 'ns', 'pd', 'sd', 'sn', 'sv'].map((type) => (
                                        <MenuItem key={type} value={type} sx={{ fontSize: '0.875rem' }}>{type.toUpperCase()}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <DatePicker
                                    label="Select Date"
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            sx: {
                                                '& .MuiInputBase-root': {
                                                    height: '42px',
                                                    fontSize: '0.875rem',
                                                    backgroundColor: '#fff',
                                                    transition: 'all 0.2s',
                                                    '&:hover': {
                                                        backgroundColor: '#f8f9fa'
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    value={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    disabled={isCSVUploaded}
                                />
                            </FormControl>

                            <TextField
                                multiline
                                placeholder="example.com, example.net, example.org"
                                rows={4}
                                fullWidth
                                value={domains}
                                onChange={(event) => setDomains(event.target.value)}
                                disabled={isCSVUploaded}
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: '0.875rem',
                                        backgroundColor: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#f8f9fa'
                                        }
                                    }
                                }}
                            />
                        </Box>

                        <Box width="50%" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <CSVReader
                                onUploadAccepted={(results) => {
                                    setVal(results.data);
                                    setZoneHover(false);
                                    setIsCSVUploaded(true);
                                }}
                                config={{
                                    worker: false,
                                    header: true,
                                    transformHeader: (header) => {
                                        return header.toLowerCase()
                                            .replace(/(?:^\w|[A-Z]|\b\w|_\w)/g, (word, index) => {
                                                if (word.startsWith('_')) {
                                                    return word.charAt(1).toUpperCase();
                                                }
                                                return index === 0 ? word.toLowerCase() : word.toUpperCase();
                                            })
                                            .replace(/\s+|_+/g, '');
                                    },
                                }}
                                onDragOver={(event) => {
                                    event.preventDefault();
                                    setZoneHover(true);
                                }}
                                onDragLeave={(event) => {
                                    event.preventDefault();
                                    setZoneHover(false);
                                }}
                                styles={{
                                    ...styles,
                                    zone: {
                                        ...styles.zone,
                                        height: '152px',
                                        border: `2px dashed ${GREY}`,
                                        borderRadius: '20px',
                                        padding: '8px',
                                        fontSize: '0.875rem'
                                    }
                                }}
                            >
                                {({
                                    getRootProps,
                                    acceptedFile,
                                    ProgressBar,
                                    getRemoveFileProps,
                                    Remove,
                                }) => (
                                    <div
                                        {...getRootProps()}
                                        style={{
                                            ...styles.zone,
                                            height: '152px',
                                            ...(zoneHover && styles.zoneHover),
                                        }}
                                    >
                                        {acceptedFile ? (
                                            <div style={styles.file}>
                                                <div style={styles.info}>
                                                    <span style={styles.size}>
                                                        {formatFileSize(acceptedFile.size)}
                                                    </span>
                                                    <span style={styles.name}>{acceptedFile.name}</span>
                                                </div>
                                                <div style={styles.progressBar}>
                                                    <ProgressBar />
                                                </div>
                                                <div {...getRemoveFileProps()} style={styles.remove}>
                                                    <Remove />
                                                </div>
                                            </div>
                                        ) : (
                                            "Drop CSV file here or click to upload"
                                        )}
                                    </div>
                                )}
                            </CSVReader>
                            <Button
                                variant="outlined"
                                onClick={downloadTemplate}
                                sx={{
                                    height: '42px',
                                    textTransform: 'none',
                                    borderRadius: 1,
                                    fontWeight: 500,
                                    borderColor: '#1976d2',
                                    color: '#1976d2',
                                    '&:hover': {
                                        backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                        borderColor: '#1976d2'
                                    }
                                }}
                            >
                                Download Template
                            </Button>
                        </Box>
                    </Stack>

                    <Box
                        sx={{
                            mt: 3,
                            p: 3,
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
                        }}
                    >
                        <Stack direction='row' spacing={2} alignItems="center">
                            <TextField
                                size="small"
                                fullWidth
                                label="Excel Sheet URL"
                                variant="outlined"
                                value={excelUrl}
                                onChange={(e) => {
                                    const url = e.target.value;
                                    setExcelUrl(url);
                                    if (validateGoogleSheetsUrl(url)) {
                                        setUrlError('');
                                        setDisabled(false);
                                    } else {
                                        setUrlError('Invalid Google Sheets URL');
                                        setDisabled(true);
                                    }
                                }}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '42px',
                                        backgroundColor: '#fff'
                                    }
                                }}
                            />
                            <Button
                                variant="contained"
                                disabled={disabled || isCSVUploaded}
                                onClick={handleSync}
                                sx={{
                                    height: '42px',
                                    minWidth: '100px',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                Sync
                            </Button>
                        </Stack>
                        {urlError && <Typography color='error' variant='caption' sx={{ pl: 1 }}>{urlError}</Typography>}
                        {uploadError && <Typography color='error' variant='caption' sx={{ pl: 1 }}>{uploadError}</Typography>}
                    </Box>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions
                sx={{
                    py: 2,
                    px: 3,
                    borderTop: '1px solid #e0e0e0',
                    backgroundColor: '#fff'
                }}
            >
                <Button
                    onClick={props.handleClose}
                    variant="outlined"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 500,
                        borderColor: '#1976d2',
                        color: '#1976d2',
                        '&:hover': {
                            borderColor: '#1976d2',
                            backgroundColor: 'rgba(25, 118, 210, 0.04)'
                        }
                    }}
                >
                    Cancel
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    disabled={!isUploadEnabled}
                    onClick={() => {
                        props.setState(val);
                        resetState();
                        props.handleClose();
                    }}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 500,
                        px: 3,
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none'
                        }
                    }}
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
} 