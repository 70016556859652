import {  Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Autocomplete, Box, Button, Stack, ThemeProvider, Typography, Chip, Snackbar, Alert, 
    Tab} from "@mui/material";
import { theme } 
from "./Theme";
import React, { useEffect, useState } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Add, FiberManualRecord } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { deletePQ, getPQs, getPresentAllocsPQ, postPQ, putPQ, quotePrice, setPricesPQ } from "./api";
import dayjs, { Dayjs } from "dayjs";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { MaterialReactTable } from "material-react-table";
import { getUserName, isAdmin, isProcessor } from "./msalService";
import { TabContext, TabList } from "@mui/lab";

const getDate1month=()=>{let date= dayjs(new Date()); date=date.set('M',-1); return date;}

export default function PQCentre() 
{

    const columns = [
        { field: 'date', headerName: 'Date', width: 100,type:'text',valueGetter: (params) => {return (new Date(params.row.date).toISOString().substring(0,10))} 
    },
    
        { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
    },{field:'type',headerName:'Type',width:70
  , renderCell: (params) => {return <Box><Chip label={params.row.domain.portfolio} size="small" sx={{backgroundColor:params.row.domain.portfolio==='owned'?'#C6CFFF':'#FFC682',height:17,fontSize:10}} /> </Box>}}
    ,
    {
      field: 'Deadline',
      headerName: 'Deadline',
      width: 100, valueGetter: (params) => params.row.domain && params.row.domain.deadline ? params.row.domain.deadline.substring(0,10) : ''
  },
  {
    field: 'OrderDate',
    headerName: 'Order Date',
    type: 'number',
    width: 100, valueGetter: (params) => params.row.domain && params.row.domain.orderDate ? params.row.domain.orderDate.substring(0,10) : ''
}
,
  {
    field: 'Order Status',
    headerName: 'Order Status',
    type: 'number',
    width: 70, valueGetter: (params) => params.row.domain && params.row.domain.orderStatus ? params.row.domain.orderStatus : 'NA'
}
,  {
        field: 'EST',
        headerName: 'EST',
        type: 'number',
        width: 70,valueGetter: (params) => {return params.row.domain.EST}
    },
    {
      field: 'GDV',
      headerName: 'GDV',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.GDV}
  },
    {
      field: 'PP',
      headerName: 'PP',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.PP}
    },
    { field: 'siteLead', headerName: 'Lead', width: 200},
    { field:'email',headerName:'Email',width:220},
    { field:'listPrice',headerName:'List Price',width:100, valueGetter: (params) => {return params.row.domain.salePrice}},
    { field:'floorPrice',headerName:'Floor Price',width:100, valueGetter: (params) => {return params.row.domain.reserve}},
    { field: 'sugg', headerName: 'SUGG', width: 100},
    { field:'quote',headerName:'QUOTE',width:100},
    { field:'processor',headerName:'Processor',width:110,valueGetter: (params) => {return params.row.user.firstName+' '+params.row.user.lastName}},

    ]

    const columnsO = [
      { field: 'date', headerName: 'Date', width: 100,type:'text',valueGetter: (params) => {return (new Date(params.row.date).toISOString().substring(0,10))} 
  },
  
      { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
  } ,
    {
      field: 'EST',
      headerName: 'EST',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.EST}
  },
  {
    field: 'GDV',
    headerName: 'GDV',
    type: 'number',
    width: 70,valueGetter: (params) => {return params.row.domain.GDV}
},
  {
    field: 'PP',
    headerName: 'PP',
    type: 'number',
    width: 70,valueGetter: (params) => {return params.row.domain.PP}
  },
  { field: 'siteLead', headerName: 'Lead', width: 200},
  { field:'email',headerName:'Email',width:220},
  { field:'listPrice',headerName:'List Price',width:100, valueGetter: (params) => {return params.row.domain.salePrice}},
  { field:'floorPrice',headerName:'Floor Price',width:100, valueGetter: (params) => {return params.row.domain.reserve}},
  { field: 'sugg', headerName: 'SUGG', width: 100},
  { field:'quote',headerName:'QUOTE',width:100},
  { field:'processor',headerName:'Processor',width:110,valueGetter: (params) => {return params.row.user.firstName+' '+params.row.user.lastName}},

  ]

  const columnsL = [
    { field: 'date', headerName: 'Date', width: 100,type:'text',valueGetter: (params) => {return (new Date(params.row.date).toISOString().substring(0,10))} 
},

    { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
},
{
  field: 'Deadline',
  headerName: 'Deadline',
  width: 100, valueGetter: (params) => params.row.domain && params.row.domain.deadline ? params.row.domain.deadline.substring(0,10) : ''
}
,  {
    field: 'EST',
    headerName: 'EST',
    type: 'number',
    width: 70,valueGetter: (params) => {return params.row.domain.EST}
},
{
  field: 'GDV',
  headerName: 'GDV',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.GDV}
},
{
  field: 'PP',
  headerName: 'PP',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.PP}
},
{ field: 'siteLead', headerName: 'Lead', width: 200},
{ field:'email',headerName:'Email',width:220},
{ field: 'sugg', headerName: 'SUGG', width: 100},
{ field:'quote',headerName:'QUOTE',width:100},
{ field:'processor',headerName:'Processor',width:110,valueGetter: (params) => {return params.row.user.firstName+' '+params.row.user.lastName}},

]

const columnsPNA = [
  { field: 'date', headerName: 'Date', width: 100,type:'text',valueGetter: (params) => {return (new Date(params.row.date).toISOString().substring(0,10))} 
},

  { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
},{field:'type',headerName:'Type',width:70
, renderCell: (params) => {return <Box><Chip label={params.row.domain.portfolio} size="small" sx={{backgroundColor:params.row.domain.portfolio==='owned'?'#C6CFFF':'#FFC682',height:17,fontSize:10}} /> </Box>}}
,
{
field: 'OrderDate',
headerName: 'Order Date',
type: 'number',
width: 100, valueGetter: (params) => params.row.domain && params.row.domain.orderDate ? params.row.domain.orderDate.substring(0,10) : ''
}
,
{
field: 'Order Status',
headerName: 'Order Status',
type: 'number',
width: 70, valueGetter: (params) => params.row.domain && params.row.domain.orderStatus ? params.row.domain.orderStatus : 'NA'
}
,  {
  field: 'EST',
  headerName: 'EST',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.EST}
},
{
field: 'GDV',
headerName: 'GDV',
type: 'number',
width: 70,valueGetter: (params) => {return params.row.domain.GDV}
},
{
field: 'PP',
headerName: 'PP',
type: 'number',
width: 70,valueGetter: (params) => {return params.row.domain.PP}
},
{ field: 'siteLead', headerName: 'Lead', width: 200},
{ field:'email',headerName:'Email',width:220},
{ field: 'sugg', headerName: 'SUGG', width: 100},
{ field:'quote',headerName:'QUOTE',width:100},
{ field:'processor',headerName:'Processor',width:110,valueGetter: (params) => {return params.row.user.firstName+' '+params.row.user.lastName}},

]
    const [openDialog, setOpenDialog]=useState(false);
    const [openDialog1, setOpenDialog1]=useState(false);

    const [domain, setDomain] = useState("");
    let [psize,setPsize]= useState(50);
    let [tvalue,setTvalue]= useState("All");

      const [pqs, setPqs] = useState([]);
      const [pqsO, setPqsO] = useState([]);
      const [pqsP, setPqsP] = useState([]);
      const [pqsA, setPqsA] = useState([]);
      const [pqsL, setPqsL] = useState([]);

      const [pq, setPq] = useState(null);

    const [date, setDate] = useState(null);
    const [date1, setDate1] = useState( getDate1month());
    const [date2, setDate2] = useState(dayjs(new Date()));
  
    const [leadName, setLeadName] = useState("");
    const [lead, setLead] = useState({});
    const [leadSocials, setLeadSocials] = useState([{platform:'Linkedin',employees:0},{platform:'Facebook',followers:0},{platform:'Instagram',followers:0},{platform:'Twitter',followers:0}]);
    const [topLead, setTopLead] = useState("");
    const [topLeadSocials, setTopLeadSocials] = useState([{platform:'Linkedin',employees:0},{platform:'Facebook',followers:0},{platform:'Instagram',followers:0},{platform:'Twitter',followers:0}]);
    const [sugg, setSugg] = useState(0);

    const [listPrice, setListPrice] = useState(0);
    const [floorPrice, setFloorPrice] = useState(0);
    const [upsuc, setUpsuc]=useState(false);
    const [upfail, setUpfail]=useState(false);
    const [upsuc1, setUpsuc1]=useState(false);
    const [upfail1, setUpfail1]=useState(false);
    const [newlead, setNewlead] = useState(false);
    const [responderName, setResponderName] = useState("");
    const [responderEmail, setResponderEmail] = useState("");
    const [agentEmail, setAgentEmail] = useState("");

    const [designation, setDesignation] = useState("");
    const [response, setResponse] = useState("");
    const[b,setB]=useState(false);
    
    const handleClose = () => {
        refresh();
        setOpenDialog(false);
      };
      const handleSocialChange = (platform, newValue) => {
        setLeadSocials(prevSocials =>
          prevSocials.map(social =>
            social.platform === platform ? { ...social, followers: newValue, employees:newValue } : social
          )
        );
      };
      const handleTopLeadsSocialChange = (platform, newValue) => {
        setTopLeadSocials(prevSocials =>
          prevSocials.map(social =>
            social.platform === platform ? { ...social, followers: newValue, employees:newValue } : social
          )
        );
      };
      const handleClose1 = () => {
        //refresh();
        setOpenDialog1(false);
      };
      const setUpdatedPQ=(pq)=>{
        setPqs((prevpqs)=>prevpqs.map((p)=>p.id===pq.id?pq:p));
      }
      const [domains, setDomains] = useState([]);
      
      useEffect(()=>{
        console.log(leadSocials);
      },[leadSocials])

      useEffect(()=>{
        getPresentAllocsPQ().then((res)=>{setDomains(res.data); console.log(res.data)}).catch((err)=>{console.log(err)})},[])

        useEffect(()=>{
          if(date1!=null&&date2!=null)
            getPQs(date1.format('YYYY-MM-DD'),date2.format('YYYY-MM-DD')).then((res)=>{setPqs(res.data);let pqarray=res.data;setPqsO(pqarray.filter((pq)=>pq.domain.portfolio==='owned'));setPqsP(pqarray.filter((pq)=>pq.domain.portfolio==='pd'));setPqsA(pqarray.filter((pq)=>pq.domain.portfolio==='ald'));setPqsL(pqarray.filter((pq)=>pq.domain.portfolio==='ltd')); console.log(res.data)}).catch((err)=>{console.log(err)})},[date1,date2,b])
    

      const refresh=()=>{setDomain("");setDate(null);setDesignation("");setLeadName("");setNewlead(true);setOpenDialog(false);setResponderEmail("");setResponderName("");setResponse("");setLead({}); setTopLead(""); setLeadSocials([{platform:'Linkedin',employees:0},{platform:'Facebook',followers:0},{platform:'Instagram',followers:0},{platform:'Twitter',followers:0}]); setTopLeadSocials([{platform:'Linkedin',followers:0},{platform:'Facebook',followers:0},{platform:'Instagram',followers:0},{platform:'Twitter',followers:0}]); setSugg(0);}
      const handleChangeT = (event, newValue) => {
        setTvalue(newValue);
      };
      function CustomToolbar(props) {
        const ids=props.ids
        return (
          <GridToolbarContainer sx={{ display:'flex',flexDirection:'row', color:'black'}} >
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <Box flexGrow={1}/>
           
 {/*isAdmin()&& <>
  <Button
    variant="text"
    //color="primary"
    startIcon={<DeleteOutline />}
    onClick={()=>{}}
    sx={{
      textTransform:'none'
    }}
  >
    Delete
  </Button>
  <Button
    variant="text"
    //color="primary"
    startIcon={<ShortcutOutlined />}
    onClick={()=>{}}
    sx={{
      textTransform:'none'
    }}
  >
    Move
</Button></>*/}
          </GridToolbarContainer>
        );
      }

    
    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2} alignItems='center'>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      PQ Center
    </Typography>
    <Box sx={{flexGrow:1}}/>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer  components={['DatePicker','DatePicker']}>
      <DesktopDatePicker 
      format='YYYY-MM-DD'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",width:200}}
          label="PQs' From"
          value={date1}
          onChange={(newValue) => {
            newValue=newValue.set('h',0);
            newValue=newValue.set('m',0);
            setDate1(newValue);}}
        />
        <DesktopDatePicker
        inputProps={{ size: 'small' }}
        format='YYYY-MM-DD'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",width:200}}
          label="PQs' To"
          value={date2}
          onChange={(newValue) => {const updatedDate = new Date();
            newValue=newValue.set('h',updatedDate.getHours());
            newValue=newValue.set('m',updatedDate.getMinutes());setDate2(newValue);}}
        />
         </DemoContainer>
    </LocalizationProvider>
     {isProcessor()&&<><Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none',
      }}
    >
      Add PQ
    </Button></>}
            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
PQ Saved!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          PQ Not Saved!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc1(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
Price Quoted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail1(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Price Not Quoted!
        </Alert>
      </Snackbar>
            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth='md' h aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add PQ</DialogTitle>
      <DialogContent>
      
        <Box sx={{ display: "flex", justifyContent: "space-between", mt:2 }}>
          <Box sx={{ mr: 1, width: "50%" }}>
            {/* <FormControl fullWidth>
              <InputLabel id="select-domain-label">Enter Domain</InputLabel>
              <Select
                labelId="select-domain-label"
                id="select-domain"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                placeholder="Domain"
                label="Enter Domain"
              >
                {domains.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option.domain.domain}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={domains}
              sx={{marginTop:0, marginBottom:1 }}
              getOptionLabel={(domain)=>{ return domain.domain.domain}}
              fullWidth
              onChange={(event,value)=>{setDomain(value); if(value==null){setLeadName(""); setLead(null);setNewlead(false);}}}
              renderInput={(params) => <TextField  {...params} type="text" label="Domain" />}
            />
            <Box sx={{
              border: '1px solid #c4c4c4',
              borderRadius: '4px',
              padding: '10px 14px',
              width: '100%',
              minHeight: '90px',//'56px' for MUI TextField
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              marginTop:2
            }}>
              {(domain!=null&&domain.domain!=null)? 
                <Stack direction='column' spacing={0.5} >
                  <Stack spacing={2} direction='row' flexWrap="wrap">
                    <Chip label={domain.domain.portfolio} size="small" sx={{backgroundColor:domain.domain.portfolio==='owned'?'#C6CFFF':'#FFC682',height:17,fontSize:10}} />
                    <Stack direction='row' spacing={1}><Typography variant="body2">EST:</Typography>
                    <Typography variant="body2" fontWeight='bold'>{domain!=null&&domain.domain!=null?domain.domain.EST:'NA'}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={1}><Typography variant="body2">GDV:</Typography>
                    <Typography variant="body2" fontWeight='bold'>{domain!=null&&domain.domain!=null?domain.domain.GDV:'NA'}</Typography>
                    </Stack>
                    
                    {domain.domain.portfolio==='ltd'?<Typography variant="body2">Deadline: {domain.domain.deadline.substring(0,10)}</Typography>:null}
                    {domain.domain.portfolio==='pd'||domain.domain.portfolio==='ald'?
                      <>
                        <Typography variant="body2">OD: {domain.domain.orderDate.substring(0,10)}</Typography>
                        <Typography variant="body2">OS: {domain.domain.orderStatus}</Typography>
                      </>:null}
                    {domain.domain.portfolio==='owned'&&
                      <>
                        <Typography variant="body2">LP: {domain.domain.listPrice}</Typography>
                        <Typography variant="body2">FP: {domain.domain.floorPrice}</Typography>
                      </>}
                  </Stack>
                  <Stack spacing={2} direction='row' flexWrap="wrap">
                    <Typography variant='body2'>SG: {domain.domain.sg}</Typography>
                    <Typography variant='body2'>CPC: {domain.domain.cpc}</Typography>
                    <Typography variant='body2'>Reg: {domain.domain.reg}</Typography>
                    <Typography variant='body2'>RegX: {domain.domain.regx}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems="center">
              <Typography variant="body2" fontWeight="bold">
                Top Lead:
              </Typography>
              <Typography variant="body2">
                {topLead} N/A
              </Typography>
              
              <Typography variant="body2">
                FB: {topLeadSocials[1].followers}
              </Typography>
              <Typography variant="body2">
                LN: {topLeadSocials[0].employees}
              </Typography>
              <Typography variant="body2">
                IG: {topLeadSocials[2].followers}
              </Typography>
              <Typography variant="body2">
                X: {topLeadSocials[3].followers}
              </Typography>
            </Stack>
                </Stack>
                :<Typography variant='body2'>No Domain Selected</Typography>
              }
            </Box>
        
            <Autocomplete
              disablePortal
              disabled={domain==null||typeof domain==='string'}
              id="combo-box-demo"
              freeSolo
              options={domain?domain.leads:[]}
              sx={{marginTop:2, marginBottom:2 }}
              getOptionLabel={(lead)=>{ return lead.site?lead.site:lead}}
              fullWidth
              onChange={(event,value)=>{if(value==null) setLeadName(""); else if (typeof value === 'string'){setLeadName(value);setNewlead(true); console.log(value)} else {setLeadName(value.site); console.log(value.site); setLead(value)};}}
              renderInput={(params) => <TextField  {...params} type="text" label="Lead Name" value={leadName} />}
            />
             <Stack  direction='row' spacing={2}>
            <TextField
  margin="normal"
  id="Facebook"
  label="FB"
  type="number"
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  //value={leadSocials[1].followers}
  fullWidth
  disabled={leadName==null||leadName==""}
  onChange={(e) => handleSocialChange('Facebook', e.target.value)}
/>

<TextField
  margin="normal"
    id="Linkedin"
  label="LN"
  type="number"
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  //value={leadSocials[0].employees}

  disabled={leadName==null||leadName==""}
  fullWidth
  onChange={(e) => handleSocialChange('Linkedin', e.target.value)}
/>

<TextField
  margin="normal"
  id="instagram"
  label="IG"
  type="number"
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  disabled={leadName==null||leadName==""}
  //value={leadSocials[2].followers}
  fullWidth
  onChange={(e) => handleSocialChange('Instagram', e.target.value)}
/>

<TextField
  margin="normal"
  id="Twitter"
  label="X"
  type="number"
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  disabled={leadName==null||leadName==""}
  //value={leadSocials[3].followers}
  fullWidth
  onChange={(e) => handleSocialChange('Twitter', e.target.value)}
/>

            </Stack>
            <TextField
              margin="normal"
              id="agent-email"
              label="Agent Email"
              type="email"
              fullWidth
              onChange={(e)=>{setAgentEmail(e.target.value)}}
              disabled={leadName==null||leadName==""}
            />
            
           {/* { <TextField
              margin="normal"
              id="toplead"
              label="Top Lead"
              
              onChange={(e)=>{setTopLead(e.target.value)}}
              disabled={leadName==null||leadName==""}
              fullWidth
            />
           
            <Stack mt={3} mb={2} direction='row' spacing={2}>
            <TextField
  margin="normal"
  id="Facebook"
  label="FB"
  type="number"
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  disabled={leadName==null||leadName==""}
  //value={topLeadSocials[1].followers}
  fullWidth
  onChange={(e) => handleTopLeadsSocialChange('Facebook', e.target.value)}
/>

<TextField
  margin="normal"
  id="Linkedin"
  label="LN"
  disabled={leadName==null||leadName==""}
  type="number"
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  //value={topLeadSocials[0].employees}

  fullWidth
  onChange={(e) => handleTopLeadsSocialChange('Linkedin', e.target.value)}
/>

<TextField
  margin="normal"
  id="Instagram"
  label="IG"
  type="number"
  disabled={leadName==null||leadName==""}
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  //value={topLeadSocials[2].followers}
  fullWidth
  onChange={(e) => handleTopLeadsSocialChange('Instagram', e.target.value)}
/>

<TextField
  margin="normal"
  id="Twitter"
  label="X"
  disabled={leadName==null||leadName==""}
  type="number"
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  //value={topLeadSocials[3].followers}
  fullWidth
  onChange={(e) => handleTopLeadsSocialChange('Twitter', e.target.value)}
/>
            </Stack>} */}
             
            <TextField
            sx={{mt:2}}
              id="suggested-price"
              label="SUGG"
              type="number"
              fullWidth
              disabled={leadName==null||leadName==""}
              onChange={(e)=>{setSugg(e.target.value)}}

              //onChange={(e)=>{setSuggestedPrice(e.target.value)}}
            />
          </Box>
          <Box sx={{ ml: 1, width: "50%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Enter Date"
                format='DD-MM-YYYY'
                value={date}
                onChange={(newValue) => { setDate(newValue); }}
                sx={{width:'100%'}}
                disabled={leadName==null||leadName==""}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
            
            <TextField
              margin="normal"
              id="responder-name"
              label="Responder Name"
              type="text"
              fullWidth
              disabled={leadName==null||leadName==""}
              onChange={(e)=>{setResponderName(e.target.value)}}
            />
            <TextField
              margin="normal"
              id="responder-email"
              label="Responder Email"
              type="email"
              fullWidth
              onChange={(e)=>{setResponderEmail(e.target.value)}}
              disabled={leadName==null||leadName==""}
            />
            <TextField
              margin="normal"
              id="designation"
              label="Designation"
              type="text"
              fullWidth
              disabled={leadName==null||leadName==""}
              onChange={(e)=>{setDesignation(e.target.value)}}
            />
            <TextField
             sx={{mt:3}}
             // margin="normal"
              id="response"
              label="Response"
              multiline
              rows={4.5}
              onChange={(e)=>{setResponse(e.target.value)}}
              disabled={leadName==null||leadName==""}
              fullWidth
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button  sx={{color:'black'}}
        onClick={() => {handleClose()}} >
          Cancel
        </Button>
        <Button disabled={response==null||date==null||response==''||responderEmail==''||agentEmail==''||sugg==0}
         onClick={() => {
            postPQ(domain.id,newlead,lead.id,leadName,response,date.format('YYYY-MM-DD'),responderName,responderEmail,designation,topLead,leadSocials,topLeadSocials,sugg,agentEmail).then((res) => {
              setUpsuc(true);
              setB(!b);
              refresh(); // Reset all states to their default values
              handleClose();
            }).catch((err) => {
              console.log(err);
              setUpfail(true);
            })}}>
            
          Save
        </Button>
      </DialogActions>
    </Dialog>
    {/* {pq&&<PQDetails pq={pq}  openDialog1={openDialog1} handleClose1={handleClose1} setUpsuc1={setUpsuc1} setUpfail1={setUpfail1} setUpdatedPQ={setUpdatedPQ} />} */}
{pq&&<ViewPQDetails1 pq={pq}  open={openDialog1} handleClose={handleClose1} setUpsuc1={setUpsuc1} setUpfail1={setUpfail1} setUpfail={setUpfail} setUpsuc={setUpsuc} setUpdatedPQ={setUpdatedPQ} setB={setB} b={b} />}
<TabContext value={tvalue} >
    <Box sx={{ width: '100%', }}>
        
      <TabList
      //orientation="vertical"
         onChange={handleChangeT}
         sx={{
          //width: '540px',
             backgroundColor: '#ffffff',
             borderRadius: '0',
             '& .MuiTab-root': {
               '&.Mui-selected': {
                 backgroundColor: '#fef7ff',
               },
               '&:hover': {
                 backgroundColor: '#fef7ff',
               },
               '&.Mui-selected:hover': {
                 backgroundColor: '#fef7ff',
               },
             },
             
           }}
      >
        <Tab sx={{width:'180px'}} value="All" label={<Stack direction='row' alignItems='center' spacing={1.25}><Typography sx={{fontSize: '14px',fontWeight: '800',
                color: '#49454f',
                lineHeight: '1.25',}}>All</Typography></Stack>} />
                 <Tab sx={{width:'180px'}} value="Owned" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#C6CFFF'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>Owned</Typography></Stack>} />
        <Tab sx={{width:'180px'}} value="Ltd" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#FFC682'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454f',
                lineHeight: '1.25',}}>LTD</Typography></Stack>} />
          
                <Tab sx={{width:'180px'}}value="PD" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#C6CFFF'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>PD</Typography></Stack>} />
                <Tab sx={{width:'180px'}}value="Ald" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#C6CFFF'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>Ald</Typography></Stack>} />
                
      </TabList>
    </Box>
    </TabContext><Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid
        autoHeight 
        rows={tvalue==='All'?pqs:tvalue==='Owned'?pqsO:tvalue==='Ltd'?pqsL:tvalue==='PD'?pqsP:pqsA}
        columns={tvalue==='All'?columns:tvalue==='Owned'?columnsO:tvalue==='Ltd'?columnsL:columnsPNA}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        onRowClick={(row)=>{setPq(row.row);setOpenDialog1(true);}}
        //getRowId={(row)=>row.domain.domain}

       // checkboxSelection
        //onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      />
      </Box>
            </Stack>
            </ThemeProvider>
    )
}

const PQDetails=(props)=>{
  const [pq,setPq]=useState(props.pq);
  const [listPrice, setListPrice] = useState(props.pq.listPrice);
  const [floorPrice, setFloorPrice] = useState(props.pq.floorPrice);
  useEffect(()=>{
    setPq(props.pq);
    setListPrice(props.pq.listPrice?props.pq.listPrice:0);
    setFloorPrice(props.pq.floorPrice?props.pq.floorPrice:0);
    
  },[props.pq])
  const handleSubmit=()=>{
    setPricesPQ(pq.id,listPrice,floorPrice).then((res)=>{console.log(res);props.setUpsuc1(true);props.setUpdatedPQ(res.data);props.handleClose1();}).catch((err)=>{console.log(err);props.setUpfail1(true)})
  }

return(
<Dialog open={props.openDialog1} onClose={props.handleClose1} aria-labelledby="form-dialog-title">
  <DialogTitle id="form-dialog-title">PQ Details</DialogTitle>
  <DialogContent>
    
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box sx={{ mr: 1, width: "60%" }}>
        <Stack direction='row' alignItems='center' spacing={1}><Typography variant="subtitle1" fontWeight='bold'>{pq.domain.domain}</Typography>
        <Chip label={pq.domain.portfolio === 'owned' ? "Owned" : "Ltd"} size="small" sx={{ backgroundColor: pq.domain.portfolio === 'owned' ? '#C6CFFF' : '#FFC682', height: 17, fontSize: 10 }} />
</Stack>
        <Typography variant="subtitle1">Lead Name: {pq.siteLead || 'N/A'}</Typography>
        <Typography variant="subtitle1" noWrap>Email: {pq.email || 'N/A'}</Typography>

        <Typography variant="subtitle1" >Response: {pq.response || 'N/A'}</Typography>
      </Box>
      <Stack direction='column' alignItems='flex-end' sx={{ ml: 1, width: "40%" }}> 
      <Stack direction='row' spacing={1}><Stack direction='row' spacing={1}><Typography variant="subtitle1" fontWeight='bold'>EST:</Typography>
      <Typography variant="subtitle1" fontWeight='bold'>{pq.domain.EST || 'NA'}</Typography></Stack>
      <Stack direction='row' spacing={1}><Typography variant="subtitle1" fontWeight='bold'>GDV:</Typography>
      <Typography variant="subtitle1" fontWeight='bold'>{pq.domain.GDV || 'NA'}</Typography></Stack>
      </Stack>
        <Typography variant="subtitle1">Date: {pq.date.substring(0,10)}</Typography>
        <Typography variant="subtitle1">Name: {pq.name || 'N/A'}</Typography>
        <Typography variant="subtitle1">Designation: {pq.designation || 'N/A'}</Typography>
      </Stack>
    </Box>
{isAdmin()?<Stack direction="row" spacing={2} alignItems="center">
    <TextField
      margin="normal"
      id="listPrice"
      label="List Price"
      type="number"
      fullWidth
      value={listPrice}
      onChange={(e) => { setListPrice(e.target.value) }}
    />
    <TextField
      margin="normal"
      id="floorPrice"
      label="Floor Price"
      type="number"
      fullWidth
      value={floorPrice}
      onChange={(e) => { setFloorPrice(e.target.value) }}
    />
  </Stack>:
  <Stack direction="row" spacing={2} justifyContent="space-between">
  <Typography variant="subtitle1" fontWeight='bold'>List Price: {listPrice<=0?"N/A":listPrice}</Typography>
  <Typography variant="subtitle1" fontWeight='bold'>Floor Price: {floorPrice<=0?"N/A":floorPrice}</Typography>
  </Stack>
  }
  </DialogContent>
  <DialogActions>
    <Button sx={{ color: 'primary' }} onClick={handleSubmit}>
      Submit
    </Button>
    <Button sx={{ color: 'secondary' }} onClick={() => { props.handleClose1() }}>
      Close
    </Button>
  </DialogActions>
</Dialog>
)
}

const ViewPQDetails = ({ pq, open, handleClose }) => {
  const currentUserEmail = getUserName(); // Assuming getUserName() fetches the current user's email
  const isEditable = pq.user.email === currentUserEmail;

  useEffect(()=>{
  if (!pq.socialWork) {
    pq.socialWork = {
      topLeads: [
        {
          domain: "aa",
          description: null,
          socials: [
            { platform: 'Linkedin', employees: 0 },
            { platform: 'Facebook', followers: 0 },
            { platform: 'Instagram', followers: 0 },
            { platform: 'Twitter', followers: 0 }
          ]
        }
      ]
    };
  } else {
    const platforms = ['Linkedin', 'Facebook', 'Instagram', 'Twitter'];
    const existingPlatforms = pq.socialWork.topLeads[0].socials.map(s => s.platform);
    platforms.forEach(platform => {
      if (!existingPlatforms.includes(platform)) {
        const social = (platform === 'Linkedin') ? { platform, employees: 0 } : { platform, followers: 0 };
        pq.socialWork.topLeads[0].socials.push(social);
      }
    });
  }
  },[])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md' aria-labelledby="view-pq-dialog-title">
      <DialogTitle id="view-pq-dialog-title">View PQ Details</DialogTitle>
      <DialogContent>
        <Stack direction='row' justifyContent='space-between' width='100%'>
          <Stack spacing={2} direction='row'>
            <Typography>EST:</Typography>
            <Typography fontWeight='bold'>{pq.domain.EST || 'NA'}</Typography>
            <Typography>GDV:</Typography>
            <Typography fontWeight='bold'>{pq.domain.GDV || 'NA'}</Typography>
            {pq.domain.portfolio==='ltd'?<><Typography>Deadline: {pq.domain.deadline.substring(0,10)}</Typography></>:null}
            {pq.domain.portfolio==='pd'||pq.domain.portfolio==='ald'?<><Typography>Order Date: {pq.domain.orderDate.substring(0,10)}</Typography>
            <Typography>Order Status: {pq.domain.orderStatus}</Typography></>:null}
{pq.domain.portfolio==='owned'&&<><Typography>List Price: {pq.domain.listPrice}</Typography>
            <Typography>Floor Price: {pq.domain.floorPrice}</Typography>
            </>}
          </Stack>
          <Stack direction='row'>
            <Chip label={pq.domain.portfolio === 'owned' ? "Owned" : "Ltd"} size="small" sx={{ backgroundColor: pq.domain.portfolio === 'owned' ? '#C6CFFF' : '#FFC682', height: 17, fontSize: 10 }} />
          </Stack>
        </Stack>
        <Stack spacing={2} mt={2}>
          <TextField
            label="Domain"
            value={pq.domain.domain}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Lead Name"
            value={pq.siteLead}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Email"
            value={pq.email}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Designation"
            value={pq.designation}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Response"
            value={pq.response}
            fullWidth
            multiline
            rows={4.5}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Suggested Price"
            value={pq.sugg}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date"
              inputFormat="DD-MM-YYYY"
              value={dayjs(pq.date)}
              onChange={() => {}}
              renderInput={(params) => <TextField {...params} InputProps={{ readOnly: true }} />}
            />
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const ViewPQDetails1 = ({ pq, open, handleClose,setUpsuc,setUpfail,setUpsuc1,setUpfail1,setUpdatedPQ, setB,b }) => {
  const [isEditable,setIsEditable] = useState(false);
  const [pqData, setPqData] = useState(pq);
  const [hasChanged, setHasChanged] = useState(false);
  const [isAdmin1,setIsAdmin1]=useState(false);

  useEffect(()=>{
    setIsAdmin1(isAdmin());
  },[])
  useEffect(() => {
    setIsEditable(pq.user.email === getUserName());

    let pq1=pq;
    if (!pq1.socialWork) {
      pq1.socialWork = {
        topLeads: [
          {
            domain: "",
            description: null,
            socials: [
              { platform: 'Linkedin', employees: 0 },
              { platform: 'Facebook', followers: 0 },
              { platform: 'Instagram', followers: 0 },
              { platform: 'Twitter', followers: 0 }
            ]
          }
        ]
      };
    } else {
      const platforms = ['Linkedin', 'Facebook', 'Instagram', 'Twitter'];
      const existingPlatforms = pq1.socialWork.topLeads[0].socials.map(s => s.platform);
      platforms.forEach(platform => {
        if (!existingPlatforms.includes(platform)) {
          const social = (platform === 'Linkedin') ? { platform, employees: 0 } : { platform, followers: 0 };
          pq1.socialWork.topLeads[0].socials.push(social);
        }
      });


    }

    if (!pq1.lead) {
      pq1.lead={
            domain: "",
            description: null,
            socials: [
              { platform: 'Linkedin', employees: 0 },
              { platform: 'Facebook', followers: 0 },
              { platform: 'Instagram', followers: 0 },
              { platform: 'Twitter', followers: 0 }
            ]
          
      };
    } else {
      const platforms = ['Linkedin', 'Facebook', 'Instagram', 'Twitter'];
      const existingPlatforms = pq1.lead.socials.map(s => s.platform);
      platforms.forEach(platform => {
        if (!existingPlatforms.includes(platform)) {
          const social = (platform === 'Linkedin') ? { platform, employees: 0 } : { platform, followers: 0 };
          pq1.lead.socials.push(social);
        }
      });


    }

    
    setPqData(pq1);
    setHasChanged(false); // Reset change tracking on prop change
  }, [pq]);

  useEffect(()=>{
    console.log(isEditable);
  },[isEditable])

  const handleChange = (field, value) => {
    setPqData(prev => {
      const updated = { ...prev, [field]: value };
      return updated;
    });
    setHasChanged(true);
  };

  const handleSocialChange = (platform, newValue) => {
    setPqData(prev => ({
      ...prev,
      lead: {
        ...prev.lead,
        socials: prev.lead.socials.map(social => social.platform === platform ? { ...social, followers: Number(newValue), employees: Number(newValue) } : social)
      }
    }));
    setHasChanged(true);
    
  };
  const handleTopLeadsSocialChange = (platform, newValue) => {
    setPqData(prev => ({
      ...prev,
      socialWork: {
        ...prev.socialWork,
        topLeads: [{ ...prev.socialWork.topLeads[0], socials: prev.socialWork.topLeads[0].socials.map(social => social.platform === platform ? { ...social, followers: Number(newValue), employees: Number(newValue) } : social) }]
      }
    }));
    setHasChanged(true);
  };

  const handleTopLeadChange = (newValue) => {
    setPqData(prev => ({
      ...prev,
      socialWork: {
        ...prev.socialWork,
        topLeads: [{ ...prev.socialWork.topLeads[0], domain: newValue }]
      }
    }));
    setHasChanged(true);
  };
 let handleSave=()=>{
  console.log(pqData);
  putPQ(pqData).then((res)=>{
    setUpsuc(true);
   // setUpdatedPQ(res.data);
    setB(!b)
    handleClose();
  }).catch((e)=>{
    setUpfail(true);
  })
 }
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md' h aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add PQ</DialogTitle>
      <DialogContent>
     
        <Box sx={{ display: "flex", justifyContent: "space-between", mt:2 }}>
          <Box sx={{ mr: 1, width: "50%" }}>
          <TextField
              id="domain"
              label="Domain"
              value={pqData.domain!=null&&pqData.domain.domain!=null?pqData.domain.domain:'NA'}
              fullWidth
              disabled
            />
             <Box sx={{
              border: '1px solid #c4c4c4',
              borderRadius: '4px',
              padding: '10px 14px',
              width: '100%',
              minHeight: '90px',//'56px' for MUI TextField
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              marginTop:2
            }}>
              {(pqData!=null&&pqData.domain!=null)? 
                <Stack direction='column' spacing={0.5} >
                  <Stack spacing={2} direction='row' flexWrap="wrap">
                    <Chip label={pqData.domain.portfolio} size="small" sx={{backgroundColor:pqData.domain.portfolio==='owned'?'#C6CFFF':'#FFC682',height:17,fontSize:10}} />
                    <Stack direction='row' spacing={1}><Typography variant="body2">EST:</Typography>
                    <Typography variant="body2" fontWeight='bold'>{pqData!=null&&pqData.domain!=null?pqData.domain.EST:'NA'}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={1}><Typography variant="body2">GDV:</Typography>
                    <Typography variant="body2" fontWeight='bold'>{pqData!=null&&pqData.domain!=null?pqData.domain.GDV:'NA'}</Typography>
                    </Stack>
                    
                    {pqData.domain.portfolio==='ltd'?<Typography variant="body2">Deadline: {pqData.domain.deadline.substring(0,10)}</Typography>:null}
                    {pqData.domain.portfolio==='pd'||pqData.domain.portfolio==='ald'?
                      <>
                        <Typography variant="body2">OD: {pqData.domain.orderDate.substring(0,10)}</Typography>
                        <Typography variant="body2">OS: {pqData.domain.orderStatus}</Typography>
                      </>:null}
                    {pqData.domain.portfolio==='owned'&&
                      <>
                        <Typography variant="body2">LP: {pqData.domain.listPrice}</Typography>
                        <Typography variant="body2">FP: {pqData.domain.floorPrice}</Typography>
                      </>}
                  </Stack>
                  <Stack spacing={2} direction='row' flexWrap="wrap">
                    <Typography variant='body2'>SG: {pqData.domain.sg}</Typography>
                    <Typography variant='body2'>CPC: {pqData.domain.cpc}</Typography>
                    <Typography variant='body2'>Reg: {pqData.domain.reg}</Typography>
                    <Typography variant='body2'>RegX: {pqData.domain.regx}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems="center">
              <Typography variant="body2" fontWeight="bold">
                Top Lead:
              </Typography>
              <Typography variant="body2">
                {} N/A
              </Typography>
              
              <Typography variant="body2">
                FB: {0}
              </Typography>
              <Typography variant="body2">
                LN: {0}
              </Typography>
              <Typography variant="body2">
                IG: {0}
              </Typography>
              <Typography variant="body2">
                X: {0}
              </Typography>
            </Stack>
                </Stack>
                :<Typography variant='body2'>No Domain Selected</Typography>
              }
            </Box>
            <Autocomplete
              disablePortal
              disabled
              id="combo-box-demo"
              freeSolo
              value={pqData.lead!=null?pqData.lead.site:null}
              sx={{marginTop:2, marginBottom:1 }}
              fullWidth
              renderInput={(params) => <TextField  {...params} type="text" label="Lead Name" />}
            />
             <Stack mt={2} direction='row' spacing={2}>
            <TextField
  margin="normal"
  id="Facebook"
  label="FB"
  type="number"
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  onChange={(e)=>handleSocialChange('Facebook',e.target.value)}
  value={pqData.lead && pqData.lead.socials.find(lead => lead.platform === 'Facebook')? pqData.lead.socials.find(lead => lead.platform === 'Facebook').followers : 0}
  fullWidth
  //disabled
/>

<TextField
  margin="normal"
    id="Linkedin"
  label="LN"
  type="number"
  onChange={(e)=>handleSocialChange('Linkedin',e.target.value)}
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  value={pqData.lead &&pqData.lead.socials.find(lead => lead.platform === 'Linkedin')? pqData.lead.socials.find(lead => lead.platform === 'Linkedin').employees : 0}
  fullWidth
/>

<TextField
  margin="normal"
  id="instagram"
  label="IG"
  type="number"
  onChange={(e)=>handleSocialChange('Instagram',e.target.value)}
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  value={pqData.lead && pqData.lead.socials.find(lead => lead.platform === 'Instagram')? pqData.lead.socials.find(lead => lead.platform === 'Instagram').followers : 0}
  fullWidth
/>

<TextField
  margin="normal"
  id="Twitter"
  label="X"
  type="number"
  onChange={(e)=>handleSocialChange('Twitter',e.target.value)}
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  value={pqData.lead && pqData.lead.socials.find(lead => lead.platform === 'Twitter')? pqData.lead.socials.find(lead => lead.platform === 'Twitter').followers : 0}
  fullWidth
/>
      </Stack>
            {/* {<TextField
              margin="normal"
              id="toplead"
              label="Top Lead"
              value={pqData.socialWork!=null&&pqData.socialWork.topLeads.length>0?pqData.socialWork.topLeads[0].site:null}
              fullWidth
              onChange={(e)=>handleTopLeadChange(e.target.value)}
              disabled={isEditable?false:true}
            />} */}
            <TextField
              margin="normal"
              id="agent-email"
              label="Agent Email"
              type="email"
              onChange={(e)=>handleChange('agentEmail',e.target.value)}
              fullWidth
              value={pqData.agentEmail}
              //disabled
            />
            
            <Stack direction='row' spacing={2} sx={{mt:2}}>
            <TextField
            
              id="suggested-price"
              label="SUGG"
              type="number"
              fullWidth
              onChange={(e)=>handleChange('sugg',e.target.value)}
              value={pqData.sugg}
            />
            <TextField
           // sx={{mt:3}}
              id="quote"
              label="QUOTE"
              type="number"
              fullWidth
              disabled={!isAdmin}
              onChange={(e)=>handleChange('quote',e.target.value)}
              value={pqData.quote}
            />
            
            </Stack>
          </Box>
          <Box sx={{ ml: 1, width: "50%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Enter Date"
                format='DD-MM-YYYY'
                value={dayjs(pqData.date)}
                sx={{width:'100%'}}
                onChange={(e)=>handleChange('date',e.toISOString().split('T')[0])}
                renderInput={(params) => <TextField fullWidth {...params} />}
                
              />
            </LocalizationProvider>
           
            {/* <Stack mt={3} mb={2} direction='row' spacing={2}>
            <TextField
  margin="normal"
  id="Facebook"
  label="FB"
  type="number"
  onChange={(e)=>handleTopLeadsSocialChange('Facebook',e.target.value)}
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  value={pqData.socialWork && pqData.socialWork.topLeads[0].socials.find(lead => lead.platform === 'Facebook')? pqData.socialWork.topLeads[0].socials.find(lead => lead.platform === 'Facebook').followers : 0}
  fullWidth
/>

<TextField
  margin="normal"
  id="Linkedin"
  label="LN"
  type="number"
  onChange={(e)=>handleTopLeadsSocialChange('Linkedin',e.target.value)}
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  value={pqData.socialWork && pqData.socialWork.topLeads[0].socials.find(lead => lead.platform === 'Linkedin')? pqData.socialWork.topLeads[0].socials.find(lead => lead.platform === 'Linkedin').employees : 0}
  fullWidth
/>

<TextField
  margin="normal"
  id="Instagram"
  label="IG"
  type="number"
  onChange={(e)=>handleTopLeadsSocialChange('Instagram',e.target.value)}
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  value={pqData.socialWork && pqData.socialWork.topLeads[0].socials.find(lead => lead.platform === 'Instagram')? pqData.socialWork.topLeads[0].socials.find(lead => lead.platform === 'Instagram').followers : 0}
  fullWidth
/>

<TextField
  margin="normal"
  id="Twitter"
  label="X"
  type="number"
  onChange={(e)=>handleTopLeadsSocialChange('Twitter',e.target.value)}
  sx={{
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
    "& input[type=number]": { MozAppearance: "textfield" },
  }}
  value={pqData.socialWork && pqData.socialWork.topLeads[0].socials.find(lead => lead.platform === 'Twitter')? pqData.socialWork.topLeads[0].socials.find(lead => lead.platform === 'Twitter').followers : 0}
  fullWidth
/>
            </Stack> */}
            <TextField
              margin="normal"
              id="responder-name"
              label="Responder Name"
              type="text"
              onChange={(e)=>handleChange('name',e.target.value)}
              fullWidth
              value={pqData.name}
              //disabled
            />
            <TextField
              margin="normal"
              id="responder-email"
              label="Responder Email"
              type="email"
              onChange={(e)=>handleChange('email',e.target.value)}
              fullWidth
              value={pqData.email}
              //disabled
            />
            <TextField
              margin="normal"
              id="designation"
              label="Designation"
              type="text"
              onChange={(e)=>handleChange('designation',e.target.value)}
              value={pqData.designation}
              fullWidth
              //disabled
            />
             <TextField
             sx={{mt:3}}
              id="response"
              label="Response"
              multiline
              rows={4.5}
              onChange={(e)=>handleChange('response',e.target.value)}
              value={pqData.response}
              fullWidth
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        
      {(isEditable||isAdmin1)&&<Button  color="error"
        onClick={() => {deletePQ(pqData.id).then((res)=>{handleClose();setB(!b)}).catch((e)=>{console.log(e)})}} >
          Delete PQ
        </Button>}
        <Box display='flex' flexGrow={1}/>
        {!isEditable&&isAdmin1&&<Button  
        disabled={!hasChanged}
        onClick={() => {quotePrice(pqData.id,pqData.quote).then((res)=>{setUpsuc1(true);setUpdatedPQ(res.data)}).catch((e)=>{setUpfail1(true);console.log(e)})}} >
          Quote Price
        </Button>}
        {isEditable&&<><Button  sx={{color:'black'}}
        onClick={() => {handleClose()}} >
          Cancel
        </Button>
        <Button 
        disabled={!hasChanged}
        onClick={()=>handleSave()}
        > 
          Save
        </Button></>}
      </DialogActions>
    </Dialog>
  );
};
