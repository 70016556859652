import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box, Stack, Tab, ThemeProvider, Typography, Button, Snackbar, Alert,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, InputLabel, MenuItem, Select, FormControlLabel,
  Tooltip,Switch,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Add, Star } from "@mui/icons-material";
import { theme } from "../Theme";
import AqTable from '../AcquisitionCenter/AqTable';
import { isAdmin, isESManager, isESAnalyst, isEDManager, isEDAnalyst } from "../msalService";
import UploadCSVDialogShortlistedDomain from "../Common/UploadCSVDialogShortlistedDomain";
import {
  getAcquShortlistedDomains, getSocialChildren, getTopLeadEmailAgent, getEsShortlistedDomains, getEsAnalystShortlistedDomains, uploadShortlistedDomains,
  moveToReco, moveToReasearch, moveToRecoEsAnalyst, moveToResearchEsAnalyst, allocateToSocialUser, allocateToEmailProcessor, moveToEmailCenter,
  getEsAnalystShortlistedDomainsPaginated,
  getAcquShortlistedDomainsPaginated,
  getEsShortlistedDomainsPaginated,
  uploadShortlistedDomainES
} from "../api";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import QaAqLeadsDialog from '../Common/QaAqLeadsDialog';
import UpdateShortlistedDomain from '../Common/UpdateShortlistedDomain';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ShortlistedDomains() {
  const isAdminUser = isAdmin();
  const isESManagerUser = isESManager();
  const isESAnalystUser = isESAnalyst();
  const isEDManagerUser = isEDManager();
  const isEDAnalystUser = isEDAnalyst();

  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [totalElements, setTotalElements] = useState(0);

  // Core data states
  const [domains, setDomains] = useState([]);
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [newDomains, setNewDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeView, setActiveView] = useState("adminView");
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // User lists
  const [children, setChildren] = useState([]); // Social users
  const [emailAgent, setEmailAgent] = useState([]); // Email agents

  // Filters and tabs
  const [mode, setMode] = useState('ed');
  const [tabValue, setTabValue] = useState('All');
  const [domainTypeFilter, setDomainTypeFilter] = useState('All');
  const [emailStateFilter, setEmailStateFilter] = useState('All');
  const [showDomainWithLeadsOnly, setShowDomainWithLeadsOnly] = useState(false);

  // Selection and actions
  const [selectedResearchAction, setSelectedResearchAction] = useState("");
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [allocating, setAllocating] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // Status indicators
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const [allocateSuccess, setAllocateSuccess] = useState(false);
  const [allocateFail, setAllocateFail] = useState(false);

  // Dialog controls
  const [openDialog, setOpenDialog] = useState(false);
  const [openAllocateDialog, setOpenAllocateDialog] = useState(false);
  const [openEmailAllocateDialog, setOpenEmailAllocateDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);


  useEffect(() => {
    let initialMode = 'ed';

    if (isAdminUser || isEDManagerUser || isEDAnalystUser) {
      initialMode = 'ed';
    } else if (isESManagerUser || isESAnalystUser) {
      initialMode = 'es';
    }
    setMode(initialMode);

    getSocialChildren().then(res => {
      setChildren(res.data);
    }).catch(err => console.log(err));

    getTopLeadEmailAgent().then(res => {
      setEmailAgent(res.data);
    }).catch(err => console.log(err));

  }, [isAdminUser, isESManagerUser, isESAnalystUser, isEDManagerUser, isEDAnalystUser]);


  useEffect(() => {
    setActiveView(getActiveView());
  }, [mode, isAdmin, isESManager, isESAnalyst, isEDManager, isEDAnalyst]);

  const getActiveView = () => {
    //for testing es-analyst flow uncomment next line
    //return 'esAnalystView';
    if (isAdmin()) {
      return 'adminView';
    }
    if (isESManager() || isEDManager()) {
      return 'managerialView';
    }
    if (isESAnalyst() && mode === 'es') {
    return 'esAnalystView';
    }
    return 'adminView';
  };

  const handleDataChange = useCallback(() => {
    fetchDomains(mode);
  }, [mode]);

  useEffect(() => {
    fetchDomains(mode);
  }, [tabValue, showDomainWithLeadsOnly, pagination, sorting, columnFilters, globalSearch, domainTypeFilter, emailStateFilter]);

  const fetchDomains = useCallback((
    currentMode,
    currentTab = tabValue,
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);
    setDomains([]);
    let apiCall;

    if (activeView === "esAnalystView") {
    apiCall = getEsAnalystShortlistedDomainsPaginated;
    } else {
      apiCall = currentMode === 'ed' ? getAcquShortlistedDomainsPaginated : getEsShortlistedDomainsPaginated;
    }

    // Transform filters into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    // Transform sorting into the expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    try {
      apiCall({
        mode: currentMode,
        tab: currentTab,
        showDomainWithLeadsOnly,
        page: paginationRules.pageIndex,
        size: paginationRules.pageSize,
        filter: JSON.stringify(transformedFilters),
        sort: JSON.stringify(transformedSorting),
        search: searchQuery,
        domainType: domainTypeFilter === 'All' ? null : domainTypeFilter,
        emailState: emailStateFilter === 'All' ? null : emailStateFilter
      })
        .then(response => {
          if (response && response.data) {
            const transformedData = response.data.content.map(item => ({
              acquShortlistedDomain: {
                id: item.id,
                domain: activeView === "esAnalystView" ? item.shortlistedDomain?.domain : item.domain,
                list: activeView === "esAnalystView" ? item.shortlistedDomain?.list : item.list,
                host: activeView === "esAnalystView" ? item.shortlistedDomain?.host : item.host,
                tld: activeView === "esAnalystView" ? item.shortlistedDomain?.tld : item.tld,
                wby: activeView === "esAnalystView" ? item.shortlistedDomain?.wby : item.wby,
                aby: activeView === "esAnalystView" ? item.shortlistedDomain?.aby : item.aby,
                reg: activeView === "esAnalystView" ? item.shortlistedDomain?.reg : item.reg,
                regx: activeView === "esAnalystView" ? item.shortlistedDomain?.regx : item.regx,
                sg: activeView === "esAnalystView" ? item.shortlistedDomain?.sg : item.sg,
                cpc: activeView === "esAnalystView" ? item.shortlistedDomain?.cpc : item.cpc,
                bids: activeView === "esAnalystView" ? item.shortlistedDomain?.bids : item.bids,
                price: activeView === "esAnalystView" ? item.shortlistedDomain?.price : item.price,
                est: activeView === "esAnalystView" ? item.shortlistedDomain?.est : item.est,
                gdv: activeView === "esAnalystView" ? item.shortlistedDomain?.gdv : item.gdv,
                edate: activeView === "esAnalystView" ? item.shortlistedDomain?.edate : item.edate,
                createdAt: activeView === "esAnalystView" ? item.shortlistedDomain?.createdAt : item.createdAt,
                p: activeView === "esAnalystView" ? item.shortlistedDomain?.p : item.p,
                len: activeView === "esAnalystView" ? item.shortlistedDomain?.len : item.len,
                esw: activeView === "esAnalystView" ? item.shortlistedDomain?.esw : item.esw,
                gdw: activeView === "esAnalystView" ? item.shortlistedDomain?.gdw : item.gdw,
                aqCategory: activeView === "esAnalystView" ? item.shortlistedDomain?.aqCategory : item.aqCategory,
                domainType: activeView === "esAnalystView" ? item.shortlistedDomain?.domainType : item.domainType,
                spr500: activeView === "esAnalystView" ? item.shortlistedDomain?.spr500 : item.spr500,
                remarks: activeView === "esAnalystView" ? item.shortlistedDomain?.remarks : item.remarks
              },
              socialLeads: item.socialDomain?.socialWorks?.[0]?.topLeads?.map(lead => {
                const leadData = lead.correctedLead || lead;
                return {
                  id: leadData.id,
                  domain: leadData.domain,
                  domainSnapShotUrl: leadData.domainSnapShotUrl,
                  copyright: leadData.copyright,
                  description: leadData.description,
                  isIrrelevant: leadData.irrelevant ?? false,
                  missedStatus: leadData.missedStatus ?? 0,
                  code: leadData.code,
                  noSocialsAvailable: leadData.noSocialsAvailable ?? false,
                  isInfluencer: leadData.isInfluencer ?? false,
                  leadStatus: leadData.leadStatus ?? "active",
                  wrongSocialStatus: leadData.wrongSocialStatus ?? 0,
                  presentAllocation: leadData.presentAllocation,
                  socials: lead.socials?.map(social => {
                    const socialData = social.correctedSocial || social;
                    return {
                      id: socialData.id,
                      platform: socialData.platform.toLowerCase(),
                      status: socialData.status,
                      followers: socialData.followers,
                      employees: socialData.employees,
                      url: socialData.url,
                      userFullName: socialData.userFullName,
                      isIrrelevant: socialData.irrelevant ?? false,
                      missedStatus: socialData.missedStatus ?? 0,
                      wrongSocialStatus: socialData.wrongSocialStatus ?? 0
                    };
                  }) || [],
                  email: lead.emailWorks?.[0]?.emails?.[0] ? {
                    id: lead.emailWorks[0].emails[0].id,
                    email: lead.emailWorks[0].emails[0].email,
                    name: lead.emailWorks[0].emails[0].name,
                    designation: lead.emailWorks[0].emails[0].designation,
                    linkedinUrl: lead.emailWorks[0].emails[0].linkedinUrl
                  } : null
                };
              }) || [],
              socialWorkId: item.socialDomain?.socialWorks?.[0]?.id || null,
              reviewRemark: item.socialDomain?.socialWorks?.[0]?.reviewRemark,
              noLeadsAvailable: item.socialDomain?.socialWorks?.[0]?.noLeadsAvailable,
              agents: {
                uploader: activeView === "esAnalystView"
                  ? (item.shortlistedDomain?.user ? {
                    name: `${item.shortlistedDomain.user.firstName} ${item.shortlistedDomain.user.lastName}`,
                    email: item.shortlistedDomain.user.email,
                    role: 'Uploader'
                  } : null)
                  : (item.user ? {
                    name: `${item.user.firstName} ${item.user.lastName}`,
                    email: item.user.email,
                    role: 'Uploader'
                  } : null),
                processor: item.socialDomain?.socialWorks?.[0]?.user ? {
                  name: `${item.socialDomain.socialWorks[0].user.firstName} ${item.socialDomain.socialWorks[0].user.lastName}`,
                  email: item.socialDomain.socialWorks[0].user.email,
                  role: 'Processor'
                } : null,
                reviewer: item.socialDomain?.socialWorks?.[0]?.reviewer ? {
                  name: `${item.socialDomain.socialWorks[0].reviewer.firstName} ${item.socialDomain.socialWorks[0].reviewer.lastName}`,
                  email: item.socialDomain.socialWorks[0].reviewer.email,
                  role: 'Reviewer',
                  reviewStatus: item.socialDomain.socialWorks[0].reviewStatus,
                  reviewRemark: item.socialDomain.socialWorks[0].reviewRemark,
                  noLeadsAvailable: item.socialDomain.socialWorks[0].noLeadsAvailable,
                } : null,
                recommender: activeView === "esAnalystView"
                  ? (item.shortlistedDomain?.recoBy ? {
                    name: typeof item.shortlistedDomain.recoBy === 'string'
                      ? item.shortlistedDomain.recoBy
                      : `${item.shortlistedDomain.recoBy.firstName} ${item.shortlistedDomain.recoBy.lastName}`,
                    email: item.shortlistedDomain.recoBy?.email,
                    role: 'Recommender'
                  } : null)
                  : (item.recoBy ? {
                    name: typeof item.recoBy === 'string'
                      ? item.recoBy
                      : `${item.recoBy.firstName} ${item.recoBy.lastName}`,
                    email: item.recoBy?.email,
                    role: 'Recommender'
                  } : null),
                approver: activeView === "esAnalystView"
                  ? (item.shortlistedDomain?.aprUnaprBy ? {
                    name: typeof item.shortlistedDomain.aprUnaprBy === 'string'
                      ? item.shortlistedDomain.aprUnaprBy
                      : `${item.shortlistedDomain.aprUnaprBy.firstName} ${item.shortlistedDomain.aprUnaprBy.lastName}`,
                    email: item.shortlistedDomain.aprUnaprBy?.email,
                    role: 'Approver'
                  } : null)
                  : (item.aprUnaprBy ? {
                    name: typeof item.aprUnaprBy === 'string'
                      ? item.aprUnaprBy
                      : `${item.aprUnaprBy.firstName} ${item.aprUnaprBy.lastName}`,
                    email: item.aprUnaprBy?.email,
                    role: 'Approver'
                  } : null)
              }
            }));

            setDomains(transformedData);
            setTotalElements(response.data.totalElements || 0);
          } else {
            console.error('Invalid API response format:', response);
            setDomains([]);
          }
        })
        .catch(error => {
          console.error('API call failed:', error);
          setDomains([]);
          setTotalElements(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error in fetchDomains:', error);
      setLoading(false);
      setDomains([]);
      setTotalElements(0);
    }
  }, [activeView, showDomainWithLeadsOnly, columnFilters, sorting, pagination, tabValue, globalSearch, domainTypeFilter, emailStateFilter]);


  useEffect(() => {
    if (newDomains && newDomains.length > 0) {
      console.log(newDomains);
      const uploadFunction = mode === 'es' ? uploadShortlistedDomainES : uploadShortlistedDomains;
      
      uploadFunction(newDomains).then(() => {

        setUploadSuccess(true);
        setNewDomains([]);
        fetchDomains(mode);
        setRefresh(!refresh);
      }).catch(err => {
        console.log(err);
        setUploadFail(true);
      });
    }
  }, [newDomains, mode]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleResearchTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderTabLabel = (tabValue) => {
    const tabLabels = {
      'All': 'All',
      'PendingResearch': 'Pending Research',
      'DoneResearch': 'Done Research'
    };
    return tabLabels[tabValue] || tabValue;
  };

  const handleResearchActionChange = (e) => {
    const action = e.target.value;
    setSelectedResearchAction(action);

    if (action === 'allocate') {
      setOpenAllocateDialog(true);
    } else if (action === 'claim') {
      // Get selected rows from the table
      const selectedRows = Object.keys(rowSelection)
        .filter(key => rowSelection[key])
        .map(key => domains[parseInt(key)])
        .filter(row => row && row.acquShortlistedDomain);

      // Map to just the IDs
      const selectedDomainIds = selectedRows
        .map(row => row.acquShortlistedDomain.id)
        .filter(id => id);

      if (selectedDomainIds.length === 0) {
        setSnackbar({
          open: true,
          message: 'No valid domains selected',
          severity: 'warning',
          autoHideDuration: 2000,
        });
        setSelectedResearchAction("");
        return;
      }

      const moveFunction = activeView === "esAnalystView"
        ? moveToResearchEsAnalyst
        : moveToReasearch;

      setLoading(true);
      moveFunction(selectedDomainIds)
        .then(() => {
          setSnackbar({
            open: true,
            message: 'Successfully moved domains to research hub',
            severity: 'success',
            autoHideDuration: 2000,
          });
          fetchDomains(mode);
          setRowSelection({});
          setSelectedResearchAction("");
        })
        .catch((err) => {
          console.error('Failed to move domains:', err);
          setSnackbar({
            open: true,
            message: err.message || 'Failed to move domains to research hub',
            severity: 'error',
            autoHideDuration: 2000,
          });
          setSelectedResearchAction("");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleMoveToReco = () => {
    setLoading(true);

    // Get selected rows from the table
    const selectedRows = Object.keys(rowSelection)
      .filter(key => rowSelection[key])
      .map(key => domains[parseInt(key)])
      .filter(row => row && row.acquShortlistedDomain);

    // Map to just the IDs
    const selectedDomainIds = selectedRows
      .map(row => row.acquShortlistedDomain.id)
      .filter(id => id);

    if (selectedDomainIds.length === 0) {
      setSnackbar({ 
        open: true, 
        message: 'No valid domains selected', 
        severity: 'warning' 
      });
      setLoading(false);
      return;
    }

    const apiCall = activeView === "esAnalystView" ? moveToRecoEsAnalyst : moveToReco;

    apiCall(selectedDomainIds)
      .then(() => {
        setSnackbar({ open: true, message: 'Moved to reco successfully', severity: 'success' });
        setRowSelection({});
        fetchDomains(mode);
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Error moving to reco', severity: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const handleAllocateToUser = () => {
    const selectedRows = Object.keys(rowSelection)
      .filter(key => rowSelection[key])
      .map(key => domains[parseInt(key)])
      .filter(row => row && row.acquShortlistedDomain);

    const selectedIds = selectedRows
      .map(row => row.acquShortlistedDomain.id)
      .filter(id => id);

    if (selectedIds.length === 0) {
      setSnackbar({
        open: true,
        message: 'No valid domains selected',
        severity: 'warning',
        autoHideDuration: 2000,
      });
      return;
    }

    const payload = {
      domainIds: selectedIds,
      user: selectedUser,
    };

    setAllocating(true);
    allocateToSocialUser(payload).then(() => {
      setSnackbar({
        open: true,
        message: 'Successfully allocated domains to social user',
        severity: 'success',
        autoHideDuration: 2000,
      });
      setOpenAllocateDialog(false);
      setSelectedResearchAction("");
      setSelectedUser('');
      setAllocating(false);
    }).catch(err => {
      console.error(err);
      setSnackbar({
        open: true,
        message: 'Failed to allocate domains to social user',
        severity: 'error',
        autoHideDuration: 2000,
      });
      setAllocating(false);
    });
  };

  const handleAllocateToEmailUser = () => {
    const selectedRows = Object.keys(rowSelection)
      .filter(key => rowSelection[key])
      .map(key => domains[parseInt(key)])
      .filter(row => row && row.acquShortlistedDomain);

    const selectedIds = selectedRows
      .map(row => row.acquShortlistedDomain.id)
      .filter(id => id);

    if (selectedIds.length === 0) {
      setSnackbar({
        open: true,
        message: 'No valid domains selected',
        severity: 'warning',
        autoHideDuration: 2000,
      });
      return;
    }

    const payload = {
      domainIds: selectedIds,
      user: selectedUser,
    };

    setAllocating(true);
    allocateToEmailProcessor(payload).then(() => {
      setSnackbar({
        open: true,
        message: 'Successfully allocated domains to email user',
        severity: 'success',
        autoHideDuration: 2000,
      });
      setOpenEmailAllocateDialog(false);
      setSelectedUser('');
      setAllocating(false);
    }).catch(err => {
      console.error(err);
      setSnackbar({
        open: true,
        message: 'Failed to allocate domains to email user',
        severity: 'error',
        autoHideDuration: 2000,
      });
      setAllocating(false);
    });
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'actions',
      header: 'review',
      size: 60,
      minSize: 60,
      maxSize: 70,
      Cell: ({ row }) => {
        const hasLeads = row.original.socialLeads?.length > 0;
        const noLeadsAvailable = row.original.socialWorkId && row.original.noLeadsAvailable;
        const canReview = hasLeads || noLeadsAvailable;
        
        return (
          <Tooltip title={
            noLeadsAvailable ? "Review No Leads Available" :
            hasLeads ? "Edit Leads & Socials" : 
            "Socials not done"
          }>
            <span>
              <IconButton
                size="small"
                onClick={() => {
                  setSelectedRow(row.original);
                  setEditDialogOpen(true);
                }}
                disabled={!canReview}
                sx={{
                  '&.Mui-disabled': {
                    opacity: 0.5,
                  }
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'emailState',
      header: 'EStatus',
      size: 60,
      Cell: ({ row }) => {
        const socialLeads = row.original.socialLeads || [];
        const totalLeads = socialLeads.length;

        if (totalLeads === 0) return null;

        let leadsSubmitted = 0;
        let leadsInProgress = 0;
        let leadsNotStarted = 0;
        let unallocated = 0;

        socialLeads.forEach(lead => {
          if (!lead.presentAllocation) {
            unallocated++;
          } else {
            switch (lead.presentAllocation.status) {
              case 'submitted':
                leadsSubmitted++;
                break;
              case 'in progress':
                leadsInProgress++;
                break;
              case 'not started':
                leadsNotStarted++;
                break;
              default:
                leadsNotStarted++;
            }
          }
        });

        // Determine the state
        let state;
        if (unallocated === totalLeads) {
          state = 'unallocated';
        } else if (leadsSubmitted === totalLeads) {
          state = 'done';
        } else if (leadsSubmitted > 0 || leadsInProgress > 0) {
          state = 'processing';
        } else if (leadsNotStarted === totalLeads) {
          state = 'not_started';
        } else {
          state = 'pending';
        }

        const getTooltipText = () => {
          const parts = [];

          // Add user info for the first lead if available
          const firstLead = socialLeads[0];
          if (firstLead?.presentAllocation?.user) {
            const user = firstLead.presentAllocation.user;
            parts.push(`Processor: ${user.firstName} ${user.lastName}`);
          }

          // Add status counts
          if (unallocated > 0) parts.push(`${unallocated} unallocated`);
          if (leadsSubmitted > 0) parts.push(`${leadsSubmitted} submitted`);
          if (leadsInProgress > 0) parts.push(`${leadsInProgress} in progress`);
          if (leadsNotStarted > 0) parts.push(`${leadsNotStarted} not started`);

          return parts.join('\n');
        };

        return (
          <Tooltip
            title={getTooltipText()}
            componentsProps={{
              tooltip: {
                sx: {
                  whiteSpace: 'pre-line',
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  border: '1px solid',
                  borderColor: 'divider',
                  p: 1,
                  '& .MuiTooltip-arrow': {
                    color: 'divider',
                  },
                },
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {state === 'done' && (
                <CheckCircleIcon sx={{ color: 'success.main', fontSize: '1.1rem' }} />
              )}
              {state === 'processing' && (
                <EmailIcon sx={{ color: 'warning.main', fontSize: '1.1rem' }} />
              )}
              {state === 'not_started' && (
                <EmailIcon sx={{ color: 'info.main', fontSize: '1.1rem' }} />
              )}
              {state === 'pending' && (
                <EmailIcon sx={{ color: 'text.disabled', fontSize: '1.1rem' }} />
              )}
              {state === 'unallocated' && (
                <EmailIcon sx={{ color: 'text.disabled', fontSize: '1.1rem', opacity: 0.5 }} />
              )}
            </Box>
          </Tooltip>
        );
      }
    }
  ], []);

  const toolbarStyles = {
    select: {
      height: '34px',
      backgroundColor: '#8797A8',
      color: '#FFFFFF',
      '.MuiSelect-select': {
        padding: '4px 8px',
        fontSize: '0.85rem',
        color: '#FFFFFF',
      },
      '&.Mui-disabled': {
        backgroundColor: '#8797A8',
        opacity: 0.7,
      },
      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
      '&:hover': {
        backgroundColor: '#8797A8',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        opacity: 0.9,
      },
    },
    button: {
      minHeight: '36px',
      backgroundColor: '#8797A8',
      color: '#FFFFFF',
      padding: '4px 8px',
      fontSize: '0.85rem',
      textTransform: 'none',
      '&.MuiButton-outlined': {
        border: '1px solid #FFFFFF',
        '&:hover': {
          border: '1px solid #FFFFFF',
        },
      },
      '&:hover': {
        backgroundColor: '#8797A8',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        opacity: 0.9,
      },
      '&.Mui-disabled': {
        backgroundColor: '#8797A8',
        opacity: 0.7,
        color: '#FFFFFF',
      },
    },
    switchLabel: {
      margin: 0,
      '& .MuiFormControlLabel-label': {
        fontSize: '0.9rem',
        marginLeft: '2px',
      },
    }
  };

  const columnVisibility = {
    code: false,
    agents: false,
    active: false,

    leads: false,
    socials: false,
    remarks: false,
    actions: false,
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbar.open} autoHideDuration={snackbar.autoHideDuration} onClose={() => setSnackbar({ open: false, message: '', severity: '' })}>
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
        <Stack direction='row' spacing={2}>
          <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
            Shortlist
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          {(isAdminUser || isEDManagerUser || isESManager ) && activeView !== "esAnalystView" && (
            <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => setOpenDialog(true)}
                  sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
                >
                  Add Domains
                </Button>
            </>
          )}
          {(isAdminUser || (isEDManagerUser && isESManagerUser)) && activeView !== "esAnalystView" && (
            <>
              <Button
                variant="outlined"
                onClick={() => {
                  const newMode = mode === 'ed' ? 'es' : 'ed';
                  setMode(newMode);
                  setDomains([]);
                  fetchDomains(newMode);
                }}
                sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
              >
                Switch To {mode === 'ed' ? 'ES' : 'ED'}
              </Button>
            </>
          )}
          <UploadCSVDialogShortlistedDomain
            open={openDialog}
            handleClose={handleCloseDialog}
            setState={setNewDomains}
          />
        </Stack>

        <Snackbar
          open={uploadSuccess}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setUploadSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Uploaded!
          </Alert>
        </Snackbar>

        <Snackbar
          open={uploadFail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setUploadFail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Upload Failed!
          </Alert>
        </Snackbar>

        <Snackbar
          open={allocateSuccess}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setAllocateSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Allocated Successfully!
          </Alert>
        </Snackbar>

        <Snackbar
          open={allocateFail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setAllocateFail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Allocation Failed!
          </Alert>
        </Snackbar>

        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleResearchTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {['All', 'PendingResearch', 'DoneResearch'].map((state) => (
                <Tab
                  key={state}
                  label={renderTabLabel(state)}
                  value={state}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel value={tabValue} sx={{ padding: 1 }}>
            <Box>
              <AqTable
                columns={columns}
                data={domains}
                rowCount={totalElements}
                isLoading={loading}
                additionalColumns={columns}
                columnVisibility={columnVisibility}
                onDataChange={handleDataChange}

                // Pass states
                pagination={pagination}
                sorting={sorting}
                columnFilters={columnFilters}
                globalSearch={globalSearch}
                rowSelection={rowSelection}

                // Pass state setters
                setPagination={setPagination}
                setSorting={setSorting}
                setColumnFilters={setColumnFilters}
                setGlobalSearch={setGlobalSearch}
                setRowSelection={setRowSelection}

                renderTopToolbarCustomActions={() => (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Button
                      variant="outlined"
                      size='small'
                      disabled={Object.keys(rowSelection).length === 0}
                      sx={toolbarStyles.button}
                      onClick={handleMoveToReco}
                    >
                      Move to Reco
                    </Button>
                    {tabValue === 'DoneResearch' && (
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={Object.keys(rowSelection).length === 0}
                        onClick={() => setOpenEmailAllocateDialog(true)}
                        sx={toolbarStyles.button}
                      >
                        Allocate to Email Hub
                      </Button>
                    )}
                    <Select
                      size="small"
                      value={domainTypeFilter}
                      onChange={(e) => { setDomainTypeFilter(e.target.value) }}
                      displayEmpty
                      sx={toolbarStyles.select}
                    >
                      <MenuItem value="All" sx={{ fontSize: '0.75rem' }}>D Type</MenuItem>
                      <MenuItem value="Specific" sx={{ fontSize: '0.75rem' }}>Specific</MenuItem>
                      <MenuItem value="Generic" sx={{ fontSize: '0.75rem' }}>Generic</MenuItem>
                      <MenuItem value="Venture" sx={{ fontSize: '0.75rem' }}>Venture</MenuItem>
                    </Select>
                    <Select
                      size="small"
                      value={emailStateFilter}
                      onChange={(e) => { setEmailStateFilter(e.target.value) }}
                      displayEmpty
                      sx={toolbarStyles.select}
                    >
                      <MenuItem value="All" sx={{ fontSize: '0.75rem' }}>E Status</MenuItem>
                      <MenuItem value="unallocated" sx={{ fontSize: '0.75rem', color: 'text.disabled' }}>Unallocated</MenuItem>
                      <MenuItem value="not_started" sx={{ fontSize: '0.75rem', color: 'info.main' }}>Not Started</MenuItem>
                      <MenuItem value="processing" sx={{ fontSize: '0.75rem', color: 'warning.main' }}>Processing</MenuItem>
                      <MenuItem value="done" sx={{ fontSize: '0.75rem', color: 'success.main' }}>Done</MenuItem>
                    </Select>
                    {tabValue != 'DoneResearch' && (
                      <Select
                        size="small"
                        value={selectedResearchAction || "default"}
                        disabled={Object.keys(rowSelection).length === 0}
                        onChange={handleResearchActionChange}
                        sx={toolbarStyles.select}
                      >
                        <MenuItem value="default" disabled>Research</MenuItem>
                        <MenuItem value="allocate">Move to Lead Hub</MenuItem>
                        <MenuItem value="claim">Move to Claim Hub</MenuItem>
                      </Select>
                    )}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showDomainWithLeadsOnly}
                          onChange={(event) => setShowDomainWithLeadsOnly(event.target.checked)}
                          name="withLeadsOnly"
                          color="primary"
                          size="small"
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '0.75rem' }}>
                          Leads Only
                        </Typography>
                      }
                      sx={toolbarStyles.switchLabel}
                    />
                  </Stack>
                )}
              />
            </Box>
          </TabPanel>
        </TabContext>
        <Dialog fullWidth open={openAllocateDialog} onClose={() => { setOpenAllocateDialog(false); setSelectedResearchAction("") }}>
          <DialogTitle>Allocate to Social User</DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
              <InputLabel id="user-select-label">Select User</InputLabel>
              <Select
                labelId="user-select-label"
                label="Select User"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {children.map((child) => (
                  <MenuItem key={child.email} value={child.email}>
                    {child.firstName + " " + child.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setOpenAllocateDialog(false); setSelectedResearchAction("") }}>Cancel</Button>
            <Button onClick={handleAllocateToUser} variant="contained" disabled={allocating}>
              {allocating ? "Allocating..." : "Allocate"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth open={openEmailAllocateDialog} onClose={() => setOpenEmailAllocateDialog(false)}>
          <DialogTitle>Allocate to Email User</DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
              <InputLabel id="email-user-select-label">Select User</InputLabel>
              <Select
                labelId="email-user-select-label"
                label="Select User"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {emailAgent.map((child) => (
                  <MenuItem key={child.email} value={child.email}>
                    {child.firstName + " " + child.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEmailAllocateDialog(false)}>Cancel</Button>
            <Button onClick={handleAllocateToEmailUser} variant="contained" disabled={allocating}>
              {allocating ? "Allocating..." : "Allocate"}
            </Button>
          </DialogActions>
        </Dialog>
        <QaAqLeadsDialog
          open={editDialogOpen}
          onClose={() => {
            setEditDialogOpen(false);
            setSelectedRow(null);
          }}
          data={selectedRow}
          onSuccess={() => {
            fetchDomains(mode);
          }}
        />
        <UpdateShortlistedDomain
          open={updateDialogOpen}
          handleClose={() => {
            setUpdateDialogOpen(false);
          }}
          onSuccess={() => {
            fetchDomains(mode);
          }}
        />
      </Stack>
    </ThemeProvider>
  );
}
