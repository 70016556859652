import * as React from 'react';
import { Avatar, Box, Button, CssBaseline, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, ThemeProvider, Toolbar, Tooltip, Typography, styled } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { theme } from './Theme';
import { Archive, ArchiveOutlined, AssignmentLateOutlined, AssignmentOutlined, DashboardOutlined, DomainOutlined, GroupOutlined, Logout, NotificationsOutlined, PlaylistAddCheckOutlined, WorkOutline, ShoppingCartOutlined, Email } from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router';
import userimg from "./images/user1.jpg";

import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { canAllocateAll, canAllocateLtd, canAllocateOwned, isAcquisitionCenterVisible, isEmailCenterVisible, isAdmin, isManager, isltdExpiredProcessor, isProcessor, isProspecterVisible, isQA, isSocialAgent, isESManager, isESAnalyst, canPlaceOrdersAq } from './msalService';
import { getToken } from './axiosInstance';
import { useUser } from './UserProvider';
import { syncUser } from './api';
import nk from "./images/nk.png";

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 0, 0, 0.5),
  minHeight: 64,
  backgroundColor: '#738e97',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,

  }),
  ...(open && {
    marginLeft: 0,//drawerWidth,
    width: '100%',// `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Home() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [olevel1, setOlevel1] = React.useState(false);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { user, updateUser, olevel } = useUser();
  const [prospectorOpen, setProspectorOpen] = React.useState(false);
  const [researchCenterOpen, setResearchCenterOpen] = React.useState(false);
  const [acquisitionCenterOpen, setAcquisitionCenterOpen] =
    React.useState(false);
  const [emailCenterOpen, setEmailCenterOpen] = React.useState(false);


  const [nestedOpen1, setNestedOpen1] = React.useState(false);
  const toggleNestedMenu1 = () => {
    if (!open)
      setOpen(true);
    setNestedOpen1(!nestedOpen1);
  };

  const [nestedOpen2, setNestedOpen2] = React.useState(false);
  const toggleNestedMenu2 = () => {
    if (!open)
      setOpen(true);
    setNestedOpen2(!nestedOpen2);
  };

  const [nestedOpen3, setNestedOpen3] = React.useState(false);
  const toggleNestedMenu3 = () => {
    if (!open)
      setOpen(true);
    setNestedOpen3(!nestedOpen3);
  };

  const [nestedOpen4, setNestedOpen4] = React.useState(false);
  const toggleNestedMenu4 = () => {
    if (!open)
      setOpen(true);
    setNestedOpen4(!nestedOpen4);
  };

  const [nestedOpen5, setNestedOpen5] = React.useState(false);
  const toggleNestedMenu5 = () => {
    if (!open)
      setOpen(true);
    setNestedOpen5(!nestedOpen5);
  };

  const [nestedOpen6, setNestedOpen6] = React.useState(false);
  const toggleNestedMenu6 = () => {
    if (!open) setOpen(true);
    setNestedOpen6(!nestedOpen6);
  };


  const [nestedOpen7, setNestedOpen7] = React.useState(false);
  const toggleNestedMenu7 = () => {
    if (!open) setOpen(true);
    setNestedOpen7(!nestedOpen7);
  };

  const [nestedOpen8, setNestedOpen8] = React.useState(false);
  const toggleNestedMenu8 = () => {
    if (!open)
      setOpen(true);
    setNestedOpen8(!nestedOpen8);
  };

  const [nestedOpen9, setNestedOpen9] = React.useState(false);
  const toggleNestedMenu9 = () => {
    if (!open)
      setOpen(true);
    setNestedOpen9(!nestedOpen9);
  };

  const [nestedOpen10, setNestedOpen10] = React.useState(false);
  const toggleNestedMenu10 = () => {
    if (!open)
      setOpen(true);
    setNestedOpen10(!nestedOpen10);
  };

  const toggleProspector = () => {
    if (!open) setOpen(true);
    setProspectorOpen(!prospectorOpen);
  };

  const toggleAcquisitionCenter = () => {
    if (!open) setOpen(true);
    setAcquisitionCenterOpen(!acquisitionCenterOpen);
  };

  const toggleEmailCenter = () => {
    if (!open) setOpen(true);
    setEmailCenterOpen(!emailCenterOpen);
  };

  const canAccessAnalysisHub = isAdmin() || isESManager() || isESAnalyst();

  React.useEffect(() => {
    syncUser().then((res) => {
      console.log(res.data); updateUser(res.data); if (res.data.users.length > 0)
        setOlevel1(false);
      else setOlevel1(true);
    }).catch((err) => { console.log(err); });
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: '#fcfcfa', height: '100%', minHeight: '100vh' }}>
        <Stack direction="column" >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" elevation={0} open={open} sx={{
              backgroundColor: '#738e97',//'white' 
              display: 'flex', width: '100%', justifyContent: 'center'

            }}
            >
              <Toolbar variant='dense' disableGutters
                sx={{ paddingLeft: '16px', paddingRight: '30px' }}
              >
                <Stack direction='row' alignItems='center' width='100%' justifyContent='center' >
                  <img
                    src={nk}
                    alt="placeholder"
                    style={{
                      borderRadius: '0',
                      transform: 'rotate(0deg)',
                      opacity: '1',
                      height: '24px',
                      padding: 0, margin: 0

                    }}
                  />
                  <Typography align='center' fontSize={18} fontWeight={500} fontFamily='Overpass' color='white' marginLeft={1}>NAMEKART</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  <Tooltip title='Will be available soon :)'>
                    <Button
                      variant="text"
                      startIcon={<NotificationsOutlined sx={{ width: 24, height: 24 }} />}
                      sx={{ height: 24, color: 'white', textTransform: 'none' }}
                    >
                      Notifications
                    </Button>
                  </Tooltip>
                  <Button
                    variant="text"
                    endIcon={<Avatar alt="User" src={userimg} sx={{ width: 24, height: 24, marginLeft: 1 }} />}
                    sx={{ color: 'white', textTransform: 'none', height: 24 }}
                  >
                    Howdy, {user.firstName}
                  </Button>
                  {isAuthenticated && <Tooltip title='Log Out'><IconButton sx={{
                    color: 'white'
                    , marginLeft: 1
                  }} onClick={() => {
                    if (accounts.length > 0) {
                      const account = accounts[0];
                      instance.logoutRedirect({ account });
                    }
                  }}><Logout sx={{ width: 24, height: 24 }} /></IconButton></Tooltip>}
                </Stack>
              </Toolbar>
            </AppBar>
          </Box>
          <Drawer PaperProps={{
            sx: {
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '6px',
              }, background: '#738e97',
              color: '#cac4d0', marginTop: '32px'
            }
          }} variant="permanent" open={open}>
            <List sx={{
              width: '100%', color: 'white'
              , fontSize: 14, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 20, letterSpacing: 0.10, wordWrap: 'break-word', paddingBottom: 4,
              '& .MuiListItemText-primary': {
                fontSize: 14,

              },
              '& .MuiListItemText-root': {
                marginLeft: 1.5

              },
            }}  >
              <ListItem button onClick={() => navigate("/")}>
                <ListItemIcon sx={{ color: '#cac4d0', padding: 0, margin: 0 }} >
                  <DashboardOutlined />
                </ListItemIcon>
                <ListItemText primary="Dashboard" primaryTypographyProps={{ fontSize: 14 }}
                />
              </ListItem>


              <ListItem onClick={() => { navigate("/pqcentre") }} button>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <AssignmentLateOutlined />
                </ListItemIcon>
                <ListItemText primary="PQ Center" />
              </ListItem>
              {(isAdmin() || isManager()) &&
                <ListItem button onClick={toggleNestedMenu1}>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <DomainOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Domain Studio" />
                </ListItem>}
              {nestedOpen1 && <List sx={{ paddingLeft: 3 }}
              >
                <ListItem button onClick={() => { navigate("/domainstudio/owned") }}>
                  <ListItemText primary="Owned Domains" />
                </ListItem>
                <ListItem button onClick={() => { navigate("/domainstudio/ltd") }}>
                  <ListItemText primary="Ltd Domains" />
                </ListItem>
                <ListItem button onClick={() => { navigate("/domainstudio/pd") }}>
                  <ListItemText primary="PD Domains" />
                </ListItem>
                <ListItem button onClick={() => { navigate("/domainstudio/ald") }}>
                  <ListItemText primary="ALD Domains" />
                </ListItem>
              </List>}
              {(isAdmin() || isManager() || isltdExpiredProcessor()) && <ListItem button onClick={toggleNestedMenu9}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <DomainOutlined />
                </ListItemIcon>
                <ListItemText primary="LTD Studio" />
              </ListItem>}
              {nestedOpen9 && <List sx={{ paddingLeft: 3 }}>
                {(isAdmin() || isManager()) && (
                  <>
                    <ListItem button onClick={() => { navigate("/ltdstudio/active") }}>
                      <ListItemText primary="Active" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate("/ltdstudio/closed") }}>
                      <ListItemText primary="Closed" />
                    </ListItem>
                  </>
                )}
                {(isAdmin() || isltdExpiredProcessor()) && (
                  <ListItem button onClick={() => { navigate("/ltdstudio/expired") }}>
                    <ListItemText primary="Expired" />
                  </ListItem>
                )}
                {(isAdmin() || isManager()) && (
                  <ListItem button onClick={() => { navigate("/ltdstudio/archive") }}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                )}
              </List>}
              {(isAdmin() || isManager()) && <ListItem button onClick={toggleNestedMenu8}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <DomainOutlined />
                </ListItemIcon>
                <ListItemText primary="Portfolio" />
              </ListItem>}
              {nestedOpen8 && <List sx={{ paddingLeft: 3 }}
              >
                <ListItem button onClick={() => { navigate("/portfolio/new") }}>
                  <ListItemText primary="New" />
                </ListItem>
                <ListItem button onClick={() => { navigate("/portfolio/active") }}>
                  <ListItemText primary="Active" />
                </ListItem>
                <ListItem button onClick={() => { navigate("/portfolio/expiring") }}>
                  <ListItemText primary="Expiring" />
                </ListItem>
                <ListItem button onClick={() => { navigate("/portfolio/expired") }}>
                  <ListItemText primary="Expired" />
                </ListItem>
                <ListItem button onClick={() => { navigate("/portfolio/deleted") }}>
                  <ListItemText primary="Deleted" />
                </ListItem>
              </List>}


              <ListItem button onClick={toggleNestedMenu6}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <ArchiveOutlined />
                </ListItemIcon>
                <ListItemText primary="Allocation Center" />
              </ListItem>
              {nestedOpen6 && <List sx={{ paddingLeft: 3 }}
              >
                {(canAllocateAll() || isAdmin()) && <ListItem button onClick={() => navigate("/bulkallocator")}>
                  <ListItemText primary="Bulk Allocator" />
                </ListItem>}
                {(isProcessor() || isAdmin()) && <ListItem button onClick={() => navigate("/mydomains")}>
                  <ListItemText primary="My Domains" />
                </ListItem>}
                {((isProcessor() || isAdmin()) && !olevel1) && <><ListItem button onClick={() => navigate("/allocatedbyme")}>
                  <ListItemText primary="Allocated by me" />
                </ListItem>
                  <ListItem button onClick={() => navigate("/nonallocated")}>
                    <ListItemText primary="Non-Allocated" />
                  </ListItem></>}
              </List>}



              <ListItem button onClick={toggleNestedMenu10}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <ArchiveOutlined />
                </ListItemIcon>
                <ListItemText primary="Lead Center" />
              </ListItem>
              {nestedOpen10 && <List sx={{ paddingLeft: 3 }}
              >
                {(isProcessor() || isAdmin()) && <>
                  <ListItem button onClick={() => navigate("/myleads")}>
                    <ListItemText primary="My Leads" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/allocatedbymeleads")}>
                    <ListItemText primary="Allocated by me" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/nonallocatedleads")}>
                    <ListItemText primary="Non Allocated" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/emailworkstation")}>
                    <ListItemText primary="E-Work Station" />
                  </ListItem>
                </>}
              </List>}



              {isAdmin() && <><ListItem button onClick={toggleNestedMenu2} disabled>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <AssignmentOutlined />
                </ListItemIcon>
                <ListItemText primary="Acquisitions" />
              </ListItem>
              </>
              }


              <ListItem button onClick={toggleNestedMenu3}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <PlaylistAddCheckOutlined />
                </ListItemIcon>
                <ListItemText primary="Review" />
              </ListItem>
              {nestedOpen3 && <List sx={{ paddingLeft: 3 }}
              >
                {(isQA() || isAdmin()) && <><ListItem button onClick={() => { navigate("/analysework/0") }}>
                  <ListItemText primary="Analyse Work" />
                </ListItem>
                  <ListItem button onClick={() => { navigate("/analysedreports") }}>
                    <ListItemText primary="Analysed Reports" />
                  </ListItem>
                </>}
                {(isAdmin() || isProcessor()) && <><ListItem button onClick={() => navigate("/qareports")}>
                  <ListItemText primary="QA Reports" />
                </ListItem>

                  <ListItem button disabled>
                    <ListItemText primary="Approvals" />
                  </ListItem>
                </>}
                {(isAdmin() || isProcessor() || isQA()) && <><ListItem button onClick={() => navigate("/misreports")}>
                  <ListItemText primary="MIS Reports" />
                </ListItem></>}
              </List>}




              <ListItem button onClick={toggleNestedMenu4}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <ArchiveOutlined />
                </ListItemIcon>
                <ListItemText primary="Processing Center" />
              </ListItem>
              {nestedOpen4 && <List sx={{ paddingLeft: 3 }}
              >
                <ListItem button onClick={() => { navigate("/domainlive") }}>
                  <ListItemText primary="Processing Live" />
                </ListItem>
                <ListItem button onClick={() => { navigate("/workarchives") }}>
                  <ListItemText primary="Archive" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Special Tasks" />
                </ListItem>
              </List>}




              {(isProcessor() || isAdmin()) &&
                <>
                  <ListItem button onClick={() => navigate('/workinput/0')}>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <WorkOutline />
                    </ListItemIcon>
                    <ListItemText primary="Work Station" />
                  </ListItem>
                </>
              }



              {isAdmin() && <ListItem button onClick={() => navigate('/workforce')}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <GroupOutlined />
                </ListItemIcon>
                <ListItemText primary="WorkForce" />
              </ListItem>}


              {isAdmin() && <>
                <ListItem button onClick={toggleNestedMenu7}>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <ArchiveOutlined />
                  </ListItemIcon>
                  <ListItemText primary="User Location" />
                </ListItem></>}
              {nestedOpen7 && <List sx={{ paddingLeft: 3 }}
              >
                <ListItem button onClick={() => { navigate("/userlocation") }}>
                  <ListItemText primary="Location Logs" />
                </ListItem>
                <ListItem button onClick={() => { navigate("userlastlocation") }}>
                  <ListItemText primary="Last Location" />
                </ListItem>
              </List>}



              {isSocialAgent() && <>
                <ListItem button onClick={toggleNestedMenu5}>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <ArchiveOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Research Hub" />
                </ListItem></>}
              {nestedOpen5 &&
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => { navigate("/socials/bank") }}>
                    <ListItemText primary="Claim Hub" />
                  </ListItem>
                  <ListItem button onClick={() => { navigate("/socials/mydomains") }}>
                    <ListItemText primary="My Claims" />
                  </ListItem>
                  {<ListItem button onClick={() => { navigate("/socials/workstation") }
                  }>
                    <ListItemText primary="Leads Hub" />
                  </ListItem>}
                  {<><ListItem button onClick={() => navigate("/socials/collected")}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                  </>}
                </List>}




              {isProspecterVisible() && <ListItem button onClick={toggleProspector}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <WorkOutline />
                </ListItemIcon>
                <ListItemText primary="Prospector" />
              </ListItem>}
              {prospectorOpen && (
                <List sx={{ paddingLeft: 2 }}>
                  {isAdmin() && (
                    <ListItem button onClick={() => navigate("/prospector/masterdata")}>
                      <ListItemText primary="Master Data" />
                    </ListItem>
                  )}
                  {canAccessAnalysisHub && (
                    <ListItem button onClick={() => navigate("/prospector/analysishub")}>
                      <ListItemText primary="Analysis Hub" />
                    </ListItem>
                  )}
                  <ListItem button onClick={() => navigate("/prospector/shortlisteddomains")}>
                    <ListItemText primary="Shortlist" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/prospector/archive")}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                </List>
              )}




              {isAcquisitionCenterVisible() && <ListItem button onClick={toggleAcquisitionCenter}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <ShoppingCartOutlined />
                </ListItemIcon>
                <ListItemText primary="Acquisition Center" />
              </ListItem>}
              {acquisitionCenterOpen && (
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => navigate("/acquisitioncenter/recohub")}>
                    <ListItemText primary="Reco Hub" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/acquisitioncenter/decisionhub")}>
                    <ListItemText primary="Decision Hub" />
                  </ListItem>
                  <ListItem button disabled={!canPlaceOrdersAq()} onClick={() => navigate("/acquisitioncenter/ordershub")}>
                    <ListItemText primary="Orders Hub" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/acquisitioncenter/active")}>
                    <ListItemText primary="Active" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/acquisitioncenter/archive")}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                </List>
              )}



              {isEmailCenterVisible() && <ListItem button onClick={toggleEmailCenter}>
                <ListItemIcon sx={{ color: '#cac4d0' }}>
                  <Email />
                </ListItemIcon>
                <ListItemText primary="Email Center" />
              </ListItem>}
              {emailCenterOpen && (
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => navigate("/emailcenter/emailhub")}>
                    <ListItemText primary="Email Hub" />
                  </ListItem>
                </List>
              )}


            </List>
          </Drawer>
        </Stack>
        <Box height='100%' sx={{ marginLeft: `${drawerWidth}px`, paddingLeft: '30px', paddingRight: '38px', paddingTop: '62px', paddingBottom: '80px' }}>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  )
}  