import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import {
    Stack,
    Button,
    FormControlLabel,
    Switch,
    Box,
    Typography,
    Tooltip,
    Link,
    Snackbar,
    Alert,
    TextField,
    InputAdornment,
    IconButton,
    Badge
} from '@mui/material';
import MultiSortDialog from '../Common/MultiSortDialog';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import UpdateShortlistedDomain from '../Common/UpdateShortlistedDomain';
import { isAdmin, isESManager, isEDManager } from '../msalService';
import { fetchStats } from '../api';

// Icons
import { Sort as SortIcon } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import CopyrightIcon from '@mui/icons-material/Copyright';
import SearchIcon from '@mui/icons-material/Search';

// Get current year for copyright
const currentYear = new Date().getFullYear();

const getCommonColumns = () => [
    {
        accessorKey: 'edate',
        header: 'edate',
        size: 60,
        minSize: 60,
        maxSize: 60,
        enableSorting: true,
        accessorFn: (row) => {
            const date = row.acquShortlistedDomain.edate;
            return date ? new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-') : '-';
        },
    },
    {
        accessorKey: 'createdAt',
        header: 'sdate',
        size: 60,
        minSize: 60,
        maxSize: 60,
        accessorFn: (row) => {
            const date = row.acquShortlistedDomain.createdAt;
            return date ? new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-') : '-';
        },
    },
    {
        accessorKey: 'list',
        header: 'list',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.list;
            return value !== null && value !== undefined ? value : '-';
        }
    },
    {
        accessorKey: 'domain',
        header: 'domain',
        size: 120,
        minSize: 120,
        maxSize: 150,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.domain;
            return value !== null && value !== undefined ? value : '-';
        },
        Cell: ({ cell }) => (
            <Tooltip title={cell.getValue()} placement="bottom">
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: '0.8rem',
                        whiteSpace: 'normal', // Changed from nowrap to normal
                        wordWrap: 'break-word', // Added to allow breaking long words
                        overflow: 'visible', // Changed from hidden to visible
                        display: 'block'
                    }}
                >
                    {cell.getValue()}
                </Typography>
            </Tooltip>
        )
    },
    {
        accessorKey: 'p',
        header: 'p',
        size: 20,
        minSize: 20,
        maxSize: 30,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.p;
            return value !== null && value !== undefined ? value : '-';
        }
    },
    {
        accessorKey: 'len',
        header: 'len',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.len;
            return value !== null && value !== undefined ? value : '-';
        }
    },
    {
        accessorKey: 'host',
        header: 'host',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.host;
            return value !== null && value !== undefined ? value : '-';
        }
    },
    {
        accessorKey: 'tld',
        header: 'tld',
        size: 30,
        accessorFn: (row) => row.acquShortlistedDomain.tld || '-'
    },
    {
        accessorKey: 'wby',
        header: 'wby',
        size: 50,
        accessorFn: (row) => {
            const wby = row.acquShortlistedDomain.wby;
            if (wby && wby.length >= 4) {
                const year = parseInt(wby.substring(0, 4), 10);
                const currentYear = new Date().getFullYear();
                return currentYear - year;
            }
            return '-';
        }
    },
    {
        accessorKey: 'aby',
        header: 'aby',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const aby = row.acquShortlistedDomain.aby;
            if (aby && aby.length >= 4) {
                const year = parseInt(aby.substring(0, 4), 10);
                const currentYear = new Date().getFullYear();
                return currentYear - year;
            }
            return '-';
        }
    },
    {
        accessorKey: 'reg',
        header: 'reg',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.reg;
            return value !== null && value !== undefined ? value : '-';
        }
    },
    {
        accessorKey: 'regx',
        header: 'regx',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.regx;
            return value !== null && value !== undefined ? value : '-';
        }
    },
    {
        accessorKey: 'tld_count',
        header: 'tldc',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => row.acquShortlistedDomain.tld_count
    },
    {
        accessorKey: 'tld_count_dev',
        header: 'tldd',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => row.acquShortlistedDomain.tld_count_dev
    },
    {
        accessorKey: 'sg',
        header: 'sg',
        size: 40,
        minSize: 40,
        maxSize: 50,
        enableSorting: true,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.sg;
            return value !== null && value !== undefined ? value : '-';
        }
    },
    {
        accessorKey: 'cpc',
        header: 'cpc',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const cpc = row.acquShortlistedDomain.cpc;
            return cpc !== undefined && cpc !== null ? cpc.toFixed(1) : '-';
        }
    },
    {
        accessorKey: 'bids',
        header: 'bids',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.bids;
            return value !== null && value !== undefined ? value : '-';
        }
    },
    {
        accessorKey: 'price',
        header: 'price',
        size: 50,
        minSize: 50,
        maxSize: 60,
        accessorFn: (row) => {
            const price = row.acquShortlistedDomain.price;
            return price !== undefined && price !== null ? Math.round(price) : '-';
        }
    },
    {
        accessorKey: 'est',
        header: 'est',
        size: 45,
        minSize: 45,
        maxSize: 50,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.est;
            return value !== null && value !== undefined ? Math.round(value / 100) * 100 : '-';
        }
    },
    {
        accessorKey: 'gdv',
        header: 'gdv',
        size: 45,
        minSize: 45,
        maxSize: 50,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.gdv;
            return value !== null && value !== undefined ? Math.round(value / 100) * 100 : '-';
        }
    },
    {
        accessorKey: 'esw',
        header: 'esw',
        size: 45,
        minSize: 45,
        maxSize: 50,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.esw;
            return value !== null && value !== undefined ? Math.round(value / 100) * 100 : '-';
        }
    },
    {
        accessorKey: 'gdw',
        header: 'gdw',
        size: 45,
        minSize: 45,
        maxSize: 50,
        accessorFn: (row) => {
            const value = row.acquShortlistedDomain.gdw;
            return value !== null && value !== undefined ? Math.round(value / 100) * 100 : '-';
        }
    },
    {
        accessorKey: 'spr500',
        header: 's500',
        size: 35,
        minSize: 35,
        maxSize: 40,
        accessorFn: (row) => {
            const spr500 = row.acquShortlistedDomain.spr500;
            if (spr500 !== null && spr500 !== undefined) {
                return spr500;
            }
            return null;
        },
        Cell: ({ cell }) => {
            const value = cell.getValue();
            return value !== null ? `${Math.round(value * 100)}%` : '-';
        }
    },
    {
        accessorKey: 'sprGdv',
        header: 'sgdv',
        size: 35,
        minSize: 35,
        maxSize: 40,
        accessorFn: (row) => {
            const sprGdv = row.acquShortlistedDomain.sprGdv;
            return sprGdv !== null && sprGdv !== undefined ? `${Math.round(sprGdv * 100)}%` : '-';
        }
    },
    {
        accessorKey: 'leads',
        header: 'leads',
        size: 130,
        minSize: 130,
        maxSize: 150,
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: '#FFC0CB',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '0.7rem',
                padding: '1px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontStyle: 'italic',
                paddingLeft: '5px',
                '& .MuiTableSortLabel-icon': {
                    display: 'none',
                },
                '& .Mui-active .MuiTableSortLabel-icon': {
                    display: 'none',
                },
            },
        },
        Cell: ({ row }) => {
            const filteredLeads = row.original.socialLeads.filter(lead => !lead.isIrrelevant);
            return (
                <Box>
                    {filteredLeads.map((lead, index) => {
                        let domain;
                        if (lead.domain) {
                            try {
                                domain = new URL(lead.domain).hostname;
                                domain = domain.replace('www.', '');
                            } catch (error) {
                                domain = lead.domain;
                            }
                        } else {
                            domain = '-';
                        }

                        let snapshotUrl = '';
                        if (lead.domainSnapShotUrl) {
                            try {
                                const parser = new DOMParser();
                                const xmlDoc = parser.parseFromString(lead.domainSnapShotUrl, "text/xml");
                                snapshotUrl = xmlDoc.getElementsByTagName("snapshot")[0]?.textContent || '';
                            } catch (error) {
                                console.error('Error parsing snapshot URL:', error);
                            }
                        }

                        const isActive = lead.leadStatus?.toLowerCase() === 'active';

                        return (
                            <Tooltip
                                key={index}
                                title={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 1,
                                        maxWidth: '300px'
                                    }}>
                                        {snapshotUrl && (
                                            <img
                                                src={snapshotUrl}
                                                alt="Domain Snapshot"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    maxHeight: '200px',
                                                    objectFit: 'contain',
                                                    filter: !isActive ? 'grayscale(100%)' : 'none'
                                                }}
                                            />
                                        )}
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                fontWeight: 'medium',
                                                wordBreak: 'break-all'
                                            }}
                                        >
                                            {lead.domain || '-'}
                                        </Typography>
                                    </Box>
                                }
                                placement="right"
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'common.white',
                                            color: 'common.black',
                                            border: '1px solid #ccc',
                                            '& .MuiTooltip-arrow': {
                                                color: 'common.white',
                                            },
                                            p: 1
                                        }
                                    }
                                }}
                            >
                                <Box sx={{
                                    width: '120px',
                                    minHeight: '20px',
                                    overflow: 'visible'
                                }}>
                                    <Link
                                        href={lead.domain.startsWith('http://') || lead.domain.startsWith('https://') ? lead.domain : `https://${lead.domain}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            display: 'block',
                                            color: isActive ? 'primary.main' : 'text.secondary',
                                            fontSize: '0.8rem',
                                            textDecoration: 'none',
                                            whiteSpace: 'normal',
                                            overflow: 'visible',
                                            wordBreak: 'break-word',
                                            wordWrap: 'break-word',
                                            lineHeight: '1.2',
                                            maxWidth: '100%',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        {domain}
                                    </Link>
                                </Box>
                            </Tooltip>
                        );
                    })}
                </Box>
            );
        }
    },
    {
        accessorKey: 'socials',
        header: 'socials',
        size: 130,
        minSize: 130,
        maxSize: 140,
        Cell: ({ row }) => {
            const socialLeads = row.original.socialLeads;

            const getUserId = (url) => {
                if (!url) return 'N/A';
                try {
                    const urlObj = new URL(url);
                    switch (true) {
                        case url.includes('linkedin.com'):
                            return urlObj.pathname.split('/')[2] || url;
                        case url.includes('twitter.com'):
                            return '@' + (urlObj.pathname.split('/')[1] || url);
                        case url.includes('facebook.com'):
                            return urlObj.pathname.split('/')[1] || url;
                        case url.includes('instagram.com'):
                            return '@' + (urlObj.pathname.split('/')[1] || url);
                        default:
                            return url;
                    }
                } catch {
                    return url || 'N/A';
                }
            };

            const formatCount = (count) => {
                if (count === null || count === undefined) return '    ';
                if (count === 0) return '0'.padStart(4, ' ');

                // For numbers less than 100, show exact value
                if (count < 100) {
                    return count.toString().padStart(4, ' ');
                }

                // For numbers >= 100, round to nearest hundred/thousand/million
                if (count >= 1000000) {
                    const roundedMillions = Math.round(count / 1000000);
                    return `${roundedMillions}M`;
                }
                if (count >= 1000) {
                    const roundedThousands = Math.round(count / 1000);
                    return `${roundedThousands}K`;
                }
                
                // For numbers between 100 and 999, round to nearest hundred
                return Math.round(count / 100) * 100;
            };

            const renderSocialIcons = (lead) => {
                // Get all social platforms from lead
                const availableSocials = lead?.socials || [];

                // Find social media entries (X/Twitter, Facebook, Instagram)
                const socialMediaEntries = availableSocials.filter(s => 
                    ['x', 'facebook', 'instagram'].includes(s.platform.toLowerCase())
                );

                // Find the entry with highest followers
                const socialWithHighestEngagement = socialMediaEntries.reduce((highest, current) => {
                    if (!highest || (current.followers || 0) > (highest.followers || 0)) {
                        return current;
                    }
                    return highest;
                }, null);

                // Create filtered socials array with LinkedIn and the highest engagement social
                const filteredSocials = [
                    availableSocials.find(s => s.platform.toLowerCase() === 'linkedin') || { platform: 'linkedin' },
                    socialWithHighestEngagement || { platform: 'x' } // Default to X if no social media found
                ];

                // Extract email information
                const emailInfo = lead?.email;
                const emailIcon = emailInfo ? emailInfo.designation ? emailInfo.designation.charAt(0).toUpperCase() : "O" : null;

                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Tooltip title={
                            emailInfo ? (
                                <Box>
                                    <Typography variant="caption" sx={{ display: 'block' }}>
                                        Email: {emailInfo.email}
                                    </Typography>
                                    <Typography variant="caption" sx={{ display: 'block' }}>
                                        Name: {emailInfo.name}
                                    </Typography>
                                    <Typography variant="caption" sx={{ display: 'block' }}>
                                        Designation: {emailInfo.designation}
                                    </Typography>
                                    <Typography variant="caption" sx={{ display: 'block' }}>
                                        LinkedIn: {emailInfo.linkedinUrl}
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography variant="caption" sx={{ display: 'block' }}>
                                    --
                                </Typography>
                            )
                        }>
                            <Box
                                // sx={{
                                //     pointerEvents: emailInfo?.linkedinUrl ? 'auto' : 'none',
                                //     opacity: emailInfo?.linkedinUrl ? 1 : 0.5,
                                // }}
                                sx={{
                                    cursor: emailInfo?.linkedinUrl ? 'pointer' : 'not-allowed',
                                    opacity: emailInfo?.linkedinUrl ? 1 : 0.5,
                                    '& a': {
                                        pointerEvents: emailInfo?.linkedinUrl ? 'auto' : 'none'
                                    }
                                }}
                            >
                                <Link
                                    href={emailInfo?.linkedinUrl ? (emailInfo.linkedinUrl.startsWith('http://') || emailInfo.linkedinUrl.startsWith('https://') ? emailInfo.linkedinUrl : `https://${emailInfo.linkedinUrl}`) : '#'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '13px',
                                        height: '13px',
                                        borderRadius: '50%',
                                        backgroundColor: emailInfo ? '#e0e0e0' : '#f0f0f0',
                                        border: '1px solid #b0b0b0',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            opacity: 0.8,
                                        },
                                        textDecoration: 'none'
                                    }}
                                >
                                    <Typography variant="caption" sx={{ fontSize: '0.6rem', fontWeight: 'bold', color: emailInfo ? 'inherit' : 'gray' }}>
                                        {emailIcon || '-'}
                                    </Typography>
                                </Link>
                            </Box>
                        </Tooltip>
                        {filteredSocials.map((social) => {
                            let Icon, count, platform = social.platform.toLowerCase();

                            switch (platform) {
                                case 'linkedin':
                                    Icon = LinkedInIcon;
                                    count = social.employees;
                                    break;
                                case 'x':
                                    Icon = XIcon;
                                    count = social.followers;
                                    break;
                                case 'facebook':
                                    Icon = FacebookIcon;
                                    count = social.followers;
                                    break;
                                case 'instagram':
                                    Icon = InstagramIcon;
                                    count = social.followers;
                                    break;
                                default:
                                    return null;
                            }

                            const isActive = social.status && social.status.toLowerCase() === 'active';
                            const iconColor = isActive ? 'primary.main' : 'action.disabled';
                            const tooltipContent = `${getUserId(social.url)}${social.userFullName ? ` - ${social.userFullName}` : ''}`;

                            return (
                                <Tooltip key={platform} title={tooltipContent}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {social.url ? (
                                            <Link href={social.url} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                                                <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                                                <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', color: 'primary.main', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                                                    {formatCount(count)}
                                                </Typography>
                                            </Link>
                                        ) : (
                                            <>
                                                <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                                                <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                                                    {formatCount(count)}
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                </Tooltip>
                            );
                        })}
                    </Box>
                );
            };

            if (!socialLeads || socialLeads.length === 0) {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Tooltip title="No copyright information available">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CopyrightIcon sx={{
                                    fontSize: '0.9rem',
                                    color: '#FFB6C1', // Light pink color for missing copyright
                                }} />
                            </Box>
                        </Tooltip>
                        {renderSocialIcons(null)}
                    </Box>
                );
            }

            return (
                <Box>
                    {socialLeads.map((lead, leadIndex) => (
                        <Box key={leadIndex} sx={{ mb: 0.5, '&:last-child': { mb: 0 } }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <Tooltip
                                    title={
                                        <Box>
                                            <Typography variant="caption" sx={{ fontSize: '0.7rem', display: 'block' }}>
                                                Copyright Year: {lead?.copyright || 'Not Available'}
                                            </Typography>
                                            {lead?.description && (
                                                <Typography variant="caption" sx={{ fontSize: '0.6rem', display: 'block', mt: 0.2 }}>
                                                    {lead.description}
                                                </Typography>
                                            )}
                                        </Box>
                                    }
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CopyrightIcon sx={{
                                            fontSize: '0.9rem',
                                            color: lead?.copyright
                                                ? (Number(lead.copyright) < currentYear ? 'action.disabled' : 'primary.main')
                                                : '#FFB6C1', // Light pink color for missing copyright
                                        }} />
                                    </Box>
                                </Tooltip>
                                {renderSocialIcons(lead)}
                            </Box>
                        </Box>
                    ))}
                </Box>
            );
        }
    },
    {
        accessorKey: 'active',
        header: 'live',
        size: 30,
        minSize: 30,
        maxSize: 40,
        accessorFn: (row) => {
            const isActive = row.socialLeads.some(lead =>
                lead.socials && lead.socials.some(social =>
                    social.status && social.status.toLowerCase() === 'active'
                )
            );
            return isActive ? '1' : '0';
        }
    },
    {
        accessorKey: 'agents',
        header: 'agent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        Cell: ({ row }) => {
            const agents = Object.entries(row.original.agents).flatMap(([key, value]) => {
                if (key === 'uploader' && Array.isArray(value)) {
                    return value;
                }
                return value ? [value] : [];
            }).filter(agent => agent !== null);

            return (
                <Stack
                    direction="row"
                    alignItems="center"                   
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '2px',
                        width: '100%',
                        '& > *': {
                            display: 'flex',
                            justifyContent: 'center',
                        },
                        padding: '2px',
                    }}
                >
                    {agents?.map((agent, index) => {
                        if (!agent) return null;

                        // Handle cases where name might be an object or string
                        let displayName = '';
                        if (typeof agent.name === 'string') {
                            displayName = agent.name;
                        } else if (agent.name?.firstName && agent.name?.lastName) {
                            displayName = `${agent.name.firstName} ${agent.name.lastName}`;
                        }

                        // Skip if we couldn't construct a valid display name
                        if (!displayName) return null;

                        // Get initials from display name
                        const initials = displayName
                            .split(' ')
                            .filter(part => part.length > 0)
                            .slice(0, 2)
                            .map(part => part[0].toUpperCase())
                            .join('');

                        // Different background colors for different roles
                        const getBackgroundColor = (role) => {
                            switch (role) {
                                case 'Uploader':
                                case 'Shortlister': return '#e3f2fd';
                                case 'Processor': return '#f1f8e9';
                                case 'Recommender': return '#fff3e0';
                                case 'Approver': return '#fce4ec';
                                default: return '#f5f5f5';
                            }
                        };

                        return (
                            <Tooltip
                                key={`${index}-${displayName}`}
                                title={
                                    <Box sx={{ p: 0.5 }}>
                                        <Typography variant="caption" sx={{ display: 'block', fontWeight: 'bold' }}>
                                            {displayName}
                                        </Typography>
                                        {agent.email && (
                                            <Typography variant="caption" sx={{ display: 'block', fontSize: '0.7rem' }}>
                                                {agent.email}
                                            </Typography>
                                        )}
                                        {agent.role && (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    display: 'block',
                                                    fontSize: '0.7rem',
                                                    fontStyle: 'italic',
                                                    mt: 0.5
                                                }}
                                            >
                                                Role: {agent.role}
                                            </Typography>
                                        )}
                                    </Box>
                                }
                                placement="bottom"
                                arrow
                            >
                                <Box
                                    sx={{
                                        backgroundColor: getBackgroundColor(agent.role),
                                        border: '1px solid rgba(0,0,0,0.1)',
                                        borderRadius: '50%',
                                        width: '18px',
                                        height: '18px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            opacity: 0.8,
                                            boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            fontSize: '0.6rem',
                                            fontWeight: 'medium',
                                            fontStyle: 'italic',
                                            lineHeight: 1,
                                            textAlign: 'center'
                                        }}
                                    >
                                        {initials}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        );
                    })}
                </Stack>
            );
        },
    }
];

const AqTable = ({
    columns: customColumns,
    data,
    rowCount,
    isLoading,
    onDataChange,
    renderTopToolbarCustomActions,
    //states
    pagination,
    sorting,
    columnFilters,
    globalSearch,
    rowSelection,

    //state setters
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalSearch,
    setRowSelection,
    columnVisibility = {},
    additionalColumns = []
}) => {
    // State management
    const [multiSortDialogOpen, setMultiSortDialogOpen] = useState(false);
    const [multiFilterDialogOpen, setMultiFilterDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [searchText, setSearchText] = useState('');

    // Get user roles
    const isAdminUser = isAdmin();
    const isESManagerUser = isESManager();
    const isEDManagerUser = isEDManager();

    // Merge common columns with custom columns
    const columns = useMemo(() => {
        const commonColumns = getCommonColumns();
        return [
            ...commonColumns,
            ...additionalColumns
        ];
    }, [additionalColumns]);

    // Common toolbar functions
    const handleCopyDomains = useCallback((selectedRows) => {
        const domains = selectedRows.map(row => row.original.acquShortlistedDomain.domain).join('\n');
        navigator.clipboard.writeText(domains)
            .then(() => {
                setSnackbar({ open: true, message: 'Domains copied to clipboard', severity: 'success' });
            })
            .catch(err => {
                setSnackbar({ open: true, message: 'Failed to copy domains', severity: 'error' });
            });
    }, []);

    const handleExportData = useCallback((selectedRows) => {
        try {
            const exportColumns = [
                'domain', 'list', 'p', 'len', 'host', 'tld', 'wby', 'aby',
                'reg', 'regx', 'sg', 'cpc', 'bids', 'price', 'est', 'gdv',
                'esw', 'gdw', 'reco', 'remarks'
            ];

            const header = exportColumns.join(',') + '\n';
            const csvContent = selectedRows.map(row => {
                return exportColumns.map(col => {
                    const value = row.original.acquShortlistedDomain[col];
                    if (value === null || value === undefined) return '';
                    if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
                    return value;
                }).join(',');
            }).join('\n');

            const csv = header + csvContent;
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `domains_export_${new Date().toISOString().split('T')[0]}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setSnackbar({
                open: true,
                message: 'Data exported successfully',
                severity: 'success'
            });
        } catch (error) {
            console.error('Export error:', error);
            setSnackbar({
                open: true,
                message: 'Failed to export data',
                severity: 'error'
            });
        }
    }, []);

    const handleFetchStats = useCallback(() => {
        const selectedDomainIndices = Object.keys(rowSelection).reduce((acc, index) => {
            if (rowSelection[index]) {
                const domainId = data[index].acquShortlistedDomain.id;
                acc[domainId] = true;
            }
            return acc;
        }, {});

        fetchStats(selectedDomainIndices)
            .then(() => {
                setSnackbar({ open: true, message: 'Stats fetched successfully', severity: 'success' });
                setRowSelection({});
                onDataChange(); // Refresh data
            })
            .catch(error => {
                setSnackbar({ open: true, message: 'Error fetching stats', severity: 'error' });
            });
    }, [rowSelection, data, onDataChange]);

    // Handle state changes with transformations
    const handleSortingChange = useCallback((newSorting) => {
        setSorting(newSorting);
    }, [setSorting]);

    const handleFilterChange = useCallback((newFilters) => {
        setColumnFilters(newFilters);
    }, [setColumnFilters]);

    // Common handlers
    const handlePaginationChange = useCallback((updater) => {
        setPagination(updater);
    }, []);

    const handleRowSelectionChange = useCallback((updater) => {
        setRowSelection(updater);
    }, [setRowSelection]);

    const handleSearch = () => {
        if (!searchText || searchText.length >= 3) {
            setGlobalSearch(searchText);
        }
    };

    // Dialog handlers
    const handleOpenMultiSortDialog = () => setMultiSortDialogOpen(true);
    const handleCloseMultiSortDialog = () => setMultiSortDialogOpen(false);
    const handleOpenMultiFilterDialog = () => setMultiFilterDialogOpen(true);
    const handleCloseMultiFilterDialog = () => setMultiFilterDialogOpen(false);

    const handleApplyMultiSort = (newSorting) => {
        setSorting(newSorting);
        setMultiSortDialogOpen(false);
    };

    const handleApplyMultiFilter = (newFilters) => {
        setColumnFilters(newFilters);
        setMultiFilterDialogOpen(false);
    };

    // Common styles
    const commonButtonStyles = {
        width: '28px',
        height: '28px',
        color: '#8797A8',
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#f5f5f5',
            borderColor: '#8797A8',
        },
        '&.Mui-disabled': {
            backgroundColor: '#f5f5f5',
            borderColor: '#e0e0e0',
            color: '#bdbdbd',
        },
    };

    const searchFieldStyles = {
        width: '160px',
        '& .MuiOutlinedInput-root': {
            height: '28px',
            fontSize: '0.875rem',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            '& fieldset': {
                borderColor: '#e0e0e0',
                borderRadius: '12px',
            },
            '&:hover fieldset': {
                borderColor: '#8797A8',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#8797A8',
            },
        },
    };

    const getActiveFiltersCount = (filters) => {
        return filters.length;
    };

    const getActiveSortsCount = (sorting) => {
        return sorting.length;
    };

    const defaultToolbarActions = ({ table }) => {
        const selectedRows = table.getSelectedRowModel().rows;
        const activeFiltersCount = getActiveFiltersCount(columnFilters);
        const activeSortsCount = getActiveSortsCount(sorting);

        return (
            <Stack direction="row" spacing={1} width="100%" alignItems="center">
                {renderTopToolbarCustomActions && renderTopToolbarCustomActions({ table })}
                <Box flexGrow={1} />
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        p: '4px',
                        gap: '8px'
                    }}
                >
                    <TextField
                        placeholder="Search domains..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') handleSearch();
                        }}
                        size="small"
                        sx={searchFieldStyles}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon
                                        sx={{ 
                                            fontSize: '1rem', 
                                            color: '#8797A8',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: '#6b7a8a',
                                            },
                                        }}
                                        onClick={handleSearch}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Box sx={{ display: 'flex', gap: '4px' }}>
                        <Tooltip title={`Sort (${activeSortsCount} active)`} arrow placement="top">
                            <Badge 
                                badgeContent={activeSortsCount} 
                                color="primary"
                                sx={{
                                    '& .MuiBadge-badge': {
                                        fontSize: '0.5rem',
                                        height: '12px',
                                        minWidth: '12px',
                                        padding: '0 3px'
                                    }
                                }}
                            >
                                <IconButton
                                    onClick={handleOpenMultiSortDialog}
                                    size="small"
                                    sx={commonButtonStyles}
                                >
                                    <SortIcon sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                        
                        <Tooltip title={`Filter (${activeFiltersCount} active)`} arrow placement="top">
                            <Badge 
                                badgeContent={activeFiltersCount} 
                                color="primary"
                                sx={{
                                    '& .MuiBadge-badge': {
                                        fontSize: '0.5rem',
                                        height: '12px',
                                        minWidth: '12px',
                                        padding: '0 3px'
                                    }
                                }}
                            >
                                <IconButton
                                    onClick={handleOpenMultiFilterDialog}
                                    size="small"
                                    sx={commonButtonStyles}
                                >
                                    <FilterListIcon sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </Badge>
                        </Tooltip>

                        {(isAdminUser || isEDManagerUser || isESManagerUser) && (
                            <Tooltip title="Update" arrow placement="top">
                                <IconButton
                                    onClick={() => setUpdateDialogOpen(true)}
                                    size="small"
                                    sx={commonButtonStyles}
                                >
                                    <GridOnRoundedIcon sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip title="Copy Domains" arrow placement="top">
                            <span>
                                <IconButton
                                    onClick={() => handleCopyDomains(selectedRows)}
                                    disabled={selectedRows.length === 0}
                                    size="small"
                                    sx={commonButtonStyles}
                                >
                                    <ContentCopyOutlinedIcon sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title="Export Data" arrow placement="top">
                            <span>
                                <IconButton
                                    onClick={() => handleExportData(selectedRows)}
                                    disabled={selectedRows.length === 0}
                                    size="small"
                                    sx={commonButtonStyles}
                                >
                                    <FileDownloadOutlinedIcon sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title="Fetch Stats" arrow placement="top">
                            <span>
                                <IconButton
                                    onClick={handleFetchStats}
                                    disabled={selectedRows.length === 0}
                                    size="small"
                                    sx={commonButtonStyles}
                                >
                                    <SyncOutlinedIcon sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Stack>
            </Stack>
        );
    };

    const columnOrder = ['mrt-row-select', 'edate', 'createdAt', 'list', 'domain', 'p', 'len', 'host', 'tld', 'wby', 'aby', 'reg', 'regx', 'tld_count', 'tld_count_dev', 'sg', 'cpc', 'bids', 'price', 'est', 'gdv', 'esw', 'gdw', 'spr500', 'sprGdv', 'reco', 'apr', 'leads', 'socials', 'code', 'active', 'agents', 'recoRemark', 'aprRemark', 'actions'];

    const table = useMaterialReactTable({
        columns,
        data,

        enableRowSelection: true,
        enableColumnActions: false,
        enableColumnFilters: true,
        enableStickyHeader: true,
        enableSorting: true,
        enableMultiSort: true,
        enableFilters: false,
        manualPagination: true,
        manualSorting: true,
        manualFiltering: true,
        maxMultiSortColCount: 3,
        rowCount,

        state: {
            pagination,
            sorting,
            columnFilters,
            isLoading,
            rowSelection,
        },
        initialState: {
            density: 'compact',
            pagination: { pageSize: 50, pageIndex: 0 },
            sorting: [],
            columnVisibility,
            columnOrder
        },

        onPaginationChange: handlePaginationChange,
        onSortingChange: handleSortingChange,
        onColumnFiltersChange: handleFilterChange,
        onRowSelectionChange: handleRowSelectionChange,

        //toptoolbar
        renderTopToolbarCustomActions: defaultToolbarActions,

        //styling
        muiTableProps: {
            sx: {
                tableLayout: 'fixed',
            },
        },
        muiTablePaperProps: {
            sx: {
                boxShadow: 'none',
                border: '1px solid #e0e0e0',
            },
        },
        muiTableContainerProps: {
            sx: {
                maxHeight: '600px',
            },
        },
        muiTopToolbarProps: {
            sx: {
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #e0e0e0',
                padding: '4px',
            },
        },
        muiBottomToolbarProps: {
            sx: {
                backgroundColor: '#f5f5f5',
                borderTop: '1px solid #e0e0e0',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                '&:first-of-type': {
                    width: '30px',
                },
                '& .MuiCheckbox-root': {
                    padding: '2px',
                    '& svg': {
                        fontSize: '1.2rem',
                    },
                },
                backgroundColor: '#FFC0CB',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '0.7rem',
                padding: '0px',
                margin: '0px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontStyle: 'italic',
                '& .MuiTableSortLabel-icon': {
                    display: 'none',
                },
                '& .Mui-active .MuiTableSortLabel-icon': {
                    display: 'none',
                }
            },
        },
        muiTableBodyCellProps: {
            sx: {
                '&:first-of-type': {
                    width: '30px',
                },
                '& .MuiCheckbox-root': {
                    padding: '2px',
                    '& svg': {
                        fontSize: '1rem',
                    },
                },
                fontSize: '0.7rem',
                padding: '0px',
                margin: '0px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontStyle: 'italic',
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                '&:hover': {
                    backgroundColor: '#e3f2fd !important',
                },
            },
        }),
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <MultiSortDialog
                open={multiSortDialogOpen}
                onClose={handleCloseMultiSortDialog}
                columns={columns}
                initialSorting={sorting}
                onApplySort={handleApplyMultiSort}
            />

            <MultiFilterDialog
                open={multiFilterDialogOpen}
                onClose={handleCloseMultiFilterDialog}
                columns={columns}
                initialFilters={columnFilters}
                onApplyFilter={handleApplyMultiFilter}
            />

            <UpdateShortlistedDomain
                open={updateDialogOpen}
                handleClose={() => setUpdateDialogOpen(false)}
                onSuccess={() => {
                    onDataChange();
                }}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AqTable;
