import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import WorkForce from './Components/WorkForce';
import UploadWork from './Components/UploadWork';
import DomainLive from './Components/DomainLive';
import PQCentre from './Components/PQCentre';
import GenerateQA from './Components/GenerateQA';
import QAReports from './Components/QAReports';
import AnalysedReports from './Components/AnalysedReports';
import WorkInput from './Components/WorkInput';
import WorkInputCopy from './Components/WorkInput copy';
import MISReports from './Components/MISReports';
import MyAllocatedSocials from './Components/MyAllocatedSocials';
import SocialWorkStation from './Components/SocialWorkStation';
import SocialsCollected from './Components/SocialsCollected';
import WorkFile from './Components/WorkFile';
import WorkArchives from './Components/WorkArchive';
import SocialsBank from './Components/SocialsBank';
import UserLastLocation from './Components/UserLastLocation';
import UserLocationList from './Components/UserLocationList';
import { saveUserLocation } from './Components/api';
import { useUser } from "./Components/UserProvider";

// Imports for Allocation Center Leads
import MyLeads from './Components/LeadAllocationCenter/MyLeads';
import AllocatedByMeLeads from './Components/LeadAllocationCenter/AllocatedByMeLeads';
import NonAllocatedLeads from './Components/LeadAllocationCenter/NonAllocatedLeads';
import EmailWorkStation from './Components/LeadAllocationCenter/EmailWorkStation';

// Imports for Allocation Center
import MyDomains from './Components/AllocationCenter/MyDomains';
import Bulkallocator from './Components/AllocationCenter/BulkAllocator';
import AllocatedByMe from './Components/AllocationCenter/AllocatedByMe';
import NonAllocated from './Components/AllocationCenter/NonAllocated';

// Imports for Domain Studio
import MyDomainsOwned from './Components/DomainStudio/DomainStudio-Owned';
import MyDomainsLtd from './Components/DomainStudio/DomainStudio-Ltd';
import MyDomainsPD from './Components/DomainStudio/DomainStudio-PD';
import MyDomainsALD from './Components/DomainStudio/DomainStudio-Ald';

// Imports for LTD Studio
import LtdStudioActive from './Components/LtdStudio/LtdStudioActive';
import LtdStudioClosed from './Components/LtdStudio/LtdStudioClosed';
import LtdStudioExpired from './Components/LtdStudio/LtdStudioExpired';
import LtdStudioArchive from './Components/LtdStudio/LtdStudioArchive';

// Imports for Prospector
import MasterData from './Components/Prospector/MasterData';
import AnalysisHub from './Components/Prospector/AnalysisHub';
import ShortlistedDomains from './Components/Prospector/ShortlistedDomains';
import ProspectorArchive from './Components/Prospector/Archive';

// Imports for Research Center
import ClaimHub from './Components/ResearchCenter/ClaimHub';
import LeadsHub from './Components/ResearchCenter/LeadsHub';
import ResearchCenterArchive from './Components/ResearchCenter/Archive';

// Imports for Acquisition Center
import RecoHub from './Components/AcquisitionCenter/RecoHub';
import DecisionHub from './Components/AcquisitionCenter/DecisionHub';
import OrdersHub from './Components/AcquisitionCenter/OrdersHub';
import AcquisitionCenterActive from './Components/AcquisitionCenter/Active';
import AcquisitionCenterArchive from './Components/AcquisitionCenter/Archive';

// Imports for Email Center
import EmailHub from './Components/EmailCenter/EmailHub';
import EmailClaimHub from './Components/EmailCenter/EmailClaimHub';
import EmailDone from './Components/EmailCenter/EmailDone';
import EmailClaims from './Components/EmailCenter/EmailClaims';
import PortfolioNew from './Components/Portfolio/New';
import PortfolioActive from './Components/Portfolio/Active';
import PortfolioExpiring from './Components/Portfolio/Expiring';
import PortfolioExpired from './Components/Portfolio/Expired';
import PortfolioDeleted from './Components/Portfolio/Deleted';

function App() {
  const { user } = useUser();
  const locationIntervalRef = React.useRef(null);
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (navigator.geolocation && user.email) {
      const saveLocation = async () => {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          const address = await getFormattedAddress(lat, lng);
          const payload = {
            userId: user.email,
            latitude: lat,
            longitude: lng,
            address: address
          };
          console.log("Location Capturing");
          saveUserLocation(payload);
        }, (error) => {
          console.error("Geolocation error:", error);
        });
      };

      const startLocationTracking = () => {
        saveLocation();
        locationIntervalRef.current = setInterval(saveLocation, 40*60 * 1000); // Save location every 40 minutes
      };

      const stopLocationTracking = () => {
        if (locationIntervalRef.current) {
          clearInterval(locationIntervalRef.current);
        }
      };

      //if (isInitialMount.current) {
        startLocationTracking();
        isInitialMount.current = false;
      //}

      return () => {
        stopLocationTracking();
      };
    }
  }, [user]);

  async function getFormattedAddress(lat, lng) {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
      const response = await fetch(url);
      const data = await response.json();
      return data.display_name || 'Address not found';
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  }

  return (
    <div className="App">
      <Router>
        <>
          <Routes>
            <Route path="" exact element={<Home />}>
              <Route path="/nonallocated" exact element={<NonAllocated />} />
              <Route path="/workforce" exact element={<WorkForce />} />
              <Route path="/mydomains" exact element={<MyDomains />} />
              <Route path="/allocatedbyme" exact element={<AllocatedByMe />} />
              <Route path="/myleads" exact element={<MyLeads />} />
              <Route path="/allocatedbymeleads" exact element={<AllocatedByMeLeads />} />
              <Route path="/nonallocatedleads" exact element={<NonAllocatedLeads />} />  
              <Route path="/emailworkstation" exact element={<EmailWorkStation />} />                         
              <Route path="/workupload" exact element={<UploadWork />} />
              <Route path="/workinput/:id" exact element={<WorkInput />} />
              <Route path="/workfile/:id" exact element={<WorkFile />} />
              <Route path="/workinputcopy/:id" exact element={<WorkInputCopy />} />
              <Route path="/workarchives" exact element={<WorkArchives />} />
              <Route path="/domainlive" exact element={<DomainLive />} />
              <Route path="/pqcentre" exact element={<PQCentre />} />
              <Route path="/analysework/:id" exact element={<GenerateQA />} />
              <Route path="/qareports" exact element={<QAReports />} />
              <Route path="/misreports" exact element={<MISReports />} />
              <Route path="/analysedreports" exact element={<AnalysedReports />} />
              <Route path="/domainstudio/owned" exact element={<MyDomainsOwned />} />
              <Route path="/domainstudio/ltd" exact element={<MyDomainsLtd />} />
              <Route path="/domainstudio/pd" exact element={<MyDomainsPD />} />
              <Route path="/domainstudio/ald" exact element={<MyDomainsALD />} />


              <Route path="/ltdstudio/active" exact element={<LtdStudioActive />} />
              <Route path="/ltdstudio/closed" exact element={<LtdStudioClosed />} />
              <Route path="/ltdstudio/expired" exact element={<LtdStudioExpired />} />
              <Route path="/ltdstudio/archive" exact element={<LtdStudioArchive />} />


              <Route path="/socials/mydomains" exact element={<MyAllocatedSocials />} />
              <Route path="/socials/workstation" exact element={<SocialWorkStation />} />
              <Route path="/socials/collected" exact element={<SocialsCollected />} />
              <Route path="/socials/bank" exact element={<SocialsBank />} />
              <Route path="/bulkallocator" exact element={<Bulkallocator />} />
              <Route path="/userlocation" exact element={<UserLocationList />} />
              <Route path="/userlastlocation" exact element={<UserLastLocation />} />

              {/* Prospector routes */}
              <Route path="/prospector/masterdata" exact element={<MasterData />} />
              <Route path="/prospector/analysishub" exact element={<AnalysisHub />} />
              <Route path="/prospector/shortlisteddomains" exact element={<ShortlistedDomains />} />
              <Route path="/prospector/archive" exact element={<ProspectorArchive />} />

              {/* Research Center routes */}
              <Route path="/researchcenter/claimhub" exact element={<ClaimHub />} />
              <Route path="/researchcenter/leadshub" exact element={<LeadsHub />} />
              <Route path="/researchcenter/archive" exact element={<ResearchCenterArchive />} />

              {/* Acquisition Center routes */}
              <Route path="/acquisitioncenter/recohub" exact element={<RecoHub />} />
              <Route path="/acquisitioncenter/decisionhub" exact element={<DecisionHub />} />
              <Route path="/acquisitioncenter/ordershub" exact element={<OrdersHub />} />
              <Route path="/acquisitioncenter/active" exact element={<AcquisitionCenterActive />} />
              <Route path="/acquisitioncenter/archive" exact element={<AcquisitionCenterArchive />} />

              {/* Email Center routes */}
              <Route path="/emailcenter/emailhub" exact element={<EmailHub />} />
              <Route path="/emailcenter/claimhub" exact element={<EmailClaimHub />} />
              <Route path="/emailcenter/emaildone" exact element={<EmailDone />} />
              <Route path="/emailcenter/emailclaims" exact element={<EmailClaims />} />

              {/* Portfolio routes */}
              <Route path="/portfolio/new" exact element={<PortfolioNew />} />
              <Route path="/portfolio/active" exact element={<PortfolioActive />} />
              <Route path="/portfolio/expiring" exact element={<PortfolioExpiring />} />
              <Route path="/portfolio/expired" exact element={<PortfolioExpired />} />
              <Route path="/portfolio/deleted" exact element={<PortfolioDeleted />} />
            </Route>
          </Routes>
        </>
      </Router>
    </div>
  );
}

export default App;
