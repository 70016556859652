import { Alert, Autocomplete, Backdrop, Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Slider, Snackbar, Stack, TextField, ThemeProvider, Tooltip, Typography } from "@mui/material";
import db from "./db";
import { theme } from "./Theme";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { exportContactsToCRM, getAllTags, getDayWork, getDayWorkId, getMissedLeads, getPresentAllocs, getPresentAllocsWI, getProcessors, getRestProcessors, getTodayWork, getUsersQA, restartAnalysis, saveLeadQA, saveMissedLeads, saveTodayWork, setBG, setStartAnswer, startAnalysis, stopAnalysis, uploadWork, workingpa, workstatuspa, removeWorkstationpamulti } from "./api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Add, ArrowDownward, ArrowDropDown, ArrowDropUp, ArrowUpward, Cancel, CheckCircle, Close, Create, Done, DoneOutline, Remove, Save, SignalCellularConnectedNoInternet3BarTwoTone, Sort, Stop, Upload, UndoOutlined } from "@mui/icons-material";
import { useUser } from "./UserProvider";
import { getName, getUserName } from "./msalService";
import { redirect, useNavigate, useParams } from "react-router";
import UploadCSVDialogWork from "./UploadCSVDialogWork";
import { CSVLink } from "react-csv";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Delete, DeleteOutline } from '@mui/icons-material';

const classificationOptions = ["CORP", "STRP", "ECOM", "LOCB", "MISC"]
const leadSourceOptions = ["Google", "DuckDuckGo", "Linkedin", "Ranking", "NameBio", "Past Data", "Assist"]
const emailSourceOptions = ["Apollo", "Clearbit", "ZeroBounce", "Website", "Assist", "Others"]

const conttbvariant = 'outlined'

const getLeadsData = (domainWork) => {
  return domainWork.leads;
};

/////////////////////////////lead input tabs start/////////////////////////////////////

const Leadtb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (event) => {
    const val = event.target.value;
    setInputValue(val);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      if (props.value.trim().length == 0) {
        if (inputValue.trim().length > 0) props.calcL(true, 1);
      } else {
        if (inputValue.trim().length == 0) props.calcL(false, 1);
      }
      props.setLeadName(props.i1, props.in, inputValue);
    }
  };

  return (
    <TextField
      placeholder="lead.com"
      value={inputValue}
      size="small"
      onChange={handleInputChange}
      onBlurCapture={() => {
        if (props.value.trim().length == 0) {
          if (inputValue.trim().length > 0) props.calcL(true, 1);
        } else {
          if (inputValue.trim().length == 0) props.calcL(false, 1);
        }
        props.setLeadName(props.i1, props.in, inputValue);
      }}
      onKeyPress={handleInputKeyPress}
      variant="outlined"
      margin="normal"
      InputProps={{ sx: { fontSize: 14, height: 30 } }}
      sx={{
        "& legend": { display: "none" },
        "& fieldset": { top: 0 },
        padding: 0,
        margin: 0,
      }}
    />
  );
});

const Imp = memo((props) => {
  const [checked, setChecked] = useState(props.checked);
  const [error, setError] = useState(false);

  useEffect(() => { setChecked(props.checked); }, [props.checked])

  useEffect(() => {
    if (checked) {
      const data = props.hasImpC;
      const isValid = data.length >= 2 && data.filter(item =>
        (item.primaryEmail && item.primaryEmail.trim() !== '') || 
        (item.secondaryEmail && item.secondaryEmail.trim() !== '')
      ).length >= 2;
      setError(!isValid);
    } else {
      setError(false);
    }
  }, [props.hasImpC, checked]);

  const onSocialDataChange = useCallback((platform, field, value) => {
    props.updateSocialData(props.i1, props.in, platform, value);
  }, [props.updateSocialData])
  const [open, setOpen] = useState(false);
  const [socialData, setSocialData] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <FormControlLabel
        sx={{ padding: 0 }}
        control={
          <Checkbox
            sx={{
              paddingY: 0,
              color: error ? 'red' : 'default',
              '&.Mui-checked': {
                color: error ? 'red' : 'default',
              }
            }}
            onChange={(event) => {
              const c = event.target.checked;
              if (c) {
                props.normalizeSocials(props.i1, props.in);
                handleOpen();
              } else {
                props.calcImp(false, 1);
                setChecked(false);
                props.setImp(props.i1, props.in, false);
              }
            }}
            checked={checked} size='small'
          />}
        label={<Typography padding={0} fontSize={14} color='text.primary' align='left'>Imp</Typography>
        }
      />
      <SocialDataDialog open={open} onClose={onClose} socialData={props.socialData} url={props.url} noSocialsAvailable={props.noSocialsAvailable} onSocialDataChange={onSocialDataChange} addSocialRows={props.addSocialRows} addSocialData={props.addSocialData} i1={props.i1} i2={props.in} setChecked={setChecked} setImp={props.setImp} calcImp={props.calcImp} leadName={props.leadName} />
    </Box>
  )
}
)

const Cf = memo((props) => {
  const [checked, setChecked] = useState(props.checked);
  useEffect(() => { setChecked(props.checked); }, [props.checked])

  return (<FormControlLabel sx={{ padding: 0 }} control={<Checkbox sx={{ paddingY: 0 }} onChange={(event) => {
    const c = event.target.checked
    setChecked(c); props.setCf(props.i1, props.in, c);
  }
  } checked={checked} size='small' />} label={<Typography padding={0} fontSize={14} color='text.primary' align='left'>CF</Typography>} />)
}
)

const Nametb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  useEffect(() => { setInputValue(props.value); }, [props.value])

  // const [pe, setPe] = useState(props.pe);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.addContactField(props.i1, props.i2, props.i3, props.c, inputValue);
    }
  };
  return (<TextField
    placeholder="Name"
    // defaultValue={props.value}
    value={inputValue}
    size='small'
    onChange={handleInputChange}
    onBlurCapture={() => { props.addContactField(props.i1, props.i2, props.i3, props.c, inputValue); }}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    //autoCorrect='false'
    InputProps={{ sx: { fontSize: 13, height: 25 } }}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 }, padding: 0, margin: 0
    }}
  />)
})

const PEmailtb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  useEffect(() => { setInputValue(props.value); }, [props.value])
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.addContactField(props.i1, props.i2, props.i3, props.c, inputValue);
    }
  };
  return (<TextField
    placeholder="P Email"
    value={inputValue}
    size='small'
    onChange={handleInputChange}
    onBlurCapture={() => { props.addContactField(props.i1, props.i2, props.i3, props.c, inputValue); }}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    //autoCorrect='false'
    InputProps={{ sx: { fontSize: 13, height: 25 } }}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 }, padding: 0, margin: 0
    }}
  />)
})

const SEmailtb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  // const [pe, setPe] = useState(props.pe);
  useEffect(() => { setInputValue(props.value); }, [props.value])
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.addContactField(props.i1, props.i2, props.i3, props.c, inputValue);
    }
  };
  return (<TextField
    placeholder="S Email"
    value={inputValue}
    size='small'
    onChange={handleInputChange}
    onBlurCapture={() => { props.addContactField(props.i1, props.i2, props.i3, props.c, inputValue); }}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    //autoCorrect='false'
    InputProps={{ sx: { fontSize: 13, height: 25 } }}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 }, padding: 0, margin: 0
    }}
  />)
})

const Desigtb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  useEffect(() => { setInputValue(props.value); }, [props.value])

  // const [pe, setPe] = useState(props.pe);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.addContactField(props.i1, props.i2, props.i3, props.c, inputValue);
    }
  };
  return (<TextField
    placeholder="Designation"
    value={inputValue}
    size='small'

    onChange={handleInputChange}
    onBlurCapture={() => { props.addContactField(props.i1, props.i2, props.i3, props.c, inputValue); }}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    //autoCorrect='false'
    InputProps={{ sx: { fontSize: 13, height: 25 } }}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 }, padding: 0, margin: 0
    }}
  />)
})

const ContactSourceSelect = memo((props) => {
  const [selectedValue, setSelectedValue] = useState(props.defaultValue);
  const [error, setError] = useState(false);
  useEffect(() => {
    setSelectedValue(props.defaultValue);
    setError(!props.defaultValue);
  }, [props.defaultValue]);

  const [open, setOpen] = useState(false);
  const onClose = () => { setOpen(false); };

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === 'Assist') {
      setOpen(true);
    } else {
      setSelectedValue(newValue);
      props.addContactField(props.i1, props.i2, props.i3, props.column, newValue);
    }
    setError(false);
  };

  return (
    <Box>
      <FormControl variant="outlined" size="small" fullWidth error={error}>
        <Select
          placeholder={props.title}
          value={selectedValue}
          onChange={handleChange}
          label={props.title}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            padding: 0,
            margin: 0,
            fontSize: 12,
            height: 25
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  fontSize: 12,
                },
              },
            },
          }}
          displayEmpty
          renderValue={(value) => {
            if (!value) {
              return <Typography color={error ? 'error' : 'grey'} fontSize={12}>{props.title}</Typography>;
            }
            return value;
          }}
        >
          {props.options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <AssistedUserDialogContact
        open={open}
        onClose={onClose}
        assistedUserContact={props.assistedUserContact}
        setAssistedUserContact={props.setAssistedUserContact}
        processors={props.processors}
        setSource={props.addContactField}
        i1={props.i1}
        i2={props.i2}
        i3={props.i3}
        setSelectedValue={setSelectedValue}
      />
    </Box>
  );
})

const RowActionsC = memo(({ i1, i2, i3, l, subRowC, addRowC }) => {
  return (<Box sx={{ display: 'flex', gap: '1rem' }}>
    <Tooltip title="Add">
      <IconButton size="small" sx={{ padding: 0 }} color="primary" onClick={() => { addRowC(i1, i2, i3); }}>
        <Add fontSize="small" sx={{ padding: 0 }} />
      </IconButton>
    </Tooltip>
    {(!(i3 == 0 && l == 1)) && <Tooltip title="Remove">
      <IconButton size="small" sx={{ padding: 0 }} color="primary" onClick={() => { subRowC(i1, i2, i3); }}>
        <Remove fontSize="small" sx={{ padding: 0 }} />
      </IconButton>
    </Tooltip>}
  </Box>)
})

const Recycle = memo((props) => {
  const [checked, setChecked] = useState(props.checked);
  useEffect(() => { setChecked(props.checked); }, [props.checked])

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (<Box><FormControlLabel sx={{ padding: 0 }} control={<Checkbox sx={{ paddingY: 0 }} onChange={(event) => {
    const c = event.target.checked;
    if (c) {
      handleOpen();
    } else {
      setChecked(false);
      props.setRecycle(props.i1, props.in, false);
    }
  }} checked={checked} size='small' />} label={<Typography padding={0} fontSize={14} color='text.primary' align='left'>Recycle</Typography>} /><TagSelectionDialog alltags={props.alltags} open={open} onClose={onClose} setRecycle={props.setRecycle} i1={props.i1} i2={props.in} setChecked={setChecked} leadName={props.leadName} selectedTags={props.selectedTags} setSelectedTags={props.setSelectedTags} /></Box>)
}
)

const Allied = memo((props) => {
  const [checked, setChecked] = useState(props.checked);
  useEffect(() => { setChecked(props.checked); }, [props.checked])
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (<Box><FormControlLabel sx={{ padding: 0 }} control={<Checkbox sx={{ paddingY: 0 }} onChange={(event) => {
    const c = event.target.checked;
    if (c) {
      handleOpen();
    } else {
      setChecked(false);
      props.setAllied(props.i1, props.in, false);
    }
  }} checked={checked} size='small' />} label={<Typography padding={0} fontSize={14} color='text.primary' align='left'>Allied</Typography>} /><AlliedDomainDialog open={open} onClose={onClose} setAllied={props.setAllied} i1={props.i1} i2={props.in} setChecked={setChecked} leadName={props.leadName} alliedDomain={props.alliedDomain} alliedRemark={props.alliedRemark} setAlliedInfo={props.setAlliedInfo} /></Box>)
}
)

const LeadInfoSelect = memo((props) => {
  const [selectedValue, setSelectedValue] = useState(props.defaultValue);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSelectedValue(props.defaultValue);
    setError(!props.defaultValue);
  }, [props.defaultValue])

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    console.log(props.column);
    props.setLeadInfo(props.i1, props.i2, props.column, newValue);
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth>

      <Select
        placeholder={props.title}
        value={selectedValue}
        onChange={handleChange}
        label={props.title}
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          padding: 0,
          margin: 0,
          fontSize: 12,
          height: 30
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root': {
                fontSize: 12,
              },
            },
          },
        }}
        displayEmpty
        renderValue={(value) => {
          if (!value) {
            return <Typography color={error ? "error" : "gray"} fontSize={12}>{props.title}</Typography>;
          }
          return value;
        }}
        error={error}
      >
        {props.options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
})

const LeadSourceSelect = memo((props) => {
  const [selectedValue, setSelectedValue] = useState(props.defaultValue);
  const [error, setError] = useState(false);
  useEffect(() => {
    setSelectedValue(props.defaultValue);
    setError(!props.defaultValue);
  }, [props.defaultValue]);

  const [open, setOpen] = useState(false);
  const onClose = () => { setOpen(false); };

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === 'Assist') {
      setOpen(true);
    } else {
      setSelectedValue(newValue);
      setError(false);
      props.setLeadInfo(props.i1, props.i2, props.column, newValue);
    }
  };

  return (
    <Box>
      <FormControl variant="outlined" size="small" fullWidth error={error}>
        <Select
          placeholder={props.title}
          value={selectedValue}
          onChange={handleChange}
          label={props.title}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            padding: 0,
            margin: 0,
            fontSize: 12,
            height: 30
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  fontSize: 12,
                },
              },
            },
          }}
          displayEmpty
          renderValue={(value) => {
            if (!value) {
              return <Typography color={error ? "error" : "gray"} fontSize={12}>{props.title}</Typography>;
            }
            return value;
          }}
        >
          {props.options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <AssistedUserDialogLead
        open={open}
        onClose={onClose}
        assistedUserLead={props.assistedUserLead}
        setAssistedUserLead={props.setAssistedUserLead}
        processors={props.processors}
        setSource={props.setLeadInfo}
        i1={props.i1}
        i2={props.i2}
        i3={props.i3}
        setSelectedValue={setSelectedValue}
      />
    </Box>
  );
});

const RowActionsL = memo(({ i1, i2, l, subRowL, site, calcL }) => {
  return (<Box sx={{ display: 'flex', gap: '1rem' }}>
    {(!(i2 == 0 && l == 1)) &&
      <Tooltip title="Remove">
        <IconButton size="small" sx={{ padding: 0 }} color="primary" onClick={() => {
          subRowL(i1, i2); const a = "ab";
          if (site && site.length > 0)
            calcL(false, 1)
        }}>
          <Remove fontSize="small" sx={{ padding: 0 }} />
        </IconButton>
      </Tooltip>}
  </Box>);
})

const CheckB = memo((props) => {
  const [status, setStatus] = useState(props.status);

  const Icon = (props) => {
    if (props.stat == 0)
      return (
        <Tooltip title="Not Started Processing">
          <Stop sx={{ padding: 0 }} />
        </Tooltip>
      );
    else if (props.stat == 1)
      return (
        <Tooltip title="Processing">
          <Create sx={{ padding: 0 }} color="primary" />
        </Tooltip>
      );
    else if (props.stat == 2)
      return (
        <Tooltip title="Processed">
          <DoneOutline sx={{ color: "green", padding: 0 }} />
        </Tooltip>
      );
  };

  return (
    <Box>
      <IconButton
        sx={{ padding: 0 }}
        size="small"
        onClick={() => {
          let s = status;
          if (status == 0) s = 1;
          else if (status == 1) s = 2;
          if (status == 2) s = 0;
          workstatuspa(props.id, s)
            .then((res) => {
              setStatus(s);
              props.setWorkStatus(status, props.i);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        <Icon stat={status} />
      </IconButton>
    </Box>
  );
});

/////////////////////////////lead input tabs ended//////////////////////
////////////////////////////render input tabs start/////////////////////

//for rendering of contact details
const RenderDetailPanelLeads = memo(
  ({
    row,
    contactsData,
    r1,
    subRowC,
    addRowC,
    addContactField,
    setAssistedUserContact,
    processors,
  }) => {
    const [r2, setR2] = useState(row);

    const contactsColumns = useMemo(
      () => [
        {
          id: "name",
          accessorKey: "name",
          size: 220,
          Cell: ({ renderedCellValue, row, column, table }) => {
            return (
              <Nametb
                value={row.original.name}
                i1={r1.index}
                i2={r2.index}
                i3={row.index}
                c={column.columnDef.accessorKey}
                addContactField={addContactField}
              />
            );
          },
          header: "Name",
        },
        {
          id: "pemail",
          header: "P Email",
          size: 220,
          accessorKey: "primaryEmail",
          Cell: ({ renderedCellValue, row, column, table }) => {
            return (
              <PEmailtb
                value={row.original.primaryEmail}
                i1={r1.index}
                i2={r2.index}
                i3={row.index}
                c={column.columnDef.accessorKey}
                addContactField={addContactField}
              />
            );
          },
        },
        {
          id: "semail",
          header: "S Email",
          size: 220,
          accessorKey: "secondaryEmail",
          Cell: ({ renderedCellValue, row, column, table }) => {
            return (
              <SEmailtb
                value={row.original.secondaryEmail}
                i1={r1.index}
                i2={r2.index}
                i3={row.index}
                c={column.columnDef.accessorKey}
                addContactField={addContactField}
              />
            );
          },
        },
        {
          accessorKey: "designation",
          size: 220,
          header: "Designation",
          Cell: ({ renderedCellValue, row, column, table }) => {
            return (
              <Desigtb
                value={row.original.designation}
                i1={r1.index}
                i2={r2.index}
                i3={row.index}
                c={column.columnDef.accessorKey}
                addContactField={addContactField}
              />
            );
          },
        },
        {
          accessorKey: "source",
          size: 140,
          header: "Source",
          Cell: ({ renderedCellValue, row, column, table }) => {
            return (
              <ContactSourceSelect
                defaultValue={row.original.source}
                i1={r1.index}
                i2={r2.index}
                i3={row.index}
                column={column.columnDef.accessorKey}
                addContactField={addContactField}
                options={emailSourceOptions}
                processors={processors}
                setAssistedUserContact={setAssistedUserContact}
                assistedUserContact={row.original.assistedUser}
                title="Source"
              />
            );
          },
        },
      ],
      []
    );

    const contactTable = useMaterialReactTable({
      columns: contactsColumns,
      data: contactsData,
      enableExpandAll: true,
      enableRowActions: true,
      positionActionsColumn: "last",
      renderRowActions: ({ row }) => (
        <RowActionsC
          i1={r1.index}
          i2={r2.index}
          i3={row.index}
          l={contactsData.length}
          addRowC={addRowC}
          subRowC={subRowC}
        />
      ),
      layoutMode: "grid-no-grow",
      enableBottomToolbar: false,
      initialState: { density: "compact" },
      muiTablePaperProps: { elevation: 0 },
      muiTableBodyRowProps: { sx: { padding: 0, margin: 0, height: 30 } },
      enableTopToolbar: false,
      enableTableHead: false,
    });
    return (
      <Box flexGrow={1} width="100%" sx={{ flexGrow: 1 }}>
        <MaterialReactTable table={contactTable} />
      </Box>
    );
  }
);

//for rendering of conditional table header for sortings
const CustomTableHeader = memo(({ onSortChange, sortConfig }) => {
  const headers = [
    { id: 'site', label: 'Site', type: 'lead', width: 220 },
    { id: 'imp', label: 'Imp', type: 'lead', width: 80 },
    { id: 'contactForm', label: 'CF', type: 'lead', width: 80 },
    { id: 'name', label: 'Name', type: 'contact', width: 220 },
    { id: 'primaryEmail', label: 'P Email', type: 'contact', width: 220 },
    { id: 'secondaryEmail', label: 'S Email', type: 'contact', width: 220 },
    { id: 'designation', label: 'Designation', type: 'contact', width: 220 },
  ];
  return (
    <Stack direction='row' pl={1}>
      {headers.map(header => (
        <Stack sx={{ width: `${header.width}px` }} spacing={1} direction='row' pl={header.type == 'lead' ? 0 : 1} alignItems='center'>
          <Typography variant="subtitle2" fontWeight='bold' key={header.id}>{header.label}</Typography>
          <Tooltip title="Sort"><IconButton size='small' sx={{ padding: 0, color: 'black' }} color="primary" onClick={() => { onSortChange(header.id, header.type) }}>
            {sortConfig && sortConfig.key === header.id ? (sortConfig.direction === 'asc' ? <ArrowUpward sx={{ fontSize: 14 }} /> : <ArrowDownward sx={{ fontSize: 14 }} />) : <Sort sx={{ fontSize: 14 }} />}
          </IconButton></Tooltip>
        </Stack>
      ))}
    </Stack>
  );
});

//for rendering lead tab details with contact detail(RenderDetailPanelLeads)
const RenderDetailPanel =
  memo(({ row, subRowC, addRowC, setLeadName, setLeadInfo, addLeads, setCf, setImp, setRecycle, addContactField, subRowL, calcLd, setLeads, setCategory, normalizeSocials, updateSocialData, addSocialRows, addSocialData, alltags, setSelectedTags, setAlliedInfo, setAllied, setAssistedUserContact, setAssistedUserLead, processors, setLeadAllocationState }) => {
    const leadsData = useMemo(() => { return getLeadsData(row.original.todayDW) }, [row.original.todayDW.leads]);
    const [r1, setR1] = useState(row);
    const [xrow, setXrow] = useState(true);
    const [l, setL] = useState(row.original.todayDW.lc);
    const [impc, setImpc] = useState(row.original.todayDW.impc);
    const [bg, setBg] = useState(row.original.domain.category || "misc");

    useEffect(() => {
      setBg(row.original.domain.category || "misc");
      if(row.original.domain.category === ""){
        setBG("misc", row.original.domain.id)
        .then((res) => setCategory("misc", r1.index))
        .catch((err) => {
          console.log(err);
          setBg("misc");
        })
      }
     }, [])

    const calcL = useCallback(async (b, c) => {
      if (b) { setL((l) => { return l + c }); }
      else { setL((l) => { return l - c }); }
      calcLd(b, c)
    }, [l])

    const calcImp = useCallback(async (b, c) => {
      if (b) { setImpc((l) => { return l + c }); }
      else { setImpc((l) => { return l - c }); }
    }, [l])

    const [sortConfig, setSortConfig] = useState(null);
    const [sortVisible, setSortVisible] = useState(false);

    const [copyLeads, setCopyLeads] = useState(() => {
      return leadsData.map(lead => lead.site).filter(site => site);
    });
    const [tooltipText, setTooltipText] = useState("Copy to clipboard");

    const onCopyLeads = () => {

      const textToCopy = copyLeads.join('\n');
      navigator.clipboard.writeText(textToCopy).then(() => {
        setTooltipText("Copied!");
        setTimeout(() => {
          setTooltipText("Copy to clipboard");
        }, 2000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }



    const onSortChange =
      (columnId, columnType) => {
        setSortConfig((currentSortConfig) => {
          let direction = 'asc';
          if (currentSortConfig && currentSortConfig.key === columnId && currentSortConfig.direction === 'asc') {
            direction = 'desc';
          } else {
            direction = 'asc';
          }

          const sorted = [...leadsData].sort((a, b) => {
            const aValue = columnType == 'lead' ? a[columnId] : a.contacts[0] ? a.contacts[0][columnId] : ''; // Simplified for demonstration
            const bValue = columnType == 'lead' ? b[columnId] : b.contacts[0] ? b.contacts[0][columnId] : '';
            console.log(aValue, bValue)
            if (direction === 'asc') {
              return aValue > bValue ? 1 : -1;
            } else {
              return aValue < bValue ? 1 : -1;
            }
          });
          setLeads(sorted, r1.index)
          return { key: columnId, direction };
        });
      }


    const leadsColumns = useMemo(() => [
      {
        id: 'site',
        accessorKey: 'site',
        header: 'Leads',
        Cell: ({ renderedCellValue, row }) => { 
          return (<Leadtb value={row.original.site} in={row.index} i1={r1.index} setLeadName={setLeadName} calcL={calcL} />) 
        },
        size: 220, 
        maxSize: 220
      },
      {
        accessorKey: 'leadAllocationState',
        header: 'Alloc',
        id: 'leadAllocationState',
        size: 80,
        maxSize: 80,
        Cell: ({ renderedCellValue, row }) => { 
          return (
            <LeadAllocation 
              leadAllocationState={row.original.leadAllocationState || 0} 
              in={row.index} 
              i1={r1.index} 
              setLeadAllocationState={setLeadAllocationState} 
            />
          ) 
        },
      },
      {
        accessorKey: 'imp',
        header: 'Imp',
        id: 'imp',
        size: 80,
        maxSize: 80,
        Cell: ({ renderedCellValue, row }) => { return (<Imp hasImpC={row.original.contacts} checked={row.original.imp} in={row.index} i1={r1.index} setImp={setImp} calcImp={calcImp} impc={impc} bg={bg} normalizeSocials={normalizeSocials} updateSocialData={updateSocialData} socialData={row.original.socialLead ? row.original.socialLead.socials : []} addSocialRows={addSocialRows} leadName={row.original.site} addSocialData={addSocialData} url={row.original.socialLead ? row.original.socialLead.url : ''} noSocialsAvailable={row.original.socialLead ? row.original.socialLead.noSocialsAvailable : false} />) },
      }, {
        header: 'C Form',
        id: 'cform',
        Cell: ({ renderedCellValue, row }) => { return (<Cf checked={row.original.contactForm} in={row.index} i1={r1.index} setCf={setCf} />) },
        size: 80, maxSize: 80,
      }, {
        header: 'Contacts',
        id: 'contact',
        Cell: ({ renderedCellValue, row }) => { return (<RenderDetailPanelLeads row={row} contactsData={row.original.contacts} r1={r1} subRowC={subRowC} addRowC={addRowC} setAssistedUserContact={setAssistedUserContact} processors={processors} addContactField={addContactField} />) },
        size: 1110, maxSize: 1110
      }, {
        header: 'Recycle',
        id: 'recycle',
        Cell: ({ renderedCellValue, row }) => { return (<Recycle checked={row.original.recycle} in={row.index} i1={r1.index} setRecycle={setRecycle} alltags={alltags} setSelectedTags={setSelectedTags} selectedTags={row.original.tags} leadName={row.original.site} />) },
        size: 100, maxSize: 100,
      }, {
        header: 'Allied',
        id: 'alliedInfo',
        Cell: ({ renderedCellValue, row }) => { return (<Allied checked={row.original.allied} in={row.index} i1={r1.index} setAlliedInfo={setAlliedInfo} alliedDomain={row.original.alliedDomain} alliedRemark={row.original.alliedRemark} leadName={row.original.site} setAllied={setAllied} />) },
        size: 80, maxSize: 80,
      }, {
        accessorKey: 'classification',
        header: 'Classification',
        id: 'classification',
        Cell: ({ renderedCellValue, row, column }) => { return (<LeadInfoSelect defaultValue={row.original.classification} column={column.columnDef.accessorKey} i1={r1.index} i2={row.index} setLeadInfo={setLeadInfo} options={classificationOptions} title='Classification' />) },
        size: 140, maxSize: 150
      }, {
        accessorKey: 'source',
        header: 'Source',
        id: 'source',
        Cell: ({ renderedCellValue, row, column }) => { return (<LeadSourceSelect defaultValue={row.original.source} column={column.columnDef.accessorKey} i1={r1.index} i2={row.index} setLeadInfo={setLeadInfo} options={leadSourceOptions} setAssistedUserLead={setAssistedUserLead} processors={processors} assistedUserLead={row.original.assistedUser} title='Source' />) },
        size: 140, maxSize: 150
      }
    ], [row.original.todayDW.leads, bg, impc])
    const [inputValue, setInputValue] = useState('');
    const [pl, setPl] = useState('');

    const [upfail, setUpfail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [textInputs, setTextInputs] = useState(new Set());



    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleInputKeyPress = (event) => {
      if (event.key === 'Enter' && inputValue.trim() !== '') {
        setTextInputs((prevInputs) => new Set([...prevInputs, inputValue.trim().toLowerCase()]));
        setInputValue('');
      }
    };

    const handleRemoveInput = (input) => {
      const updatedInputs = new Set(textInputs);
      updatedInputs.delete(input);
      setTextInputs(updatedInputs);
    };

    const leadTable = useMaterialReactTable({
      columns: leadsColumns,
      data: leadsData,
      enableExpandAll: true,
      enableBottomToolbar: false,
      enableTopToolbar: false,
      enableTableHead: false,
      layoutMode: 'grid-no-grow',
      enableRowActions: true,
      positionActionsColumn: 'last',
      renderRowActions: ({ row }) => (
        <RowActionsL i1={r1.index} i2={row.index} l={leadsData.length} subRowL={subRowL} site={row.original.site} calcL={calcL} />

      ),
      initialState: {//expanded:true,
        density: 'compact', pagination: { pageSize: 1000, pageIndex: 0 }
      },

      state: { isLoading: isLoading },
      //muiTableBodyRowProps:{ sx:{flex:true,flexDirection:'row', alignItems:'end',justifyContent:'s'}},
      muiTableBodyProps: { sx: { backgroundColor: '#fcfcfa' } }, muiTableBodyRowProps: { sx: { backgroundColor: '#fcfcfa', paddingY: 0 } },
      muiTablePaperProps: { elevation: 0 },
      muiDetailPanelProps: { sx: { paddingY: 0, backgroundColor: 'white', paddingLeft: 8 } },
      onEditingRowSave: ({ values, table, row }) => {
        saveLeadQA(row.original.id, values.impMiss == 'Yes', values.irrelevant == 'Yes', values.primaryEmailMissing ? values.primaryEmailMissing : "").then((res) => { table.setEditingRow(null); console.log(res.data) })
          .catch((err) => { console.log(err); setUpfail(true) })
      },

    });
    return (
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          width="100%"
          justifyContent="start"
          alignItems="center"
          spacing={3}
          paddingLeft={2}
          paddingRight={5}
        >
          <Tooltip title={tooltipText} arrow>
            <Box
              onClick={onCopyLeads}
              sx={{
                fontSize: "14px",
                display: "inline-block",
                cursor: "pointer",
                borderRadius: "50%",
                padding: "5px",
              }}
            >
              <ContentCopyIcon sx={{ fontSize: "inherit" }} variant="body2" fontWeight="bold" />
            </Box>
          </Tooltip>

          <Button
            size="small"
            endIcon={sortVisible ? <ArrowDropUp /> : <ArrowDropDown />}
            onClick={() => setSortVisible(!sortVisible)}
          >
            Sort
          </Button>

          <FormControl variant="standard" sx={{ width: "125px", height: "25px" }} size="small">
            <Select
              labelId="demo-controlled-select-label"
              id="demo-controlled-select"
              value={bg || ""}
              label="Category"
              displayEmpty
              onChange={(event) => {
                const v = event.target.value;
                const b = bg;
                setBg(v);
                setBG(v, row.original.domain.id)
                  .then((res) => setCategory(v, r1.index))
                  .catch((err) => {
                    console.log(err);
                    setBg(b);
                  });
              }}
              sx={{
                '.MuiSelect-select': {
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '0px',
                  paddingRight: '12px',
                }
              }}
            >
              <MenuItem value="" disabled>
                <Typography fontSize={13} color="text.secondary">
                  Choose Category
                </Typography>
              </MenuItem>
              <MenuItem value="generic">
                <Typography fontSize={13} color="text.primary">
                  Generic
                </Typography>
              </MenuItem>
              <MenuItem value="specific">
                <Typography fontSize={13} color="text.primary">
                  Specific
                </Typography>
              </MenuItem>
              <MenuItem value="ventures">
                <Typography fontSize={13} color="text.primary">
                  Ventures
                </Typography>
              </MenuItem>
              <MenuItem value="misc">
                <Typography fontSize={13} color="text.primary">
                  Misc
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>

          <Typography variant="body2" fontWeight="bold">
            Leads Count: {l}
          </Typography>
          <Snackbar
            open={upfail}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setUpfail(false)}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              Not Uploaded!
            </Alert>
          </Snackbar>
        </Stack>

        {sortVisible && <CustomTableHeader onSortChange={onSortChange} sortConfig={sortConfig} />}
        <MaterialReactTable table={leadTable} />
        <Stack direction='row' paddingLeft={1}>
          <TextField
            placeholder="Paste or Enter Leads"
            value={pl}
            size='small'
            multiline
            onChange={(event) => {
              setPl(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                let pll = pl.trim();
                console.log("t1")
                if (pll !== '') {
                  const arr = pll.split('\n')
                  const l = arr.map((ar) => { return createLeadRowWithSite(ar.trim().toLowerCase()) })
                  calcL(true, arr.length)
                  addLeads(row.index, l)
                  setPl('');
                }
              }
            }}
            variant='outlined'
            margin="normal"
            //autoCorrect={false}
            InputProps={{ sx: { fontSize: 14 } }}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 }, padding: 0, margin: 0, width: 190
            }}
          />
        </Stack>
      </Stack>
    )
  })

////////////////////////////render input tabs end/////////////////////
///////////////////////function start///////////////////

let createLeadRow = () => {
  return {
    site: "",
    contactForm: false,
    imp: false,
    recycle: false,
    allied: false,
    alliedRemark: "",
    source: "",
    classification: "",
    allowMailing: false,
    contacts: [{ name: "", primaryEmail: "", secondaryEmail: "", designation: "", source: "" }]
  }
}

let createLeadRowWithSite = (site) => {
  return {
    site: site,
    contactForm: false,
    imp: false,
    recycle: false,
    allied: false,
    alliedRemark: "",
    source: "",
    classification: "",
    allowMailing: false,
    contacts: [{ name: "", primaryEmail: "", secondaryEmail: "", designation: "", source: "" }]
  }
}

let createContactRow = () => {
  return { name: "", primaryEmail: "", secondaryEmail: "", designation: "", source: "" }
}

let createSocialArray = () => {
  return [
    { platform: 'Linkedin', employees: 0, followers: 0, url: "" },
    { platform: 'Facebook', followers: 0, url: "" },
    { platform: 'Instagram', followers: 0, url: "" },
    { platform: 'Twitter', followers: 0, url: "" }
  ];
}

let createSocialLead = (domainName) => {
  return {
    domain: domainName,
    noSocialsAvailable: false,
    socials: createSocialArray()
  }
}


///////////////////////function ended///////////////////
/////////////////////////main code for input work is below from line 1200 to 2100start/////////////


export default function WorkInput() {
  const { id } = useParams();

  const [presentAllocs, setPresentAllocs] = useState([]);
  const [alltags, setAlltags] = useState([]);
  const [processors, setProcessors] = useState([]);

  const ref = useRef(null)
  const [ld, setLd] = useState(0);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [work, setWork] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [scanningLeads, setScanningLeads] = useState(false);
  const [unmailableLeadsCount, setUnmailableLeadsCount] = useState(0);
  const [unmailableDialogOpen, setUnmailableDialogOpen] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const isValidLead = (lead) => {
    return lead.site && lead.site.trim() !== '';
  };

  useEffect(() => {
    getRestProcessors().then((res) => { setProcessors(res.data) }).catch((err) => { console.log(err) })
  }, [])

  const countUnmailableLeads = () => {
    setScanningLeads(true);
    let count = 0;

    presentAllocs.forEach((alloc) => {
      alloc.todayDW.leads.forEach((lead) => {
        if (isValidLead(lead) && !lead.allowMailing) {
          count++;
        }
      });
    });

    setUnmailableLeadsCount(count);
    setScanningLeads(false);
    return count;
  };

  useEffect(() => {
    if (work && work.length > 0) {
      setLoading(true)
      console.log("saving work", presentAllocs)
      saveTodayWork(date, presentAllocs).then((res) => {
        console.log("saving work")
        setB(!b)
        setChanges(false)
        saveChangesIndexDB(false);
        //setOpenS(true);
        uploadWork(work).then((res) => {
          setUpsuc(true); setWork([]); setB(!b); getPresentAllocsWI().then((res) => {
            updateDataFromServer(res);
            setLoading(false)
          }).catch((err) => { console.log(err); setLoading(false) })
        }).catch((err) => { console.log(err); setUpfail(true); setLoading(false) });


      }).catch((err) => { console.log(err); setOpenF(true); setLoading(false) })
    }
  }, [work])

  const [canreview, setCanreview] = useState(true);

  const saveChangeToIndexDB = async (id, allocs) => {
    db.transaction('rw', db.presentAllocs, async () => {
      await db.presentAllocs.put(allocs[id]);
    }).then(() => {
      let d = new Date(); db.data.update(1, { lastWorkSaved: d.getTime() });
    }).catch((error) => {
      console.error(`Failed to update presentAllocs in IndexedDB: ${error}`);
    });
  }

  const saveChangesIndexDB = async (changes) => {
    db.transaction('rw', db.data, async () => {
      await db.data.update(1, { changes: changes });
    }).catch((error) => {
      console.error(`Failed to update changes in data in IndexedDB: ${error}`);
    });
  }

  const saveLdIndexDB = async (ld) => {
    db.transaction('rw', db.data, async () => {
      await db.data.update(1, { ld: ld });
    }).catch((error) => {
      console.error(`Failed to update ld in data in IndexedDB: ${error}`);
    });
  }

  const setImp = useCallback((i1, i2, c) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].imp = c; // Update the site value at the specified index
      if (c)
        updatedAllocs[i1].todayDW.impc = updatedAllocs[i1].todayDW.impc + 1;
      else
        updatedAllocs[i1].todayDW.impc = updatedAllocs[i1].todayDW.impc - 1;

      return  updateAllowMailing(updatedAllocs, i1, i2);
    });
  }, [])

  const addContactField = useCallback((i1, i2, i3, c, val) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = prevAllocs.map((alloc, index) => {
        if (index === i1) {
          return {
            ...alloc,
            todayDW: {
              ...alloc.todayDW,
              leads: alloc.todayDW.leads.map((lead, leadIndex) => {
                if (leadIndex === i2) {
                  return {
                    ...lead,
                    contacts: lead.contacts.map((contact, contactIndex) => {
                      if (contactIndex === i3) {
                        return { ...contact, [c]: val };
                      }
                      return contact;
                    }),
                  };
                }
                return lead;
              }),
            },
          };
        }
        return alloc;
      });
  
      if (c === 'source' || c === 'primaryEmail' || c === 'secondaryEmail') {
        return updateAllowMailing(updatedAllocs, i1, i2);
      }
  
      setChanges(true);
      saveChangesIndexDB(true);
      saveChangeToIndexDB(i1, updatedAllocs);
  
      return updatedAllocs;
    });
  }, [])

  const setCf = useCallback((i1, i2, c) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].contactForm = c; // Update the site value at the specified index
      setChanges(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      saveChangesIndexDB(true);
      return updatedAllocs;
    });
  }, [])

  const addRowC = useCallback((i1, i2, i3) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].contacts = [...updatedAllocs[i1].todayDW.leads[i2].contacts.slice(0, i3 + 1), createContactRow(), ...updatedAllocs[i1].todayDW.leads[i2].contacts.slice(i3 + 1)]
      return updatedAllocs;
    });
  }, [])

  const subRowC = useCallback((i1, i2, i3) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].contacts = [...updatedAllocs[i1].todayDW.leads[i2].contacts.slice(0, i3), ...updatedAllocs[i1].todayDW.leads[i2].contacts.slice(i3 + 1)]
      saveChangeToIndexDB(i1, updatedAllocs);
      setChanges(true);
      saveChangesIndexDB(true);
      return updatedAllocs;
    });
  }, [])

  const subRowL = useCallback((i1, i2) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads = [...updatedAllocs[i1].todayDW.leads.slice(0, i2), ...updatedAllocs[i1].todayDW.leads.slice(i2 + 1)]
      saveChangeToIndexDB(i1, updatedAllocs);
      setChanges(true);
      saveChangesIndexDB(true);
      return updatedAllocs;
    });
  }, [])

  const setRecycle = useCallback((i1, i2, c) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].recycle = c; // Update the site value at the specified index
      setChanges(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      saveChangesIndexDB(true);
      return updatedAllocs;
    });
  }, [])

  const setAllied = useCallback((i1, i2, c) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].allied = c;
      setChanges(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      saveChangesIndexDB(true);
      return updatedAllocs;
    });
  }, [])

  const setSelectedTags = useCallback((i1, i2, tags) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].tags = [...tags];
      setChanges(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      saveChangesIndexDB(true);
      return updatedAllocs;
    });
  }, [])

  const setAlliedInfo = useCallback((i1, i2, alliedDomain, alliedRemark) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].alliedDomain = alliedDomain;
      updatedAllocs[i1].todayDW.leads[i2].alliedRemark = alliedRemark;
      setChanges(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      saveChangesIndexDB(true);
      return updatedAllocs;
    });
  }, [])

  const addSocialRows = useCallback((i1, i2, rows) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].socialLead.socials = [...rows];
      setChanges(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      saveChangesIndexDB(true);
      return updatedAllocs;
    });
  }, [])

  const addSocialData = useCallback((i1, i2, rows, url, noSocialsAvailable) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].socialLead.socials = [...rows];
      updatedAllocs[i1].todayDW.leads[i2].socialLead.url = url;
      updatedAllocs[i1].todayDW.leads[i2].socialLead.noSocialsAvailable = noSocialsAvailable;
      setChanges(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      saveChangesIndexDB(true);

      return updatedAllocs;
    });
  }, [])

  const updateSocialData = useCallback((i1, i2, platform, social) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      const leads = updatedAllocs[i1].todayDW.leads;

      if (leads[i2] && leads[i2].socialLead && leads[i2].socialLead.socials) {
        const socialIndex = leads[i2].socialLead.socials.findIndex(s => s.platform === platform);

        if (socialIndex !== -1) {
          if (platform === 'Linkedin') {
            leads[i2].socialLead.socials[socialIndex].employees = social;
          } else {
            leads[i2].socialLead.socials[socialIndex].followers = social;
          }
        } else {
          // If the platform doesn't exist, add it
          const newSocial = platform === 'Linkedin'
            ? { platform, employees: social }
            : { platform, followers: social };
          leads[i2].socialLead.socials.push(newSocial);
        }

        setChanges(true);
        saveChangeToIndexDB(i1, updatedAllocs);
        saveChangesIndexDB(true);
      }

      return updatedAllocs;
    });
  }, []);

  const normalizeSocials = useCallback((i1, i2) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      let socialLead = updatedAllocs[i1].todayDW.leads[i2].socialLead;
      if (socialLead) {
        let socials = socialLead.socials;
        //console.log(socials)
        if (!socials || socials.length < 4) {
          console.log("adding socials")
          const defaultSocials = [
            { platform: 'Linkedin', employees: 0, followers: 0, url: "" },
            { platform: 'Facebook', followers: 0, url: "" },
            { platform: 'Instagram', followers: 0, url: "" },
            { platform: 'Twitter', followers: 0, url: "" }
          ];

          // If socials is null, initialize it with default values
          if (!socials) {
            socials = [...defaultSocials];
          } else {
            // Create a map of existing platforms
            const existingPlatforms = new Map(socials.map(s => [s.platform, s]));

            // Merge existing with default, keeping existing values
            socials = defaultSocials.map(def => {
              const existing = existingPlatforms.get(def.platform);
              return existing || def;
            });
          }
          updatedAllocs[i1].todayDW.leads[i2].socialLead.socials = socials;
          saveChangeToIndexDB(i1, updatedAllocs);
        }
      }
      else {
        updatedAllocs[i1].todayDW.leads[i2].socialLead = createSocialLead(updatedAllocs[i1].todayDW.leads[i2].site);
        saveChangeToIndexDB(i1, updatedAllocs);
      }
      console.log("normalised socials")

      return updatedAllocs;
    });
  }, []);

  const addLeads = useCallback((i1, l) => {
    setPresentAllocs((prevAllocs) => {
      let updatedAllocs = [...prevAllocs];
      updatedAllocs[i1] = { ...updatedAllocs[i1], todayDW: { ...updatedAllocs[i1].todayDW, leads: [...updatedAllocs[i1].todayDW.leads, ...l] } }; // Update the site value at the specified index
      saveChangeToIndexDB(i1, updatedAllocs);
      saveChangesIndexDB(true);
      setChanges(true);
      return updatedAllocs;
    });
  }, [])

  const setLeads = useCallback((leads, i1) => {
    setPresentAllocs((prevAllocs) => {
      let updatedAllocs = [...prevAllocs];
      updatedAllocs[i1] = { ...updatedAllocs[i1], todayDW: { ...updatedAllocs[i1].todayDW, leads: leads } }; // Update the site value at the specified index
      saveChangeToIndexDB(i1, updatedAllocs);
      return updatedAllocs;
    });
  }, [])

  const setLeadName = useCallback((i1, i2, inputValue) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].site = inputValue; // Update the site value at the specified index
      setChanges(true);
      saveChangesIndexDB(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      return updatedAllocs;
    });
  }, [])

  const setAssistedUserLead = useCallback((i1, i2, assistedUser) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].assistedUser = assistedUser; // Update the site value at the specified index
      setChanges(true);
      saveChangesIndexDB(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      return updatedAllocs;
    });
  }, [])

  const setAssistedUserContact = useCallback((i1, i2, i3, assistedUser) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].contacts[i3].assistedUser = assistedUser; // Update the site value at the specified index
      setChanges(true);
      saveChangesIndexDB(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      return updatedAllocs;
    });
  }, [])

  const setLeadInfo = useCallback((i1, i2, column, inputValue) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2][column] = inputValue; // Update the site value at the specified index
      if (column === 'classification' || column === 'source') {
        return updateAllowMailing(updatedAllocs, i1, i2);
      }
      setChanges(true);
      saveChangesIndexDB(true);
      saveChangeToIndexDB(i1, updatedAllocs);

      return updatedAllocs;
    });
  }, [])

  const isValidContact = (contact) => {
    const hasEmail = (contact.primaryEmail && contact.primaryEmail.trim() !== '') ||
      (contact.secondaryEmail && contact.secondaryEmail.trim() !== '');
    return hasEmail;
  };

  const updateAllowMailing = (allocs, i1, i2) => {
    const updatedAllocs = [...allocs];
    const lead = updatedAllocs[i1].todayDW.leads[i2];

    const hasClassification = lead.classification && lead.classification.trim() !== '';
    const hasLeadSource = lead.source && lead.source.trim() !== '';
    const allContactsHaveSource = lead.contacts.every(contact => { if (isValidContact(contact)) return contact.source && contact.source.trim() !== ''; else return true; });

    let hasImpC = true;
    if(lead.imp){
        hasImpC = lead.contacts.length >= 2 && lead.contacts.filter(item =>
          (item.primaryEmail && item.primaryEmail.trim() !== '') ||
          (item.secondaryEmail && item.secondaryEmail.trim() !== '')
        ).length >= 2;
    }

    lead.allowMailing = !!(hasClassification && hasLeadSource && allContactsHaveSource && hasImpC);

    setChanges(true);
    saveChangesIndexDB(true);
    saveChangeToIndexDB(i1, updatedAllocs);

    return updatedAllocs;
  };

  const setWorkStatus = useCallback((status, i) => {
    setPresentAllocs((prevAllocs) => {
      let arr = [...prevAllocs];
      arr[i].status = status;
      saveChangeToIndexDB(i, arr);

      return arr;
    });
  }, [])

  const setCategory = useCallback((bg, i) => {
    setPresentAllocs((prevAllocs) => {
      let arr = [...prevAllocs];
      arr[i].domain.category = bg;
      saveChangeToIndexDB(i, arr);
      return arr;
    });
  }, [])

  const calcLd = useCallback(async (b, c) => {
    if (b) setLd((ld) => {
      saveLdIndexDB(ld + c);
      return ld + c
    });
    else setLd((ld) => {
      saveLdIndexDB(ld - c);
      return ld - c
    });
  }, [])

  const proceedWithSaving = () => {
    setLoading(true);
    console.log("saving work", presentAllocs);
    saveTodayWork(date, presentAllocs)
      .then((res) => {
        console.log("saved work");
        setB(!b);
        setChanges(false);
        saveChangesIndexDB(false);
        setOpenS(true);
        getPresentAllocsWI()
          .then((res) => {
            updateDataFromServer(res);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setOpenF(true);
        setLoading(false);
      });
  };

  const handleSyncChanges = () => {
    const unmailableC = countUnmailableLeads(presentAllocs);
    if (unmailableC > 0) {
      setUnmailableDialogOpen(true);
    } else {
      proceedWithSaving();
    }
  };

  const RemoveButton = memo(({ id, atWorkStation, i, handleRemoveToggle }) => {
    return (
      <IconButton
        onClick={() => handleRemoveToggle(id, i)}
        size="small"
      >
        {atWorkStation ? <DeleteOutline /> : <Delete />}
      </IconButton>
    );
  });

  const handleRemoveToggle = useCallback((id, index) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[index] = {
        ...updatedAllocs[index],
        atWorkStation: !updatedAllocs[index].atWorkStation
      };
      saveChangeToIndexDB(index, updatedAllocs);
      return updatedAllocs;
    });
  }, []);

  const setLeadAllocationState = useCallback((i1, i2, state) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].todayDW.leads[i2].leadAllocationState = state;
      setChanges(true);
      saveChangesIndexDB(true);
      saveChangeToIndexDB(i1, updatedAllocs);
      return updatedAllocs;
    });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        id: 'domain',
        header: 'Domain',
        accessorFn: (row) => { return row.domain.domain; },
      },
      {
        id: 'working',
        accessorFn: (row) => { return row.status; },
        Cell: ({ renderedCellValue, row }) => { return (<CheckB id={row.original.id} working={row.original.working} status={row.original.status} i={row.index} setWorkStatus={setWorkStatus} />) },
        header: 'Status',
      },
      {
        id: 'Type',
        accessorFn: (row) => { return row.domain.portfolio; },
        Cell: ({ renderedCellValue, row }) => { return (<Box><Chip label={renderedCellValue} size="small" sx={{ backgroundColor: renderedCellValue === 'owned' ? '#C6CFFF' : '#FFC682', height: 17, fontSize: 10 }} /> </Box>); },
        header: 'Type',
      },
      {
        id: 'EST',
        accessorFn: (row) => {
          return row.domain.EST;
        },
        header: 'EST',
      },
      {
        id: 'PP',
        accessorFn: (row) => {
          return row.domain.PP;
        },
        header: 'PP',
      },
      {
        id: 'deadline', //id is still required when using accessorFn instead of accessorKey
        header: 'Deadline',
        accessorFn: (originalRow) => { if (originalRow.domain.portfolio == 'ltd' && originalRow.domain.deadline != null && originalRow.domain.deadline != '') { const date = new Date(originalRow.domain.deadline); return date } else return null }, //convert to date for sorting and filtering
        filterVariant: 'date-range',
        Cell: ({ cell }) => { if (cell.getValue() != null) return cell.getValue().toISOString().split('T')[0]; else return "N/A" },
      },
      {
        id: 'orderDate', //id is still required when using accessorFn instead of accessorKey
        header: 'Order Date',
        accessorFn: (originalRow) => { if ((originalRow.domain.portfolio == 'ald' || originalRow.domain.portfolio == 'pd') && originalRow.domain.orderDate != null && originalRow.domain.orderDate != '') { const date = new Date(originalRow.domain.orderDate); return date } else return null }, //convert to date for sorting and filtering
        filterVariant: 'date-range',
        Cell: ({ cell }) => { if (cell.getValue() != null) return cell.getValue().toISOString().split('T')[0]; else return "N/A" },
      },
      {
        id: 'remove',
        header: 'Remove',
        Cell: ({ row }) => (
          <RemoveButton
            id={row.original.id}
            atWorkStation={row.original.atWorkStation}
            i={row.index}
            handleRemoveToggle={handleRemoveToggle}
          />
        ),
        size: 60,
      },
    ],
    [
      //handleRemoveToggle
    ]
  );

  const { user1, updateUser1 } = useUser();
  const [b, setB] = useState(false);
  const [b1, setB1] = useState(false);

  const [prevMisFilled, setPrevMisFilled] = useState(true);
  const [ansFilled, setAnsFilled] = useState(true);

  const [startQuestion, setStartQuestion] = useState("");

  const [openMd, setOpenMd] = useState(false);
  const [openAd, setOpenAd] = useState(false);

  const [openS, setOpenS] = useState(false);
  const [openF, setOpenF] = useState(false);
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState("");

  const [rbtitle, setRbtitle] = useState("");
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    if (!prevMisFilled) {
      setOpenMd(true);
    }
    else setOpenMd(false);
  }, [prevMisFilled]);

  useEffect(() => {
    if ((!openMd) && (!ansFilled)) {
      setOpenAd(true);
    }
    else setOpenAd(false);
  }, [openMd, ansFilled]);

  const setPulse = async (res) => {
    setPrevMisFilled(res.data.prevMISFilled);
    setAnsFilled(res.data.ansFilled);
    setStartQuestion(res.data.question);
  }

  const updateDataFromServer = (res) => {
    console.log("updating data from server")
    let d = res.data.list;
    setPulse(res);
    console.log(d)
    let l = 0;

    d = d.map((a1) => {
      let a = a1;
      let leads = a.todayDW.leads;
      l = l + leads.length;
      leads = leads.map((lead) => {
        let l = lead;
        if (l.contacts.length == 0)
          l.contacts.push(createContactRow());
        return l
      })
      leads.push(createLeadRow())
      a.todayDW.leads = leads
      return a;
    })
    setPresentAllocs(d);
    setDate(res.data.date);
    setChanges(false)
    setLd(l);
    db.presentAllocs.clear().then(() => {
      db.presentAllocs.bulkPut(d);
      db.data.put({ id: 1, date: res.data.date, changes: false, ld: l, lastWorkSaved: res.data.lastWorkSaved });
    });
  }

  useEffect(() => {
    if (id == 0)
      getPresentAllocsWI().then((res) => {
        console.log(res.data)
        db.data.toArray().then((d) => {
          console.log(d)
          if (d.length > 0) {
            let da = d[0];
            if (da.date == res.data.date) {
              console.log("indexed db last: ", da.lastWorkSaved)
              console.log("server last: ", res.data.lastWorkSaved)
              if ((da.lastWorkSaved < res.data.lastWorkSaved || (res.data.lastWorkSaved - da.lastWorkSaved > -5000)) || (da.lastWorkSaved == 0 && res.data.lastWorkSaved == 0)) {
                updateDataFromServer(res);
              }
              else {
                db.presentAllocs.toArray().then((allocs) => {
                  //setPresentAllocs(allocs);
                  if (allocs.length > 0) {
                    setPresentAllocs(allocs);
                  }
                  else {
                    updateDataFromServer(res);
                  }
                });
                console.log(da)
                setDate(da.date);
                setChanges(da.changes);
                setLd(da.ld);
              }
            }
            else {
              //updateDataFromServer(res);
              db.presentAllocs.toArray().then((allocs) => {
                //setPresentAllocs(allocs);
                if (allocs.length > 0) {
                  setPresentAllocs(allocs);
                }
                else {
                  updateDataFromServer(res);
                }
              });
              console.log(da)
              setDate(da.date);
              setChanges(da.changes);
              setLd(da.ld);
            }
          }
          else {
            updateDataFromServer(res);
          }
        });
        console.log(presentAllocs)
      }).catch((err) => { console.log(err) })
  }, [b1])

  useEffect(() => {
    getAllTags().then((res) => {
      console.log("All Tags Fetched", res.data)
      setAlltags(res.data)
    }).catch((err) => { console.log(err) })
  }, [])


  useEffect(() => { console.log(presentAllocs) }, [presentAllocs]);


  /////////main table structer
  const domainTable = useMaterialReactTable({

    columns: columns,
    data: presentAllocs,

    initialState: { 
      density: 'compact', 
      pagination: { pageSize: 200, pageIndex: 0 } 
    },

    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30, 50, 100, 200, 300],
      showFirstButton: false,
      showLastButton: false,
    },
    
    //for adding details/tools in top toolbar of table
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' width='100%' alignItems='center'>
        <Button
          variant="contained"
          size='small'
          disabled={loading}
          sx={{ textTransform: 'none' }}
          onClick={handleSyncChanges}
        >
          {loading ? "Syncing..." : scanningLeads ? "Scanning..." : "Sync Changes"}
        </Button>
        <Box flexGrow={1} />
        <Typography variant='body2' fontWeight='bold' >Total Leads: {ld}</Typography>
      </Stack>
    ),

    //memoMode:'cells',
    //for rendering all data in table
    renderDetailPanel: ({ row }) => (
      <RenderDetailPanel 
        row={row} 
        addRowC={addRowC} 
        subRowC={subRowC} 
        setLeadName={setLeadName} 
        setLeadInfo={setLeadInfo} 
        addLeads={addLeads} 
        setCf={setCf} 
        setImp={setImp} 
        setRecycle={setRecycle} 
        addContactField={addContactField} 
        subRowL={subRowL} 
        calcLd={calcLd} 
        setLeads={setLeads} 
        setCategory={setCategory} 
        normalizeSocials={normalizeSocials} 
        updateSocialData={updateSocialData} 
        addSocialRows={addSocialRows} 
        addSocialData={addSocialData} 
        alltags={alltags} 
        setSelectedTags={setSelectedTags} 
        setAllied={setAllied} 
        setAlliedInfo={setAlliedInfo} 
        setAssistedUserLead={setAssistedUserLead} 
        setAssistedUserContact={setAssistedUserContact} 
        processors={processors} 
        setLeadAllocationState={setLeadAllocationState} 
      />
    ),

    enableExpandAll: true,
    muiTableBodyRowProps: { sx: { backgroundColor: 'whitesmoke' } },
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: table.getState().isFullScreen ? 1250 : undefined,
      },
    }),
    muiDetailPanelProps: ({ row }) => ({
      sx: {
        backgroundColor: '#fcfcfa', paddingX: 0
      },
    }),
  });

  //////nothing in return
  return (
  <ThemeProvider theme={theme}>
    <Stack direction='column' spacing={5}>
      <Stack direction='row' spacing={2} alignItems='center' width='100%' >
        <Typography
          variant="h6"
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          Work Station
        </Typography>
        <Box flexGrow={1}></Box>
        <Button
          variant="text"
          color="primary"
          startIcon={<Upload />}
          onClick={() => { setOpenDialog(true) }}
          sx={{
            height: 40,
            borderRadius: 100,
            textTransform: 'none',
          }}
        >
          Upload Work
        </Button>
        {<ExportContactsToCRM presentAllocs={presentAllocs} changes={changes} />}
        <UploadCSVDialogWork title='Work' open={openDialog} handleClose={handleClose} setState={setWork} />
        <MissingMISDialog open={openMd} onClose={() => { setOpenMd(false); }} />
        <QuestionDialog open={openAd} date={date} question={startQuestion} onClose={() => { setOpenAd(false); }} />
        <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Work Uploaded!
          </Alert>
        </Snackbar>
        <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Work Not Uploaded!
          </Alert>
        </Snackbar>
        <Snackbar open={openS} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setOpenS(false); }}>
          <Alert severity='success' sx={{ width: '100%' }}>
            Saved!
          </Alert>
        </Snackbar>
        <Snackbar open={openF} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setOpenF(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Saved!
          </Alert>
        </Snackbar>
        <UnmailableLeadsDialog
          open={unmailableDialogOpen}
          onClose={() => setUnmailableDialogOpen(false)}
          onContinue={() => {
            setUnmailableDialogOpen(false);
            proceedWithSaving();
          }}
          loading={loading}
          unmailableCount={unmailableLeadsCount}
        />
      </Stack>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable
            table={domainTable}
          />
        </LocalizationProvider>
      </Box>
    </Stack>
  </ThemeProvider>
  )
}


/////////////////////Below are dialog related things main code for input work station is above from line 1550 to 2100
/////////////////////all below are dialog boxes////////////////////////


const SocialDataDialog = ({ open, onClose, socialData, onSocialDataChange, addSocialRows, i1, i2, setChecked, setImp, calcImp, leadName, url, noSocialsAvailable, addSocialData }) => {

  const handleChange = (platform, field, value) => {
    setData(prevData => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex(item => item.platform === platform);
      if (index !== -1) {
        updatedData[index] = { ...updatedData[index], [field]: (field === 'url' ? value : parseInt(value)) || 0 };
      }
      return updatedData;
    });
  };

  const [data, setData] = useState(socialData);
  const [noSocialsAvailable1, setNoSocialsAvailable1] = useState(false);
  const [url1, setUrl1] = useState(null);

  const disabled = useMemo(() => {
    if (data)
      return (data.every(item =>
        ((item.platform === 'Linkedin' ? item.employees === 0 : item.followers === 0) || (item.ur))
      ) && !noSocialsAvailable1);

    else return true;
  }, [url1, data, noSocialsAvailable1]);

  useEffect(() => { setUrl1(url); }, [url])
  useEffect(() => { setData(socialData); }, [socialData])
  useEffect(() => { setNoSocialsAvailable1(noSocialsAvailable); }, [noSocialsAvailable])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center" >
          <Typography fontSize={20} color='text.primary' align='left'>Socials of {leadName}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={noSocialsAvailable1}
                onChange={(e) => setNoSocialsAvailable1(e.target.checked)}
              />
            }
            label="No Socials Available"
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        {data && <Stack direction='column' spacing={2} paddingTop={2}>
          <Stack direction='row' spacing={2}>
            <TextField
              label="LN-EMP"
              type="number"
              value={data.find(item => item.platform === 'Linkedin')?.employees || 0}
              onChange={(e) => handleChange('Linkedin', 'employees', e.target.value)}

              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=number]": { MozAppearance: "textfield" },
                width: '100px',
                flexShrink: 0
              }}
            />
            <TextField
              label="LN-FLW"
              type="number"
              value={data.find(item => item.platform === 'Linkedin')?.followers || 0}
              onChange={(e) => handleChange('Linkedin', 'followers', e.target.value)}

              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=number]": { MozAppearance: "textfield" },
                width: '100px',
                flexShrink: 0
              }}
            />
            <TextField
              label="LN-URL"
              type="text"
              value={data.find(item => item.platform === 'Linkedin')?.url || ''}
              onChange={(e) => handleChange('Linkedin', 'url', e.target.value)}
              fullWidth
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            <TextField
              label="X-FLW"
              type="number"
              value={data.find(item => item.platform === 'Twitter')?.followers || 0}
              onChange={(e) => handleChange('Twitter', 'followers', e.target.value)}

              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=number]": { MozAppearance: "textfield" },
                width: '100px',
                flexShrink: 0
              }}
            />
            <TextField
              label="X-URL"
              type="text"
              value={data.find(item => item.platform === 'Twitter')?.url || ''}
              onChange={(e) => handleChange('Twitter', 'url', e.target.value)}
              fullWidth
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            <TextField
              label="IG-FLW"
              type="number"
              value={data.find(item => item.platform === 'Instagram')?.followers || 0}
              onChange={(e) => handleChange('Instagram', 'followers', e.target.value)}

              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=number]": { MozAppearance: "textfield" },
                width: '100px',
                flexShrink: 0
              }}
            />
            <TextField
              label="IG-URL"
              type="text"
              value={data.find(item => item.platform === 'Instagram')?.url || ''}
              onChange={(e) => handleChange('Instagram', 'url', e.target.value)}
              fullWidth
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            <TextField
              label="FB-FLW"
              type="number"
              value={data.find(item => item.platform === 'Facebook')?.followers || 0}
              onChange={(e) => handleChange('Facebook', 'followers', e.target.value)}

              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=number]": { MozAppearance: "textfield" },
                width: '100px',
                flexShrink: 0
              }}
            />
            <TextField
              label="FB-URL"
              type="text"
              value={data.find(item => item.platform === 'Facebook')?.url || ''}
              onChange={(e) => handleChange('Facebook', 'url', e.target.value)}
              fullWidth
            />
          </Stack>
        </Stack>}

      </DialogContent>
      <DialogActions><Button onClick={() => { onClose(); }}>Cancel</Button><Button disabled={disabled} onClick={() => { addSocialData(i1, i2, data, url1, noSocialsAvailable1); setChecked(true); setImp(i1, i2, true); calcImp(true, 1); onClose(); }}>Add</Button></DialogActions>
    </Dialog>
  );
};

const MissingMISDialog = (props) => {
  const [open, setOpen] = useState(props.open);
  const navigate = useNavigate();

  useEffect(() => { setOpen(props.open) }, [props.open])

  return (
    <Dialog
      open={open}
      aria-labelledby="missing-mis-dialog-title"
      aria-describedby="missing-mis-dialog-description"
    >
      <DialogTitle id="missing-mis-dialog-title">
        {"Missing MIS!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="missing-mis-dialog-description">
          You haven't filled your previous work day's MIS. Please complete it before proceeding.
        </DialogContentText>
      </DialogContent>
      {<DialogActions>
        <Button onClick={() => { navigate('/misreports') }} color="primary">
          Proceed
        </Button>
      </DialogActions>}
    </Dialog>
  );
};

const QuestionDialog = ({ open, question, date, onClose }) => {
  const [value, setValue] = useState(0);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const marks = [
    { value: -1, label: 'Average' },
    { value: 0, label: 'Good' },
    { value: 1, label: 'Great' }
  ]

  const handleSubmit = () => {
    setStartAnswer(value, date).then((res) => { console.log(res); onClose(); }).catch((err) => { console.log(err); });
  };

  const valueText = (value) => {
    switch (value) {
      case -1:
        return 'Average';
      case 0:
        return 'Good';
      case 1:
        return 'Great';
      default:
        return '';
    }
  };

  return (
    <Dialog open={open} >
      <DialogTitle>{question}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: 300, mt: 2, px: 2 }}>
          <Slider
            aria-label="Answer"
            defaultValue={0}
            //getAriaValueText={valueText}
            //valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={-1}
            max={1}
            value={value}
            onChange={handleSliderChange}
          />
          {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Not Good</Typography>
            <Typography>Good</Typography>
            <Typography>Great</Typography>
          </Box> */}
        </Box>
      </DialogContent>
      <DialogActions>
        {/* {<Button onClick={onClose}>Cancel</Button>} */}
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const TagSelectionDialog = ({ open, onClose, alltags, selectedTags, setSelectedTags, setRecycle, i1, i2, leadName, setChecked }) => {
  const [selTags, setSelTags] = useState([]);

  const handleTagChange = (event, newValue) => {
    setSelTags(newValue);
  };

  useEffect(() => {
    setSelTags(selectedTags);
  }, [selectedTags])

  const handleSubmit = () => {
    if (selTags.length > 0) {
      setSelectedTags(i1, i2, selTags);
      setRecycle(i1, i2, true);
      setChecked(true);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Select Tags for {leadName}</DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          options={Array.isArray(alltags) ? alltags : []}
          getOptionLabel={(option) => option.displayName}
          renderInput={(params) => <TextField {...params} label="Tags" />}
          value={selTags}
          onChange={handleTagChange}
          fullWidth
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={selTags && selTags.length === 0}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AlliedDomainDialog = ({ open, onClose, i1, i2, setAlliedInfo, alliedDomain, setAllied, alliedRemark, leadName, setChecked }) => {
  const [domain, setDomain] = useState(alliedDomain ? alliedDomain : { domain: "" });
  const [remark, setRemark] = useState(alliedRemark);

  const remarkOptions = [
    'Upgrade to .com',
    'Upgrade to shorten domain',
    'Product/Service/Category',
    'Others'
  ];

  useEffect(() => {
    if (alliedDomain) {
      setDomain(alliedDomain);
    } else {
      setDomain({ domain: "" });
    }
  }, [alliedDomain]);

  useEffect(() => {
    setRemark(alliedRemark);
  }, [alliedRemark])

  const handleSubmit = () => {
    setAlliedInfo(i1, i2, domain, remark);
    setAllied(i1, i2, true);
    setChecked(true);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>{leadName} : Allied Domain Information</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="domain"
          label="Domain"
          type="text"
          fullWidth
          value={domain.domain}
          onChange={(e) => setDomain((prevDomain) => ({ ...prevDomain, domain: e.target.value }))}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="remark-label">Remark</InputLabel>
          <Select
            labelId="remark-label"
            id="remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            label="Remark"
          >
            {remarkOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!domain || !domain.domain || remark == ''} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const AssistedUserDialogLead = ({ processors, assistedUserLead, i1, i2, setAssistedUserLead, open, onClose, setSource, setSelectedValue }) => {
  const [selectedUser, setSelectedUser] = useState(assistedUserLead ? assistedUserLead : { firstName: '', lastName: '', email: '' });

  useEffect(() => {
    if (assistedUserLead)
      setSelectedUser(assistedUserLead);
    else
      setSelectedUser({ firstName: '', lastName: '', email: '' });
  }, [assistedUserLead])

  const handleSubmit = () => {
    setAssistedUserLead(i1, i2, selectedUser);
    setSource(i1, i2, 'source', 'Assist');
    setSelectedValue('Assist');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Processor</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={processors}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          value={selectedUser}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Processor" variant="outlined" />}
          sx={{ width: 300, mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!selectedUser || selectedUser.email == ''} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const AssistedUserDialogContact = ({ processors, assistedUserContact, i1, i2, i3, setAssistedUserContact, open, onClose, setSource, setSelectedValue }) => {
  const [selectedUser, setSelectedUser] = useState(assistedUserContact ? assistedUserContact : { firstName: '', lastName: '', email: '' });

  useEffect(() => {
    if (assistedUserContact)
      setSelectedUser(assistedUserContact);
    else
      setSelectedUser({ firstName: '', lastName: '', email: '' });
  }, [assistedUserContact])

  const handleSubmit = () => {
    setAssistedUserContact(i1, i2, i3, selectedUser);
    setSource(i1, i2, i3, 'source', 'Assist');
    setSelectedValue('Assist');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Processor</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={processors}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          value={selectedUser}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Processor" variant="outlined" />}
          sx={{ width: 300, mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!selectedUser || selectedUser.email == ''} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const ExportContactsToCRM = ({ presentAllocs, changes }) => {
  const headers = [
    { label: "DOMAIN", key: "DOMAIN" },
    { label: "LEAD", key: "LEAD" },
    { label: "NAME", key: "NAME" },
    { label: "EMAIL", key: "EMAIL" }
  ];
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState('');
  const [data, setData] = useState([]);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);

  // Handle opening the dialog
  const handleOpen = () => {
    setOpen(true);
    const userName = getName(); // Replace with actual logic to fetch username
    console.log(userName)
    const date = new Date().toISOString().split('T')[0]; // Format date as yyyy-MM-dd
    setTag(`${userName}/${date}`);
    setData(transformData());
  };

  // Handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Transform presentAllocs to the required array format
  const transformData = () => {
    const contactsArray = [];
    presentAllocs.forEach(alloc => {
      alloc.todayDW.leads.forEach(lead => {
        if (lead.allowMailing) {
          lead.contacts.forEach(contact => {
            const email = contact.primaryEmail || contact.secondaryEmail;
            if (email) {
              contactsArray.push({
                DOMAIN: alloc.domain.domain,
                LEAD: lead.site,
                NAME: contact.name,
                EMAIL: email
              });
            }
          });
        }
      });
    });
    console.log(contactsArray);
    return contactsArray;
  };

  return (
    <>
      <Button variant="text" onClick={handleOpen} disabled={changes}
      >
        Export Contacts
      </Button>
      <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Contacts Exported!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Contacts Not Exported!
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Export Contacts To CRM</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="tag"
            label="Tag"
            type="text"
            fullWidth
            variant="outlined"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <CSVLink 
          // style={{
          //   textDecoration: 'none',
          //   pointerEvents: 'none',
          //   opacity: 0.5,
          // }} 
          data={data} filename={`${tag}.csv`} headers={headers}>
            <Typography color='primary' variant='caption'>Download CSV</Typography>
          </CSVLink>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            console.log(data);
            // Replace with actual export logic
            exportContactsToCRM(data, tag, getUserName()).then((res) => {
              console.log(res); setUpsuc(true); handleClose();
              window.open('https://app.namekart.com/crm/home/manualCampaigns', '_blank');
            }).catch((err) => { console.log(err); setUpfail(true); });
          }}>Export</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UnmailableLeadsDialog = ({ open, onClose, onContinue, unmailableCount, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="unmailable-leads-dialog-title"
      aria-describedby="unmailable-leads-dialog-description"
    >
      <DialogTitle id="unmailable-leads-dialog-title" color='error'>Warning: Error In Leads!</DialogTitle>
      <DialogContent>
        <DialogContentText id="unmailable-leads-dialog-description">
          You have {unmailableCount} lead(s) with errors that will not be available for mailing. Do you want to continue saving?
          <br />
          <br />
          Possible reasons for error are:
          <ul style={{ paddingLeft: '20px', marginTop: '0', marginBottom: '0' }}>
            <li>Lead Classification Missing</li>
            <li>Lead or Contact Source Missing</li>
            <li>For Imp Minimum Two Contacts Required</li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onContinue} disabled={loading} color="primary" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/////////////////////all dialog boxes ended////////////////////////

const LeadAllocation = memo((props) => {
  const [checked, setChecked] = useState(props.leadAllocationState === 1);

  useEffect(() => { 
    setChecked(props.leadAllocationState === 1); 
  }, [props.leadAllocationState])

  return (
    <Box>
      <FormControlLabel
        sx={{ padding: 0 }}
        control={
          <Checkbox
            sx={{ paddingY: 0 }}
            onChange={(event) => {
              const c = event.target.checked;
              props.setLeadAllocationState(props.i1, props.in, c ? 1 : 0);
              setChecked(c);
            }}
            checked={checked}
            size='small'
          />
        }
        label={
          <Typography padding={0} fontSize={14} color='text.primary' align='left'>
            Alloc
          </Typography>
        }
      />
    </Box>
  )
})